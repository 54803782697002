const titles = [
  "Welcome to Women Who Code!",
  "What is your name?",
  "How would you best describe your job search?",
  "Are you open to new career opportunities?",
  "When did you get your first tech job?",
  "Do you want to upload a resume?",
  "Receive the Career Nav edition of Code Review?",
  "What do you hope to gain from the community?",
  "Which coding stacks do you use?",
  "Which coding stacks do you want to learn?",
  "What best describes your level of expertise?",
  "How many years of experience in tech do you have?",
  "What is your current location?",
  "Which job locations would you apply to?",
  "Are you willing to relocate?",
  "What is your preferred work environment?",
  "You have completed your onboarding!",
];

export default titles
