import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useAuth } from "../auth/useAuth";
import CarouselContainer from "../legacy/components/CarouselContainer";
import Layout from "../legacy/components/Layout";
import "../legacy/components/onboarding_modal.scss";
import OnboardingModal from "../components/modals/onboarding/OnboardingModal";
import { usePageData } from "../hooks/index";
import DashboardService from "../services/DashboardService";
import YoutubeModal from "../components/modals/YoutubeModal";
import { useDispatch, useSelector } from "react-redux";
import { dashboardReceived } from "../app/slices/pagesSlice";
import DonateBanner from "../legacy/components/DonateBanner";

const Dashboard = () => {
  const location = useLocation();
  const query = queryString.parse(location.search);

  const { isLoading, logout } = useAuth();
  const dispatch = useDispatch();
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const { dashboard } = useSelector((state) => state.pages);
  const { loading: loadingDashboard, dashboardData } = dashboard;

  usePageData({
    service: DashboardService.getDashboard,
    callback: (data) => dispatch(dashboardReceived(data)),
    loading: loadingDashboard,
  });

  const {
    profile_job_list: jobList,
    profile_join_list: joinList,
    profile_expertise_list: expertiseList,
    profile_years_in_tech: yearsInTech,
    communities,
    filestack_bucket: fileBucket,
    filestack_key: fileKey,
  } = dashboardData;

  // "unauthorized" error can be removed once fully migrated to Auth0 Actions
  if (query.error === "unauthorized" || query.error === "access_denied") {
    return logout({
      returnTo: "/login",
      state: {
        type: "error",
        message: "Please check your email to confirm your account.",
      },
    });
  }

  return (
    <Layout
      modal={
        youtubeUrl !== "" ? (
          <YoutubeModal youtubeUrl={youtubeUrl} setYoutubeUrl={setYoutubeUrl} />
        ) : (
          !isLoading && <OnboardingModal
            isOpen={!isLoading}
            jobList={jobList}
            joinList={joinList}
            expertiseList={expertiseList}
            yearsInTech={yearsInTech}
            communities={communities || {}}
            fileBucket={fileBucket}
            fileKey={fileKey}
          ></OnboardingModal>
        )
      }
      loading={false}
    >
      <CarouselContainer
        carouselType="events"
        label="Events"
        linkedToUrl={"/events"}
        setYoutubeUrl={setYoutubeUrl}
      />
      <CarouselContainer
        carouselType="resources"
        label="Resources"
        linkedToUrl={"/resources"}
        setYoutubeUrl={setYoutubeUrl}
      />
      <CarouselContainer
        carouselType="jobs"
        label="Jobs"
        linkedToUrl={"/jobs"}
        setYoutubeUrl={setYoutubeUrl}
      />
      <DonateBanner />
    </Layout>
  );
};

export default Dashboard;
