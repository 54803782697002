import { useState, useEffect, useRef } from "react";

function useDropdown(props) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        props.onClickOutside?.();
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  return { isOpen, setIsOpen, toggleDropdown, ref };
}

export default useDropdown;
