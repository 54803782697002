import { FilterOption } from "../../../legacy/components/NewOnboarding";
import Text from "../../Text";

const Step16 = ({ recommendedCommunities, updateNetworks, values }) => (
  <>
    <Text className="modal-text mobile-hidden">
      You can now enjoy an experience customized for you.
      <br />
      <br />
      Based on your preferences, we recommend you join the following
      communities:
    </Text>
    <Text className="desktop-hidden">
      Based on your preferences, we recommend you join the following
      communities:
    </Text>
    <div className="filter-option-container filter-option-container--two-lines community-list">
      <FilterOption
        filterClassName="filter-option-modal"
        options={recommendedCommunities}
        property="network_titles"
        selectedOption={values}
        setSelectedOption={updateNetworks}
      />
    </div>
    <Text className="modal-text2 mobile-hidden">
      You can always change any of these parameters by going to Settings.
    </Text>
  </>
);
export default Step16;
