import React, { useState, useEffect } from "react";
import debounce from "just-debounce-it";
import $ from "jquery";
import Filter from "./Filter";
import Skeleton from "../../components/Skeleton";
import { LocationSearchRef } from "./LocationSearch";
import TagsService from "../../services/TagsService";
import SuggestionsService from "../../services/SuggestionsService";
import "jquery-ui/themes/base/core.css";
import "jquery-ui/themes/base/theme.css";
import "jquery-ui/themes/base/autocomplete.css";
import "jquery-ui/ui/core";
import "jquery-ui/ui/widgets/autocomplete";
import "./events_search.scss";
import "./autocomplete.scss";

const inPersonOnline = ["In Person", "Online"];

const EventsSearch = React.forwardRef(
  (
    {
      communities,
      fetchEvents,
      filters,
      numOfFilters,
      resetFilters,
      searchTerm,
      setFilter,
      setLocations,
      setSearchTerm,
      showMobileFiltersView,
      skeleton,
      toggleShowMobileFiltersView,
      windowWidth,
    },
    ref
  ) => {
    const [showFilter, setShowFilter] = useState("");
    const [stacks, setStacks] = useState([]);

    const isMobile = windowWidth <= 720;

    const fetchSearchSuggestions = () => {
      SuggestionsService.getSuggestions("NetworkEvent").then((data) => {
        $("#event-search-term").autocomplete({
          source: function (request, response) {
            var results = $.ui.autocomplete.filter(data, request.term);
            response(results.slice(0, 10));
          },
          minLength: 1,
        });
      });
    };

    const fetchStacks = () => {
      TagsService.getStacks().then((data) => {
        const stacks = data.list.map((x) => x.name);
        setStacks(stacks);
      });
    };

    const handleChange = (e) => {
      setSearchTerm(e.target.value);
    };

    const debouncedHandleKeyPress = debounce(handleChange, 500);

    useEffect(() => {
      fetchSearchSuggestions();
      fetchStacks();
    }, []);

    return (
      <form className="events-search">
        {!showMobileFiltersView && (
          <div className="top-row">
            <div className="input-container">
              {skeleton ? (
                <Skeleton />
              ) : (
                <>
                  <input
                    className="search-term"
                    id="event-search-term"
                    type="text"
                    placeholder="What"
                    onKeyUp={debouncedHandleKeyPress}
                    ref={ref[0]}
                  />
                  <ion-icon class="icon" name="search" />
                </>
              )}
            </div>
            {!isMobile && (
              <div
                className={
                  "filters-icon-container " +
                  (numOfFilters > 0 ? "filters-set" : "")
                }
                onClick={toggleShowMobileFiltersView}
              >
                <ion-icon class="filters-icon" name="options" />
                <span className="num-of-filters-set">{numOfFilters}</span>
              </div>
            )}
            {skeleton ? (
              <>
                <Skeleton className="input-container" h={48} />
                <Skeleton w={87} h={48} radius={8} />
              </>
            ) : (
              <>
                <LocationSearchRef
                  key={filters.location}
                  search={filters}
                  setLocations={setLocations}
                  ref={ref[1]}
                />
                <div style={{ display: 'flex', width: isMobile ? '100%' : ''}}>
                  <button
                    type="submit"
                    id="search-submit"
                    onClick={(event) => {
                      fetchEvents({
                        event,
                        page: 1,
                        query: searchTerm,
                        filterObject: filters,
                        keepOld: false
                      });
                      setShowFilter("");
                    }}
                  >
                    SEARCH
                  </button>
                  {isMobile && (
                    <div
                      className={
                        "filters-icon-container " +
                        (numOfFilters > 0 ? "filters-set" : "")
                      }
                      onClick={toggleShowMobileFiltersView}
                    >
                      <ion-icon class="filters-icon" name="options" />
                      <span className="num-of-filters-set">{numOfFilters}</span>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}

        {(!isMobile || showMobileFiltersView) && (
          <div className="bottom-row">
            {showMobileFiltersView && (
              <div>
                <div
                  className="close-icon-container"
                  onClick={toggleShowMobileFiltersView}
                >
                  <ion-icon class="close-icon" name="close" />
                </div>
                <div className="filters-label">Filters</div>
              </div>
            )}
            {skeleton ? (
              <>
                <Skeleton
                  className="filter-container"
                  w="33%"
                  h={36}
                  radius={40}
                />
                <Skeleton
                  className="filter-container"
                  w="33%"
                  h={36}
                  radius={40}
                />
                <Skeleton
                  className="filter-container"
                  w="33%"
                  h={36}
                  radius={40}
                />
              </>
            ) : (
              <>
                <Filter
                  showFilter={showFilter}
                  filterType="in person / online"
                  filterOptions={inPersonOnline}
                  selectedFilterOptions={filters.inPersonOnline}
                  setShownFilter={setShowFilter}
                  setFilter={setFilter}
                  showMobileFilterView={showMobileFiltersView}
                />
                <Filter
                  showFilter={showFilter}
                  filterType="community"
                  filterOptions={communities}
                  selectedFilterOptions={filters.community}
                  setShownFilter={setShowFilter}
                  setFilter={setFilter}
                  showMobileFilterView={showMobileFiltersView}
                />
                <Filter
                  showFilter={showFilter}
                  filterType="stack"
                  filterOptions={stacks}
                  selectedFilterOptions={filters.stack}
                  setShownFilter={setShowFilter}
                  setFilter={setFilter}
                  showMobileFilterView={showMobileFiltersView}
                />
              </>
            )}
            {skeleton ? (
              <Skeleton w={87} h={36} radius={8} />
            ) : (
              <>
                {showMobileFiltersView && (
                  <div className="mobile-filter-buttons">
                    <button
                      className="apply-button"
                      type="button"
                      onClick={(event) => {
                        fetchEvents({
                          event,
                          page: 1,
                          query: searchTerm,
                          filterObject: filters,
                          keepOld: false
                        });
                        toggleShowMobileFiltersView();
                      }}
                    >
                      APPLY
                    </button>
                    <button
                      className="reset-button"
                      type="button"
                      onClick={resetFilters}
                    >
                      RESET
                    </button>
                  </div>
                )}
                {!showMobileFiltersView && (
                  <button
                    className="reset-button"
                    type="button"
                    onClick={resetFilters}
                  >
                    RESET
                  </button>
                )}
              </>
            )}
          </div>
        )}
      </form>
    );
  }
);

export default EventsSearch;
