import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './navbar.scss';
import navbarLogo from '../images/dashboard/navbar-logo.svg';
import searchUsers from '../images/dashboard/search-users.svg';
import { useAuth } from '../../auth/useAuth';

const Navbar = ({ user, loggedIn }) => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const now = new Date();
  const [displayWideView, setDisplayWideView] = useState(false);
  const { login, logout } = useAuth();
  const { profile } = useAuth();
  const todaysDay = days[now.getDay()];
  const todaysDateOfMonth = now.getDate();
  const todaysMonth = months[now.getMonth()];
  const todaysYear = now.getFullYear();
  const windowWidth = window.innerWidth;
  const location = useLocation();
  const currentPage = location?.pathname?.split('/')?.pop();
  const talentUrl = process.env.REACT_APP_API_ROOT_URL + "/dashboard";
  const wordpressUrl = process.env.REACT_APP_WORDPRESS_URL;

  const { first_name, last_name, avatar_url } = profile ?? {};
  return (
    <div
      className={
        'navbar ' +
        (displayWideView ? 'wide-view ' : '') +
        (!loggedIn ? 'logged-out' : '')
      }
      onMouseEnter={() => (windowWidth > 780 ? setDisplayWideView(true) : null)}
      onMouseLeave={() =>
        windowWidth > 780 ? setDisplayWideView(false) : null
      }
    >
      <div
        className="hamburger-icon-container"
        onClick={() => setDisplayWideView(!displayWideView)}
      >
        <ion-icon class="hamburger-icon" name="menu" />
      </div>
      <div
        className="close-icon-container"
        onClick={() => setDisplayWideView(false)}
      >
        <ion-icon class="close-icon" name="close" />
      </div>
      {(windowWidth > 780 || displayWideView) && (
        <div
          className={
            'user-headshot-container ' + (displayWideView ? 'wide-view' : '')
          }
        >
          <Link to="/profile">
            {avatar_url && <img alt="User avatar" src={avatar_url} />}
            {!avatar_url && first_name && last_name && (
              <span className="user-headshot-placeholder">
                {first_name.substring(0, 1)}
                {last_name.substring(0, 1)}
              </span>
            )}
            {!avatar_url && (!first_name || !last_name) && user && (
              <span className="user-headshot-placeholder">
                {user.email.substring(0, 1)}
              </span>
            )}
          </Link>
          {displayWideView && (
            <React.Fragment>
              <div className="welcome-message">
                {first_name ? `Welcome, ${first_name}` : 'Welcome'}
              </div>
              <div className="datetime">
                {todaysDay}, {todaysMonth} {todaysDateOfMonth} {todaysYear}
              </div>
              <div className="divider" />
            </React.Fragment>
          )}
        </div>
      )}
      {!loggedIn && windowWidth <= 780 && displayWideView && (
        <div className="logged-out-mobile-logo-container">
          <a href={wordpressUrl}>
            <img className="logo" alt="Navbar Logo" src={navbarLogo} />
          </a>
          <div className="divider" />
        </div>
      )}
      <div
        className={'links-container ' + (displayWideView ? 'wide-view ' : '') + (loggedIn ? 'logged-in' : 'logged-out')}
      >
        <Link
          to="/dashboard"
          className={
            'link-container dashboard ' +
            (displayWideView ? 'wide-view ' : '') +
            (currentPage === 'dashboard' ? 'active' : '')
          }
        >
          <ion-icon name="bar-chart-outline" />
          {displayWideView && <span className="label">Dashboard</span>}
        </Link>
        <Link
          to="/favorites"
          className={
            'link-container favorites ' +
            (displayWideView ? 'wide-view ' : '') +
            (currentPage === 'favorites' ? 'active' : '')
          }
        >
          <ion-icon name="star-outline" />
          {displayWideView && <span className="label">Saved</span>}
        </Link>
        <Link
          to="/events"
          className={
            'link-container events ' +
            (displayWideView ? 'wide-view ' : '') +
            (currentPage === 'events' ? 'active' : '')
          }
        >
          <ion-icon name="calendar-outline" />
          {displayWideView && <span className="label">Events</span>}
        </Link>
        <Link
          to="/resources"
          className={
            'link-container resources ' +
            (displayWideView ? 'wide-view ' : '') +
            (currentPage === 'resources' ? 'active' : '')
          }
        >
          <ion-icon name="folder-open-outline" />
          {displayWideView && <span className="label">Resources</span>}
        </Link>
        <Link
          to="/jobs"
          className={
            'link-container jobs ' +
            (displayWideView ? 'wide-view ' : '') +
            (currentPage === 'jobs' ? 'active' : '')
          }
        >
          <ion-icon name="briefcase-outline" />
          {displayWideView && <span className="label">Jobs</span>}
        </Link>
        <a
          href={talentUrl}
          className={'link-container'}
        >
          <img src={searchUsers} alt="search users" />
          {displayWideView && <span className="label">Find Talent</span>}
        </a>
        <Link
          to="/applaudher"
          className={
            'link-container applaudher ' +
            (displayWideView ? 'wide-view ' : '') +
            (currentPage === 'applaudher' ? 'active' : '')
          }
        >
          <ion-icon name="trophy-outline" />
          {displayWideView && <span className="label">ApplaudHer</span>}
        </Link>
        <Link
          to="/settings"
          className={
            'link-container settings ' +
            (displayWideView ? 'wide-view ' : '') +
            (currentPage === 'settings' ? 'active' : '')
          }
        >
          <ion-icon name="settings-outline" />
          {displayWideView && <span className="label">Settings</span>}
        </Link>
        <div
          onClick={logout}
          className={
            'link-container settings ' + (displayWideView ? 'wide-view ' : '')
          }
        >
          <ion-icon name="log-out-outline"></ion-icon>
          {displayWideView && <span className="label">Log out</span>}
        </div>
        <div className='menu-buttons'>
          <button className="sign-up-button" onClick={() => login('login')}>
            SIGN UP
          </button>
          <Link to="/donate">
            <button className="donate-button">DONATE</button>
          </Link>
          <button className="login-button" onClick={() => login('register')}>
            LOG IN
          </button>
        </div>
      </div>
      {
        <div className="logo-container">
          {windowWidth > 780 && <div className="divider" />}
          <a href={wordpressUrl}>
            <img className="logo" alt="Navbar Logo" src={navbarLogo} />
          </a>
        </div>
      }
      <div className="logged-out-buttons">
        <button className="sign-up-button" onClick={login}>
          SIGN UP
        </button>
        <Link to="/donate">
          <button className="donate-button">DONATE</button>
        </Link>
        <button className="login-button" onClick={login}>
          LOG IN
        </button>
      </div>
    </div>
  );
};

export default Navbar;
