import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../legacy/components/Button";
import ProfileEdit from "../legacy/components/ProfileEdit";
import Layout from "../legacy/components/Layout";
import { usePageData } from "../hooks/index";
import ProfileService from "../services/ProfileService";
import { profileReceived } from "../app/slices/pagesSlice";
import LottiePlayer from "lottie-react";
import profile_line from "../assets/Profile_Line.json";
import { useMemo } from "react";

const SocialContainer = ({ src, text }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src={src} className="social-img" alt="Social" />
      <p className="content">{text}</p>
    </div>
  );
};

const ProfileSection = () => {
  const screenWidth = window.innerWidth;
  const [edit, setEdit] = React.useState(false);
  const [showFlash, setShowFlash] = React.useState(false);
  const [values, setValues] = React.useState(null);
  const [playLottie, setPlayLottie] = React.useState(false);
  const [showCard, setShowCard] = React.useState(false);

  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.pages);
  const { profileData, loading: loadingProfile } = profile;

  const handleResetClick = () => {
    ProfileService.updatePassword().then(({ redirect_url }) => {
      if (redirect_url) window.location = redirect_url;
    });
  };

  usePageData({
    service: ProfileService.getProfileData,
    callback: (data) => {
      dispatch(profileReceived(data));
    },
    loading: loadingProfile,
  });

  React.useEffect(() => {
    if (!loadingProfile) setValues(profileData.profile);
  }, [loadingProfile, profileData.profile]);

  React.useEffect(() => {
    if (showFlash) {
      setTimeout(() => {
        setShowFlash(false);
      }, 800);
    }
  }, [screenWidth, showFlash]);

  const {
    first_name,
    last_name,
    job_title,
    work,
    parent,
    email,
    url,
    github_username,
    twitter_username,
    linkedin_url,
    instagram_username,
    pronoun_list,
  } = values ?? {};

  const loading = useMemo(
    () => loadingProfile || values === null,
    [loadingProfile, values]
  );

  useEffect(() => {
    if (loading) return;
    setShowCard(true);

    setTimeout(() => {
      setPlayLottie(true);
    }, 800);
  }, [loading]);

  const fullName =
    first_name === null ? "Name: N/A" : `${first_name} ${last_name}`;

  return !edit ? (
    <Layout loading={false}>
      <div id="profile-section">
        {showFlash && (
          <div className="status-changed">
            <p style={{ margin: 0 }}>Your changes have been saved.</p>
          </div>
        )}
        <div className="title-button-container">
          <h1 className="profile-title">My Profile</h1>
          {screenWidth > 890 && (
            <div style={{ display: "flex" }}>
              <Button
                icon="lock-closed"
                text="Change Password"
                onClick={handleResetClick}
                rounded
              />
              <div style={{ marginRight: 20 }} />
              <Button icon="pencil" text="Edit" onClick={() => setEdit(true)} rounded />
            </div>
          )}
        </div>
        <div style={{ marginBottom: 66 }} />
        <div className={`profile-card ${showCard ? "show" : "hide"}`}>
          {!loading && (
            <>
              <div className="line-container">
                {playLottie && (
                  <LottiePlayer animationData={profile_line} loop={0} />
                )}
              </div>
              <div className="img-logo-container">
                <div className="profile-img-container">
                  {values.filepicker_url ? (
                    <img
                      src={values.filepicker_url ? values.filepicker_url : ""}
                      className="profile-img"
                      alt="Profile"
                    />
                  ) : first_name && last_name ? (
                    <span className="img-letter">
                      {first_name.substring(0, 1)}
                      {last_name.substring(0, 1)}
                    </span>
                  ) : (
                    <p className="img-letter">{values.email.substring(0, 1)}</p>
                  )}
                </div>
                <div className="logo-container">
                  <img
                    src="/images/wwcode_logo.png"
                    className="logo"
                    alt="WWC Logo"
                  />
                </div>
              </div>
              <div className="bottom-section">
                <h3 className="name-header">{fullName}</h3>
                <h5 className="position-header">
                  {job_title === null ? "Job Title: N/A" : job_title} &#8226;
                  {work === null ? "Company: N/A" : work}
                </h5>
                <p className="content">
                  {pronoun_list.length === 0
                    ? "Pronouns: N/A"
                    : pronoun_list.join("/")}
                  &#8226; {parent ? "With kids" : null}
                </p>
                <div style={{ marginBottom: 12 }} />
                <SocialContainer
                  src="/images/email.png"
                  text={email === null ? "N/A" : email}
                />
                <div style={{ marginBottom: 12 }} />
                <div className="social-container">
                  <SocialContainer
                    src="/images/website.png"
                    text={url === null ? "N/A" : url}
                  />
                  <div className="social-spacing" />
                  <SocialContainer
                    src="/images/github.png"
                    text={github_username === null ? "N/A" : github_username}
                  />
                  <div className="social-spacing" />
                  <SocialContainer
                    src="/images/twitter.png"
                    text={twitter_username === null ? "N/A" : twitter_username}
                  />
                  <div className="social-spacing" />
                  <SocialContainer
                    src="/images/linkdin.png"
                    text={linkedin_url === null ? "N/A" : linkedin_url}
                  />
                  <div className="social-spacing" />
                  <SocialContainer
                    src="/images/instagram.png"
                    text={
                      instagram_username === null ? "N/A" : instagram_username
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {screenWidth < 890 && (
          <React.Fragment>
            <Button icon="pencil" text="Edit" onClick={() => setEdit(true)} />
            <div style={{ marginBottom: 40 }} />
            <Button
              icon="lock-closed"
              text="Change Password"
              onClick={handleResetClick}
            />
            <div style={{ marginBottom: 200 }} />
          </React.Fragment>
        )}
      </div>
    </Layout>
  ) : (
    <ProfileEdit
      setEdit={setEdit}
      values={values}
      setValues={setValues}
      data={profileData}
      screenWidth={screenWidth}
      successFlash={setShowFlash}
      id={values.id}
    />
  );
};

export default ProfileSection;
