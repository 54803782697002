import { createSlice } from "@reduxjs/toolkit";
import BookmarkingsService from "../../services/BookmarkingsService";

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState: {
    loading: true,
    events: [],
    jobs: [],
    resources: [],
  },
  reducers: {
    favoritesLoading(state) {
      return { ...state, loading: true };
    },
    favoritesReceived(state, action) {
      if (state.loading) {
        return {
          loading: false,
          ...action.payload,
        }
      }
    },
    addFavorite(state, action) {
      const { type, item } = action.payload;
      state[type].push(item);
    },
    removeFavorite(state, action) {
      const { type, id } = action.payload;
      state[type] = state[type].filter(item => item.id !== id);
    },
  },
});

const fetchFavorites = () => async (dispatch) => {
  dispatch(favoritesLoading());
  const response = await BookmarkingsService.getFavorites();
  const action = favoritesReceived(response.favorites);
  dispatch(action);
}

export const { reducer: favoritesReducer } = favoritesSlice;
export const { favoritesLoading, favoritesReceived, addFavorite, removeFavorite } = favoritesSlice.actions;
export { fetchFavorites };