import { createSlice } from "@reduxjs/toolkit";
import applaudHersService from '../../services/ApplaudHersService';
const applaudhersSlice = createSlice({
  name: 'applaudhers',
  initialState: {
    loading: true,
    applaudHers: [],
    meta: {}
  },
  reducers: {
    applaudHersLoading(state) {
      state.loading = true;
    },
    applaudHersReceived(state, action) {
      state.loading = false;
      state.applaudHers = action.payload.applaudHers;
      state.meta = action.payload.meta;
    },
  },
});

export const fetchApplaudHers = (payload) => async (dispatch) => {
  dispatch(applaudHersLoading());
  const response = await applaudHersService.getApplaudHers(payload);
  dispatch(applaudHersReceived(response));
};

export const { reducer: applaudHersReducer } = applaudhersSlice;
export const { applaudHersLoading, applaudHersReceived } = applaudhersSlice.actions;