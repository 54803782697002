import React from "react";

function FormWithDropdown({ options, setOption, values, property, label }) {
  const [dropdownText, setDropdownText] = React.useState(
    "Please choose an option"
  );
  const [openList, setOpenList] = React.useState(false);

  React.useEffect(() => {
    if (values[property] !== "" && values[property] !== null) {
      setDropdownText(values[property]);
    }
  }, [property, values]);

  const renderOptions = (options) => {
    return options.map((option) => (
      <p
        className="list-item"
        onClick={() => {
          setDropdownText(option);
          setOption({ ...values, [property]: option });
        }}
        key={option}
      >
        {option}
      </p>
    ));
  };
  return (
    <>
      {label && <p className="dropdown-label">{label}</p>}
      <div
        className="dropdown-container"
        onClick={() => setOpenList((prev) => !prev)}
      >
        <div className="dropdown">
          <p className="dropdown-text">{dropdownText}</p>
        </div>
        {openList && <div className="drop-list">{renderOptions(options)}</div>}
      </div>
    </>
  );
}

export default FormWithDropdown;
