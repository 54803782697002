import RadioButtons from "../../buttons/RadioButtons";
import { ReactComponent as HandThumbUp } from "../../icons/hand-thumb-up.svg";
import { ReactComponent as HandThumbDown } from "../../icons/hand-thumb-down.svg";

const Step3 = ({ values, setValues }) => {
  const options = [
    {
      text: "Yes",
      Icon: HandThumbUp,
    },
    {
      text: "No",
      Icon: HandThumbDown,
    },
  ];
  const selectedValues = values.career_opportunities;
  const handleChange = (newValues) => {
    setValues((state) => ({ ...state, career_opportunities: newValues }));
  };

  return (
    <>
      <RadioButtons
        options={options}
        selectedValues={selectedValues}
        onChange={handleChange}
      />
      <p className="form-info-text">
        By clicking Yes, you consent to having your information shared with
        partner companies that are hiring. If you have a question, feel free to
        message us at
        <span style={{ color: "#E86431" }}> contact@womenwhocode.com</span>
      </p>
    </>
  );
};

export default Step3;
