function FormWithButtons({
  header,
  buttons,
  step,
  info,
  property,
  values,
  setValues,
  fileBucket,
  fileKey,
  uploadFile,
  goToPage,
  noHeader,
  width,
}) {
  const renderButtons = (buttons) => {
    const truncateText = (text) => {
      return text.length > 20 ? `${text.substring(0, 20)}...` : text;
    };
    const setFile = (data) =>
      setValues({
        ...values,
        resume_filename: data.filename,
        resume_url: data.url,
      })

    return buttons.map((button, index) => (
      <div key={button}>
        <button
          className="outline-button"
          style={{ width: width ? width : '100%' }}
          onClick={() => {
            if (fileBucket) {
              uploadFile(fileBucket, fileKey, 'application/pdf', setFile)
              return;
            }
            setValues({ ...values, [property]: button });
            goToPage(step + 1);
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {fileBucket ? (
              <ion-icon
                name="cloud-upload"
                style={{ marginRight: 15 }}
              ></ion-icon>
            ) : null}
            {values[property] && fileBucket ? (
              <p
                title={values[property]}
                style={{
                  margin: 0,
                  fontSize: 14,
                  lineHeight: 1.2,
                }}
              >
                {truncateText(values[property])}
              </p>
            ) : (
              button
            )}
          </div>
        </button>
        {index !== buttons.length - 1 ? (
          <div style={{ marginBottom: 25 }} />
        ) : null}
      </div>
    ));
  };
  let dynamicMargin = buttons.length > 2 ? '30%' : 220;
  return noHeader ? (
    renderButtons(buttons)
  ) : (
    <>
      <h4 className="form-header">{header}</h4>
      <div style={{ marginBottom: 48 }} />
      {renderButtons(buttons)}
      <div style={{ marginBottom: info ? 95 : dynamicMargin }} />
    </>
  );
}

export default FormWithButtons;