import Skeleton from "../../components/Skeleton";
import "../stylesheets/base/extends/_button.scss";

const Button = ({
  icon,
  text,
  isLink,
  urlLink,
  onClick,
  className,
  width,
  rounded,
  skeleton,
}) => {
  if (skeleton) {
    return (
      <Skeleton
        w={width ?? 105}
        h={60}
        mt={15}
        mb={15}
        ml={15}
        mr={15}
        radius={rounded ? 8 : 0}
      />
    );
  }

  if (isLink) {
    return (
      <a href={urlLink} className="outline-button-profile">
        <ion-icon name={icon}></ion-icon>
        <div
          style={{
            display: "inline-block",
            marginLeft: 15,
            borderRadius: rounded ? 8 : 0,
          }}
        >
          {text}
        </div>
      </a>
    );
  }

  return (
    <button
      className={className ? className : "outline-button-profile"}
      style={{ width: width ? width : null, borderRadius: rounded ? 8 : 0 }}
      onClick={onClick}
    >
      {icon ? <ion-icon name={icon}></ion-icon> : null}
      <div
        style={{
          display: "inline-block",
          marginLeft: icon ? 15 : null,
        }}
      >
        {text}
      </div>
    </button>
  );
};

export default Button;
