import RadioButtons from "../../buttons/RadioButtons";
import { ReactComponent as HandThumbUp } from "../../icons/hand-thumb-up.svg";
import { ReactComponent as HandThumbDown } from "../../icons/hand-thumb-down.svg";

const Step14 = ({ values, setValues }) => {
  const options = [
    {
      text: "Yes",
      Icon: HandThumbUp,
    },
    {
      text: "No",
      Icon: HandThumbDown,
    },
  ];

  const selectedValues = values.relocate;
  const handleChange = (newValues) => {
    setValues((state) => ({ ...state, relocate: newValues }));
  };

  return (
    <RadioButtons
      options={options}
      selectedValues={selectedValues}
      onChange={handleChange}
    />
  );
};
export default Step14;
