import { useState, useEffect } from 'react'

const useIntersectionObserver = ({ distance, objectRef }) => {
  const [isNearScreen, setIsNearScreen] = useState(false)

  useEffect(() => {
    let observer

    const element = objectRef.current

    const onChange = (entries) => {
      const el = entries[0]
      setIsNearScreen(el.isIntersecting)
    }

    Promise.resolve(
      typeof IntersectionObserver !== 'undefined'
        ? IntersectionObserver
        : import('intersection-observer')
    ).then(() => {
      observer = new IntersectionObserver(onChange, {
        rootMargin: distance
      })
  
      if (element) observer.observe(element)
    })

    return () => observer && observer.disconnect()
  })

  return { isNearScreen }
}

export default useIntersectionObserver
