import React from 'react'

function Input({
  label,
  onChange,
  error,
  value,
  name,
  noLabel,
  className,
  maxLength,
  message,
  placeholder
}){
  return(
    <div>
      {
        !noLabel &&
        <label className="input-label" htmlFor={label}>
          {label}
        </label>
      }
      <input
        type="text"
        placeholder={placeholder ?? (error ? "" : `Enter ${label}`)}
        className={className ? className : "new-input"}
        id={label}
        name={name}
        onChange={onChange}
        defaultValue={value}
        maxLength={maxLength}
        style={{ border: error ? '1px solid #ED254E' : '1px solid #000'
        , backgroundColor: error ? '#EBEBEB' : '#FFF'}}
      />
      <p className="error-message">{message}</p>
    </div>
  )
}

export default Input
