/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "../legacy/components/Layout";
import ResourcesSearch from "../legacy/components/ResourcesSearch";
import ResourceWide from "../legacy/components/ResourcesWide";
import ResourcesService from "../services/ResourcesService";
import YoutubeModal from "../components/modals/YoutubeModal";
import NoResultsFound from "../legacy/components/NoResultsFound";
import { useInfiniteScrollPagination } from "../hooks";
import { defaultCards } from "../utils/defaults";
import "../legacy/stylesheets/resources_section.scss";
import ScrollToTopButton from "../components/buttons/ScrollToTopButton";

const ALLOWED_URL_PARAMS = ["level", "format", "stack", "page"];

const Resources = () => {
  const navigate = useNavigate();
  const { resources: storeResources, loading: loadingResources } = useSelector(
    (state) => state.resources
  );
  const [resources, setResources] = useState(defaultCards(storeResources));
  const [showMobileFiltersView, setShowMobileFiltersView] = useState(false);
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [search, setSearch] = useState({ term: "" });
  const [filters, setFilters] = useState({
    level: [],
    format: [],
    stack: [],
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const updateWindowWidth = (w) => setWindowWidth(window.innerWidth);

  const searchRef = useRef();
  const objectRef = useRef();

  const { page: currentPage, setNextPage, resetPage } = useInfiniteScrollPagination(
    objectRef,
    () => {
      fetchResources({
        event: null,
        page: currentPage,
        query: search.term,
        filterObject: filters,
        keepOld: true
      });
    }
  );

  const setSearchTerm = (searchTerm) => {
    setSearch({
      term: searchTerm,
    });
  };

  const setFilter = (filterName, filterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [filterName]: filterValue,
    }));
    updateUrlParam(filterName, filterValue);
  };

  const resetResources = () => {
    setResources(storeResources);
  };

  const resetFilters = () => {
    setSearch({
      term: "",
    });

    setFilters({
      level: [],
      format: [],
      stack: [],
    });

    resetResources();

    searchRef.current.value = "";
    window.history.replaceState("", "", window.location.pathname);
  };

  const fetchResources = ({ event, page, query, filterObject, keepOld }) => {
    event?.preventDefault?.();
  
    const filterArray = [];
  
    if (filterObject?.level?.length) {
      filterArray.push(`&level_list=${filterObject.level.join(",")}`);
    }
    if (filterObject?.stack?.length) {
      filterArray.push(`&topic_list=${filterObject.stack.join(",")}`);
    }
    if (filterObject?.format?.length) {
      filterArray.push(`&format_list=${filterObject.format.join(",")}`);
    }
  
    let payload = filterArray.length
      ? `${filterArray.join("")}&page=${page}`
      : `page=${page}`;
  
    if (query) {
      payload = `query=${query}&${payload}`;
    }
  
    const fetchFromService = (isSearch) => {
      const serviceMethod = isSearch
        ? ResourcesService.searchResources
        : ResourcesService.getResources;
  
      serviceMethod(payload).then((data) => {
        setResources((prevResources) => {
          if (keepOld) {
            return [...prevResources, ...(data?.resources || [])];
          } else {
            return data?.resources;
          }
        });
        setNextPage(data?.meta?.next_page);
      });
    };
  
    if (query || filterArray.length > 0) {
      // If there is a search query or filters we use the search endpoint
      fetchFromService(true);
    } else {
      // else we use the default resources endpoint
      fetchFromService(false);
    }
  };
  
  // Parses url params and sets filters
  const parseUrlParamsAndSetFilters = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const filterObject = {};
    ALLOWED_URL_PARAMS.forEach((param) => {
      if (urlParams.has(param)) {
        filterObject[param] = urlParams.get(param).split(",");
      }
    });
    for (let key in filterObject) {
      setFilter(key, filterObject[key]);
    }
  };

  const toggleShowMobileFiltersView = () => {
    setShowMobileFiltersView(!showMobileFiltersView);
  };

  // Updates url params when filters change
  const updateUrlParam = (paramName, paramValues) => {
    const urlParams = new URLSearchParams(window.location.search);
    let newUrlParamsString = "";
    for (let i of urlParams.keys()) {
      if (i !== paramName && ALLOWED_URL_PARAMS.indexOf(i) > -1) {
        newUrlParamsString =
          newUrlParamsString + i + "=" + urlParams.get(i) + "&";
      }
    }
    if (paramValues.length > 0) {
      newUrlParamsString =
        newUrlParamsString + paramName + "=" + paramValues.join(",");
    }
    if (newUrlParamsString !== "") {
      newUrlParamsString = "?" + newUrlParamsString;
    }
    if (newUrlParamsString[newUrlParamsString.length - 1] === "&") {
      newUrlParamsString = newUrlParamsString.substring(
        0,
        newUrlParamsString.length - 1
      );
    }
    newUrlParamsString === ""
      ? navigate(window.location.pathname, { replace: true })
      : navigate(newUrlParamsString, { replace: true });
  };

  const filtersCount = Object.values(filters).reduce(
    (acc, x) => acc + x.length,
    0
  );

  // Handle window resize
  useEffect(() => {
    updateWindowWidth();
    window.addEventListener("resize", updateWindowWidth);
  }, []);

  // Set store resources if filters and search term are empty
  useEffect(() => {
    if (filtersCount || search.term || !storeResources.length) return;
    setResources(storeResources);
  }, [filters, search.term, storeResources]);

  useEffect(() => {
    // Check if there are filters or search term, if not parse URL parameters
    if (!(filtersCount || search.term)) {
      parseUrlParamsAndSetFilters();
    }
    
    // Execute fetch if filters or search term are present
    if (filtersCount || search.term) {
      fetchResources({
        event: null,
        page: 1,
        query: search.term,
        filterObject: filters,
        keepOld: false
      });
    }
  }, [filters, search.term]);

  return (
    <Layout
      loading={false}
      modal={
        youtubeUrl !== "" && (
          <YoutubeModal youtubeUrl={youtubeUrl} setYoutubeUrl={setYoutubeUrl} />
        )
      }
    >
      <div id="resources-section">
        <div>
          <ResourcesSearch
            fetchResources={fetchResources}
            filters={filters}
            numOfFiltersSet={0}
            ref={searchRef}
            resetFilters={resetFilters}
            resetPage={resetPage}
            search={search}
            setSearchTerm={setSearchTerm}
            setFilter={setFilter}
            showMobileFiltersView={showMobileFiltersView}
            skeleton={loadingResources}
            toggleShowMobileFiltersView={toggleShowMobileFiltersView}
            windowWidth={windowWidth}
          />
          {!showMobileFiltersView && (
            <div className="resources-content">
              {resources?.length > 0 ? (
                <div className="search-results-container">
                  <div className="search-results">
                    {resources?.map((resource, idx) => {
                      return (
                        <div className="resource-container" key={`resource-${resource?.id}-${idx}`}>
                          <ResourceWide
                            resource={resource}
                            setYoutubeUrl={setYoutubeUrl}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div id="visor" ref={objectRef}></div>
                </div>
              ) : (
                <NoResultsFound
                  showResourcesDescription={true}
                  resetFilters={resetFilters}
                />
              )}
            </div>
          )}
        </div>
        <ScrollToTopButton hide={currentPage === 1} />
      </div>
    </Layout>
  );
};

export default Resources;
