import CareerSettingsEdit from './CareerSettingsEdit';
import QuestionWithFilters from './QuestionWithFilters';
import QuestionWithText from './QuestionWithText';

const CareerSettings = ({
  isEdit,
  setEdit,
  profile,
  setProfile,
  editData,
  successFlash,
  errorFlash,
  screenWidth,
  fetchProfile,
  skeleton
}) => {
  const consentValue = (value) => (value ? 'Yes' : 'No');
  return isEdit ? (
    <CareerSettingsEdit
      isEdit={isEdit}
      setEdit={setEdit}
      successFlash={successFlash}
      errorFlash={errorFlash}
      data={editData}
      screenWidth={screenWidth}
      profile={profile}
      setProfile={setProfile}
      fetchProfile={fetchProfile}
    />
  ) : (
    <div className="settings-wrapper">
      <div className="flex-wrap">
        <QuestionWithFilters
          question="How would you best describe your job search?"
          filters={profile.job_search}
          skeleton={skeleton}
        />
        <div style={{ marginRight: '10%' }} />
        <div className="question-block">
          <QuestionWithText
            noTopMargin={true}
            question="Are you open to new career opportunities?"
            answer={profile.career_opportunities}
            skeleton={skeleton}
          />
        </div>
      </div>
      <div className="flex-wrap">
        <div className="question-block">
          <QuestionWithText
            question="When did you get your first tech job?"
            answer={profile.starter_year_in_tech}
            skeleton={skeleton}
          />
        </div>
        <div style={{ marginRight: '10%' }} />
        <div className="question-block">
          <QuestionWithText
            question="Your Resume"
            answer={profile.resume_filename}
            skeleton={skeleton}
          />
        </div>
      </div>
      <div className="flex-wrap" style={{ marginTop: 48 }}>
        <QuestionWithFilters
          question="Which WWCode Communities would you like to join?"
          filters={profile.network_titles}
          skeleton={skeleton}
        />
        <div style={{ marginRight: '10%' }} />
        <div className="question-block">
          <QuestionWithText
            question="Would you like to receive the Career Nav edition of Code Review in your inbox?"
            answer={
              consentValue(profile.information_consented_at) === 'Yes'
                ? 'Yes'
                : 'No'
            }
            noTopMargin={true}
            skeleton={skeleton}
          />
        </div>
      </div>
      <div className="flex-wrap" style={{ marginTop: 48 }}>
        <QuestionWithFilters
          question="What do you hope to gain from the community?"
          filters={profile.why_join_list}
          skeleton={skeleton}
        />
      </div>
    </div>
  );
};
export default CareerSettings;
