import { FormWithSearch } from "../../../legacy/components/NewOnboarding";

const Step13 = ({ preferredLocation, updatePreferredLocation }) => (
  <FormWithSearch
    searchId="preferred_locations_attributes"
    locationSearch={preferredLocation}
    setLocation={(location) => updatePreferredLocation(location)}
  />
);

export default Step13;
