import ReCAPTCHA from "react-google-recaptcha";
import useForm from '../../hooks/useForm'
import './ApplyToJobModal.scss'
import Modal from './Modal'
import Input from '../Input'
import Button from '../Button'
import Text from '../Text'
import schema from '../../utils/validations/applyToJob'
import JobsService from "../../services/JobsService";

const ReCAPTCHAKEY = process.env.REACT_APP_PUBLIC_GOOGLE_RECAPTCHA

const ApplyToJobModal = ({ open, close, selectedJob, showAlert }) => {

	const handleSubmit = async values => {
		const { success } = await JobsService.applyToJob({ ...values, jobId: selectedJob.id })
		showAlert(success ? 'success' : 'error', 2000)
		reset()
		close()
	}

	const initialState = { email: '', link: '', message: '', captcha: null }
	const {
		subscribe, inputs, errors, submit, reset
	} = useForm(initialState, handleSubmit, schema)

	return(
		<Modal isOpen={open} title="Submit application" close={close}>
			<form onSubmit={submit} className="ApplyToJobModal">
				<Text>
					Send the company your contact information, portfolio and a brief message.
					Tell them how awesome you are by sharing your skills and experience!
				</Text>
				<section className="ApplyToJobModal__body">
					<div className="ApplyToJobModal__left">
						<Input
							onChange={subscribe('email')}
							label="Email"
							value={inputs.email}
							error={errors.email}
						/>
						<Input
							onChange={subscribe('link')}
							label="Linkedin or portfolio link"
							value={inputs.link}
							error={errors.link}
						/>
						<div className="ApplyToJobModal__recaptcha">
							<ReCAPTCHA sitekey={ReCAPTCHAKEY} onChange={subscribe('captcha')} />
						</div>
						{errors.captcha && (
							<span className="ApplyToJobModal__recaptcha--error">{errors.captcha}</span>
						)}
					</div>
					<div className="ApplyToJobModal__right">
						<Input 
							onChange={subscribe('message')}
							label="Message"
							value={inputs.message}
							type="TextArea"
							maxLength={1000}
							error={errors.message}
						/>
					</div>
				</section>
				<Button type="submit" className="ApplyToJobModal__button">
					SUBMIT
				</Button>
			</form>
		</Modal>
	)
}

export default ApplyToJobModal
