import { BaseService } from "./BaseService"

const TimezonesService =  {
	getTimezones: async() => {
		try {
			const data = await BaseService.get(`timezones`)
			return data
		} catch(e) {
			console.error(e)
		}
	},
}

export default TimezonesService
