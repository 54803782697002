import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate, Link } from "react-router-dom";
import useForm from "../hooks/useForm";
import Alert from "../components/Alert";
import Input from "../components/Input";
import Select from "../components/Select";
import Layout from "../legacy/components/Layout";
import ApplaudHersService from "../services/ApplaudHersService";
import NetworksService from "../services/NetworksService";
import schema from "../utils/validations/applaudHer";
import useAlert from "../hooks/useAlert";
import '../styles/applaudher_form.scss';

const ReCAPTCHAKEY = process.env.REACT_APP_PUBLIC_GOOGLE_RECAPTCHA;

const Banner = () => {
  return (
    <div className="applaudher-form-banner-container">
      <h1><i>Submit an #ApplaudHer</i></h1>
    </div>
  )
};

const ApplaudHerForm = () => {
  const navigate = useNavigate();
  const { showAlert, alert } = useAlert();
  const initialState = {
    firstName: '',
    lastName: '',
    socialUrl: '',
    network: 0,
    description: '',
    captcha: null
  };

  const [alertMessage, setAlertMessage] = useState('');
  const [networks, setNetworks] = useState([]);

  const fetchNetworks = () => {
    NetworksService.getNetworks().then((data) => {
      const networkTitlesWithIds = [{ title: '-- Choose a network --', id: 0 }, ...data.networks.map((network) => ({ title: network.title, id: network.id }))];
      setNetworks(networkTitlesWithIds);
    })
  };

  useEffect(() => {
    fetchNetworks();
  }, []);

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    subscribe(name)(value);
  };
  
  const handleCaptchaChange = (captchaValue) => {
    subscribe('captcha')(captchaValue);
  };

  const handleSubmit = async (values) => {
    if (!values.captcha) {
      setAlertMessage("Please verify the captcha");
      showAlert('error', 3500);
      return;
    }

    try {
      await ApplaudHersService.createApplaudHer(values);
      console.log('ApplaudHer created successfully');
      navigate('/applaudher', { state: { submitted: true } });
    } catch (error) {
      setAlertMessage('Error creating ApplaudHer:', error.message);
      showAlert('error', 3500);
    }
  };

  const { subscribe, inputs, errors, submit } = useForm(initialState, handleSubmit, schema);

  return (
    <Layout>
      <Alert {...alert}>
        {alertMessage}
      </Alert>
      <Banner />
      <div className="applaudher-form-container">
        <div className="applaudher-intro-text">
          <h1>#ApplaudHer</h1>
          <p>Women Who Code highlights the achievements of our members by celebrating them with ApplaudHers. These are acknowledgments of professional accomplishments such as obtaining a new technical job, receiving a promotion, speaking at a conference, learning a new programming language, or stepping up as a Director and/or Lead in your network.</p>
          <br/>
          <p>ApplaudHers can be submitted for yourself or others.</p>
          <br/>
          <p>Examples:</p>
          <ol>
            <li><p>&bull; Josephine Tam of WWCode SF accepted a job with AcmeCo as a Technical Support Engineer.</p></li>
            <li><p>&bull; Sarah Masud of WWCode Bangalore gave a talk on open source licenses at FOSSMEET 2017.</p></li>
          </ol>
          <br/>
          <p>Check out the #ApplaudHer page <Link to="/applaudher" className="applaudher-link">here</Link> to see more!</p>
        </div>
        <form onSubmit={submit}>
          <div className="input-group">
            <Input
              label="RECIPIENT'S FIRST NAME"
              name="firstName"
              type="Input"
              onChange={subscribe('firstName')}
              value={inputs.firstName}
              error={errors.firstName}
            />
          </div>
          
          <div className="input-group">
            <Input
              label="RECIPIENT'S LAST NAME"
              name="lastName"
              type="Input"
              onChange={subscribe('lastName')}
              value={inputs.lastName}
              error={errors.lastName}
            />
          </div>

          <div className="input-group">
            <Input
              label="RECIPIENT'S @TWITTERHANDLE OR LINKEDIN URL"
              name="socialUrl"
              type="Input"
              onChange={subscribe('socialUrl')}
              value={inputs.socialUrl}
              error={errors.socialUrl}
            />
          </div>

          <div className="input-group">
            <Select
              label="RECIPIENT'S WWCODE NETWORK"
              name="network"
              options={networks}
              onChange={handleSelectChange}
              value={inputs.network}
              error={errors.network}
              className="custom-select-class"
            />
          </div>

          <div className="input-group">
            <Input
              label="#APPLAUDHER"
              name="description"
              type="TextArea"
              onChange={subscribe('description')}
              value={inputs.description}
              error={errors.description}
              maxLength="150"
            />
          </div>

          <div className="input-group">
            <div className="recaptcha-wrapper">
              <ReCAPTCHA
                sitekey={ReCAPTCHAKEY}
                onChange={handleCaptchaChange}
              />
            </div>
            {errors.captcha && <span className="recaptcha-error">{errors.captcha}</span>}
          </div>

          <div className="submit-container">
            <button type="submit">Submit #ApplaudHer</button>
          </div>
        </form>
      </div>
    </Layout>
  )
};

export default ApplaudHerForm;