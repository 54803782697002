import React, { useState, useEffect } from "react";
import Filter from "./Filter";
import { LocationSearchRef } from "./LocationSearch";
import "./jobs_search.scss";
import TagsService from "../../services/TagsService";
import SuggestionsService from "../../services/SuggestionsService";
import $ from "jquery"
import "jquery-ui/themes/base/core.css";
import "jquery-ui/themes/base/theme.css";
import "jquery-ui/themes/base/autocomplete.css";
import "jquery-ui/ui/core";
import "jquery-ui/ui/widgets/autocomplete";
import "./autocomplete.scss";
import Skeleton from "../../components/Skeleton";
import debounce from "just-debounce-it";

const onsiteRemote = ["On Site", "Remote"];
const jobType = ["Full time", "Part time", "Contract"];
const levels = ["Internship", "Returnship", "Junior", "Mid", "Senior"];

const JobsSearch = React.forwardRef(
  (
    {
      fetchJobs,
      filters,
      numOfFiltersSet,
      resetFilters,
      search,
      setFilter,
      setLocations,
      setSearchTerm,
      skeleton,
      windowWidth,
    },
    ref
  ) => {
    const [showFilter, setShowFilter] = useState("");
    const [showMobileFiltersView, setShowMobileFiltersView] = useState(false);
    const [stacks, setStacks] = useState([]);

    const isMobile = windowWidth <= 720;

    const fetchSearchSuggestions = () => {
      SuggestionsService.getSuggestions("Job").then((data) => {
        $("#job-search-term").autocomplete({
          source: function (request, response) {
            var results = $.ui.autocomplete.filter(data, request.term);
            response(results.slice(0, 10));
          },
          minLength: 1
        })
      });
    };

    const fetchStacks = () => {
      TagsService.getStacks().then((data) => {
        const stacks = data.list.map(x => x.name)
        setStacks(stacks)
      });
    }

    const toggleShowMobileFiltersView = () => {
      setShowMobileFiltersView(!showMobileFiltersView);
    };

    const handleChange = (e) => {
      setSearchTerm(e.target.value);
    };

    const debouncedHandleKeyPress = debounce(handleChange, 300);

    useEffect(() => {
      fetchSearchSuggestions();
      fetchStacks();
    }, [])

    return (
      <form className="jobs-search">
        <div className="top-row">
          <div className="input-container">
            {skeleton
              ? <Skeleton />
              : <>
                <input
                  className="search-term"
                  id="job-search-term"
                  type="text"
                  placeholder="What"
                  onKeyUp={debouncedHandleKeyPress}
                  ref={ref[0]}
                />
                <ion-icon class="icon" name="search" />
              </>
            }
          </div>
          {!isMobile && (
            <div
              className={
                "filters-icon-container " +
                (numOfFiltersSet > 0 ? "filters-set" : "")
              }
              onClick={toggleShowMobileFiltersView}
            >
              <ion-icon class="filters-icon" name="options" />
              <span className="num-of-filters-set">{numOfFiltersSet}</span>
            </div>
          )}
          {skeleton ? (
            <>
              <Skeleton w="50%" h={48} />
              <Skeleton w={87} h={48} ml={14} radius={8} />
            </>
          ) : (
            <>
              <LocationSearchRef search={filters} setLocations={setLocations} ref={ref[1]} />
              <div style={{ display: 'flex', width: isMobile ? '100%' : ''}}>
                <button
                  type="submit"
                  id="search-submit"
                  onClick={(e) => {
                    fetchJobs({
                      event: e,
                      page: 1,
                      query: search.term,
                      filterObject: filters,
                      keepOld: false
                    });
                    setShowFilter("");
                    setFilter("what", [search.term]);
                  }}
                >
                  SEARCH
                </button>
                {isMobile && (
                  <div
                    className={
                      "filters-icon-container " +
                      (numOfFiltersSet > 0 ? "filters-set" : "")
                    }
                    onClick={toggleShowMobileFiltersView}
                  >
                    <ion-icon class="filters-icon" name="options" />
                    <span className="num-of-filters-set">{numOfFiltersSet}</span>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        {(!isMobile || showMobileFiltersView) && (
          <div className="bottom-row">
            {showMobileFiltersView && (
              <div>
                <div
                  className="close-icon-container"
                  onClick={toggleShowMobileFiltersView}
                >
                  <ion-icon class="close-icon" name="close" />
                </div>
                <div className="filters-label">Filters</div>
              </div>
            )}
            {skeleton ? (
              <>
                <Skeleton className="filter-container" w="25%" h={36} radius={40} />
                <Skeleton className="filter-container" w="25%" h={36} radius={40} />
                <Skeleton className="filter-container" w="25%" h={36} radius={40} />
                <Skeleton className="filter-container" w="25%" h={36} radius={40} />
              </>
            ) : (
              <>
                <Filter
                  showFilter={showFilter}
                  filterType="level"
                  filterOptions={levels}
                  selectedFilterOptions={filters.level}
                  setShownFilter={setShowFilter}
                  setFilter={setFilter}
                  showMobileFilterView={showMobileFiltersView}
                />
                <Filter
                  showFilter={showFilter}
                  filterType="onsite / remote"
                  filterOptions={onsiteRemote}
                  selectedFilterOptions={filters.onsiteRemote}
                  setShownFilter={setShowFilter}
                  setFilter={setFilter}
                  showMobileFilterView={showMobileFiltersView}
                />
                <Filter
                  showFilter={showFilter}
                  filterType="job type"
                  filterOptions={jobType}
                  selectedFilterOptions={filters.jobType}
                  setShownFilter={setShowFilter}
                  setFilter={setFilter}
                  showMobileFilterView={showMobileFiltersView}
                />
                <Filter
                  showFilter={showFilter}
                  filterType="stack"
                  filterOptions={stacks}
                  selectedFilterOptions={filters.stack}
                  setShownFilter={setShowFilter}
                  setFilter={setFilter}
                  showMobileFilterView={showMobileFiltersView}
                />
              </>
            )}
            {skeleton ? (
              <Skeleton w={87} h={36} radius={8} />
            ) : (
              <>
                {showMobileFiltersView ? (
                  <div className="mobile-filter-buttons">
                    <button
                      className="apply-button"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        fetchJobs({
                          event: e,
                          page: 1,
                          query: search.term,
                          filterObject: filters,
                          keepOld: false
                        })
                        toggleShowMobileFiltersView();
                        setFilter("what", [search.term]);
                      }}
                    >
                      APPLY
                    </button>
                    <button
                      className="reset-button"
                      type="button"
                      onClick={resetFilters}
                    >
                      RESET
                    </button>
                  </div>
                ) : (
                  <button
                    className="reset-button"
                    type="button"
                    onClick={resetFilters}
                  >
                    RESET
                  </button>
                )}
              </>
            )}
          </div>
        )}
      </form>
    );
  }
);

export default JobsSearch;
