import React from 'react'
import ProgressBar from '../../../components/modals/onboarding/ProgressBar'

function SkipProgressWrapper({ topMargin, step, goToPage }){
  return(
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <div className="skip-button"
          style={{ marginLeft: 60}}
          onClick={() => goToPage(step - 1)}
        >
          <img src="/images/right-green-arrow.png"style={{ transform: 'scaleX(-1)'}} alt="Right arrow"/>
          <h6 className="skip-text" style={{ marginLeft: 10 }}>Back</h6>
        </div>
        <div className="skip-button"
          style={{ marginRight: 60}}
          onClick={() => goToPage(step + 1, true)}
        >
          <h6 className="skip-text">Skip</h6>
          <img src="/images/right-green-arrow.png" alt="Right arrow"/>
        </div>
      </div>
      <div style={{ marginBottom: topMargin }}/>
      <div>
        <ProgressBar step={step} steps={17}/>
      </div>
    </React.Fragment>
  )
}

export default SkipProgressWrapper
