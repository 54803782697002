import { BaseService } from "./BaseService";

const NetworksService = {
  getNetworks: async () => {
      try {
        const data = await BaseService.get(`networks`);
        return data;
      } catch (e) {
        console.error(e);
      }
  },
  getNetworkRecommendations: async () => {
    try {
      const data = await BaseService.get(`networks/recommendations`);
      return data;
    } catch (e) {
      console.error(e);
    }
  },
};

export default NetworksService;
