import LocationSettingsEdit from "./LocationSettingsEdit";
import QuestionWithFilters from "./QuestionWithFilters";
import QuestionWithText from "./QuestionWithText";
import { getStringListFromArray } from "./utils/FormUtils";

const LocationSettings = ({
  isEdit,
  setEdit,
  profile,
  setProfile,
  editData,
  successFlash,
  errorFlash,
  fetchProfile,
  skeleton,
}) => {
  const {
    location,
    preferred_locations_attributes,
    preferred_timezones,
    relocate,
    work_location,
  } = profile;

  const getLocationNames = (l) => {
    if (!l) return "";
    if (!l[0]) return "";
    let formatted_addresses = Object.values(l)
      .map((x) => x.address_attributes)
      .map((x) => x.formatted_address);
    return formatted_addresses.filter((x) => x !== null);
  };

  return isEdit ? (
    <LocationSettingsEdit
      isEdit={isEdit}
      setEdit={setEdit}
      successFlash={successFlash}
      errorFlash={errorFlash}
      data={editData}
      profile={profile}
      setProfile={setProfile}
      fetchProfile={fetchProfile}
    />
  ) : (
    <div className="settings-wrapper">
      <div className="flex-wrap">
        <div className="question-block">
          <QuestionWithText
            noTopMargin={true}
            question="Where are you located?"
            answer={location}
            skeleton={skeleton}
          />
        </div>
        <div style={{ marginRight: "10%" }} />
        <QuestionWithFilters
          question="Which job locations would you apply to?"
          filters={getLocationNames(preferred_locations_attributes)}
          skeleton={skeleton}
        />
      </div>
      <div className="flex-wrap">
        <div className="question-block">
          <QuestionWithText
            question="Are you willing to relocate?"
            answer={relocate}
            skeleton={skeleton}
          />
        </div>
        <div style={{ marginRight: "10%" }} />
        <div className="question-block">
          <h5 className="text-header text-header--margin">
            What is your preferred work environment?
          </h5>
          <p className="text-answer">
            {getStringListFromArray(work_location)}
          </p>
        </div>
      </div>
      <div style={{ marginTop: 48 }} />
      <QuestionWithFilters
        question="What timezones are you willing to work in?"
        filters={preferred_timezones}
        skeleton={skeleton}
      />
    </div>
  );
};

export default LocationSettings;
