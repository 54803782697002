import { configureStore } from '@reduxjs/toolkit';
import { alertsReducer } from './slices/alertsSlice';
import { applaudHersReducer } from './slices/applaudhersSlice';
import { authReducer } from './slices/authSlice';
import { eventsReducer } from './slices/eventsSlice';
import { favoritesReducer } from './slices/favoritesSlice';
import { jobsReducer } from './slices/jobsSlice';
import { pagesReducer } from './slices/pagesSlice';
import { resourcesReducer } from './slices/resourcesSlice';


export const store = configureStore({
  reducer: {
    alerts: alertsReducer,
    applaudHers: applaudHersReducer,
    auth: authReducer,
    events: eventsReducer,
    favorites: favoritesReducer,
    jobs: jobsReducer,
    pages: pagesReducer,
    resources: resourcesReducer,
  },
});
