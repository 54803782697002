import React from "react";
import { useSelector } from "react-redux";
import Format from "./utils/Format";
import Favorite from "./Favorite";
import CopyLink from "./CopyLink";
import Skeleton from "../../components/Skeleton";
import "./job_details.scss";

const JobDetails = React.forwardRef(({ job, timeZoneList, apply, setSelectedJob, skeleton }, ref) => {

  const {
    company_name,
    salary,
    currency,
    title,
    salary_period,
    benefits,
    experience_list,
    stack_list,
    addresses,
    time_status,
    remote,
    remote_timezone,
    apply_url,
    description,
    job_details,
    responsibilities,
    id,
  } = job ?? {};

  const favorites = useSelector((state) => state.favorites.jobs) || [];
  const isFavorite = favorites.some((favoriteJob) => favoriteJob?.id === id);

  const format = new Format();

  const hiringProcess =
    job_details &&
    job_details
      .filter((x) => x.kind === "hiring_process")
      .sort((x, y) => x.step_number - y.step_number);

  if ((!id || job?.status === "expired" ) && !skeleton) {
    return (
      <div className="job-details">
        <h2 className="title">
          {!id ? (
            "The job you're looking for does not exist."
          ) : (
            "Thank you for your interest in this role. The job you're looking for has expired."
          )}
        </h2>
      </div>
    )
  }

  return (
    <div className="job-details" ref={ref}>
      {
        setSelectedJob && (
          <button className="back-to-jobs-button" onClick={() => setSelectedJob({})}>
            <ion-icon class="back-icon" name="chevron-back" />
            Back to Jobs
          </button>
        )
      }
      {skeleton ? (
        <>
          <Skeleton rounded className="title" w={378} h={32} mb={9} />
          <Skeleton rounded className="company" w={89} h={16} mb={7} />
          <Skeleton rounded className="level" w={41} h={15} mb={13} />
          <Skeleton rounded className="salary-range" w={176} h={20} mb={3} />
          <Skeleton
            rounded
            className="salary-time-frame"
            w={136}
            h={16}
            mb={14}
          />
          <Skeleton rounded className="location" w={175} h={16} mb={13} />
          <Skeleton rounded className="tags" w={199} h={16} />
        </>
      ) : (
        <>
          <h2 className="title">{title}</h2>
          <div className="company">{company_name}</div>
          {experience_list && (
            <div className="level">
              {format.capitalizeFirstLetter(experience_list, ", ")}
            </div>
          )}
          <div className="salary-range">{salary}</div>
          <div className="salary-time-frame">
            {!!salary && (
              `${currency} ${format.removeUnderscrore(salary_period)},${" "}`
            )}

            {format.capitalizeFirstLetter(time_status, " ")}
          </div>
          <div className="location">
            <ion-icon class="location-icon" name="location" />
            {remote && !remote_timezone && "Remote"}
            {remote &&
              remote_timezone &&
              ` Remote in ${format.abbrTimezoneWithOffset(
                remote_timezone,
                timeZoneList
              )}`}
            {remote && format.displayTimezone(remote_timezone)}
            {addresses?.length > 0 && (
              <>
                {remote && " or "}
                {addresses[0].city && `${addresses[0].city}, `}
                {addresses[0].state && `${addresses[0].state}, `}
                {addresses[0].country}
              </>
            )}
          </div>
          {stack_list?.length > 0 && (
            <div className="tags">
              <span className="tag">
                {format.capitalizeFirstLetter(stack_list, " | ")}
              </span>
            </div>
          )}
        </>
      )}
      <div className="buttons-container">
        {skeleton ? (
          <Skeleton w={78} h={48} radius={8} />
        ) : apply_url ? (
          <a href={apply_url} target="_blank" rel="noreferrer">
            APPLY
          </a>
        ) : (
          <a onClick={apply} href="/#">
            APPLY
          </a>
        )}
        {skeleton ? (
          <>
            <Skeleton w={99} h={48} radius={8} ml={12} />
            <Skeleton w={40} h={40} radius={8} ml={12} mt={4} />
          </>
        ) : (
          <>
            <Favorite type="jobs" item={{...job}} favorite={isFavorite} button />
            <div className="mobile-copy-link-container">
              <CopyLink url={window.location.host + "/jobs?id=" + id} />
            </div>
          </>
        )}
        <button className="copy-link-button" type="button">
          <CopyLink url={window.location.host + "/jobs?id=" + id} />
          SHARE
        </button>
      </div>
      <div className="gray-line" />
      {skeleton ? (
        <div className="about-us-container">
          <Skeleton rounded w={89} h={20} mb={20} />
          <Skeleton w="100%" h={68} className="about-us" />
        </div>
      ) : (
        <div className="about-us-container">
          <h3>About Us</h3>
          <div className="about-us">{description}</div>
        </div>
      )}
      {skeleton ? (
        <div className="what-you-will-do-container">
          <Skeleton rounded w={89} h={20} mb={20} />
          <Skeleton w="100%" h={68} className="about-us" />
        </div>
      ) : (
        <div className="what-you-will-do-container">
          <h3>What You'll Do</h3>
          <div className="what-you-will-do">
            {responsibilities}
          </div>
        </div>
      )}
      {skeleton ? (
        <div className="benefits-container">
          <Skeleton rounded w={89} h={20} mb={20} />
          <Skeleton w="100%" h={68} className="about-us" />
        </div>
      ) : (benefits?.length > 0 && (
        <div className="benefits-container">
          <h3>Benefits</h3>
          <div className="what-you-will-do">
            <ul className="benefits">
              {benefits?.map((benefit, index) => (
                <li key={index} className="benefit" title={benefit.name}>
                  <div
                    className="benefit-icon"
                    title={benefit.name}
                    dangerouslySetInnerHTML={{ __html: benefit.icon_xml }}
                  />
                  {format.capitalizeFirstLetter(benefit.name, " ")}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
      {skeleton ? (
        <div className="hiring-process-container">
          <Skeleton rounded w={89} h={20} mb={20} />
          <Skeleton w="100%" h={68} className="about-us" />
        </div>
      ) : (
        <div className="hiring-process-container">
          <h3>Our Hiring Process</h3>
          <div className="hiring-process">
            {hiringProcess &&
              hiringProcess.map((t, i) => (
                <p className="hiring-process-item" key={i}>
                  <span className="hiring-process-item__step">Step {i + 1}:</span>{" "}
                  {t.description}
                </p>
              ))}
          </div>
        </div>
      )}
    </div>
  );
});

export default JobDetails;
