import { BaseService } from "./BaseService";

const JobsService = {
  getJobs: async (payload) => {
    let url = 'jobs';
    if (payload) url = `jobs?${payload}`;
    try {
      const data = await BaseService.get(url);
      return { jobs: data.jobs, ...data };
    } catch (e) {
      console.error(e);
      return { jobs: [] };
    }
  },
  getJobById: async (id) => {
    if (!id) throw new Error('No id provided');
    try {
      const data = await BaseService.get(`jobs/${id}`);
      return { ...data };
    } catch (e) {
      console.error(e);
      return {};
    }
  },
  searchJobs: async (payload) => {
    try {
      const data = await BaseService.get(`jobs/search?${payload}`);
      return { jobs: data.jobs, ...data };
    } catch (e) {
      console.error(e);
      return { jobs: [] };
    }
  },
  getJobsData: async () => {
    try {
      const data = await BaseService.get(`jobs/jobs_page`);
      return { jobsData: data };
    } catch (e) {
      console.error(e);
      return { JobsData: null };
    }
  },
  applyToJob: async ({ captcha, message, email, link, jobId }) => {
    try {
      const payload = {
        "g-recaptcha-response": captcha,
        job_id: jobId,
        email_forwarder: {
          from_email: email,
          metadata: {
            message,
            portfolio_url: link
          }
        }
      }
      await BaseService.post(`jobs/submit`, payload, false);
      return { success: true }
    } catch (e) {
      console.error(e);
      return { success: false }
    }
  }
};

export default JobsService;
