import { useState } from "react";
import "./RadioButtons.scss";
import Radio from "../../legacy/components/Radio";
import Checkbox from "../Checkbox";

const HorizontalButton = ({ Icon, text, isSelected, onClick, isCheckbox }) => (
  <div
    className={`HorizontalButton ${
      isSelected ? "HorizontalButton--active" : ""
    }`}
    onClick={onClick}
  >
    {isCheckbox ? (
      <Checkbox checked={isSelected} label={text} onChange={() => {}} />
    ) : (
      <Radio
        value={text}
        onChange={() => {}}
        name={text}
        label={text}
        checked={isSelected}
      />
    )}
    <Icon
      className={`HorizontalButton__icon ${
        isSelected ? "HorizontalButton__icon--active" : ""
      }`}
    />
  </div>
);
const RadioButtons = ({ options, onChange, selectedValues, multiple }) => {
  const [selected, setSelected] = useState(selectedValues ?? []);
  const handleChange = (text) => {
    const val = text.toLowerCase();
    if (!multiple) {
      setSelected([val]);
      onChange(val);
      return;
    }
    const newSelected = selected.includes(val)
      ? selected.filter((x) => x !== val)
      : [...selected, val];
    setSelected(newSelected);
    onChange(newSelected);
  };

  return (
    <div className="RadioButtons">
      {options.map((option) => (
        <HorizontalButton
          {...option}
          key={option.text}
          onClick={() => handleChange(option.text)}
          isSelected={selected.includes(option.text.toLowerCase())}
          isCheckbox={multiple}
        />
      ))}
    </div>
  );
};

export default RadioButtons;
