import TechSettingsEdit from './TechSettingsEdit';
import QuestionWithFilters from './QuestionWithFilters';
import QuestionWithText from './QuestionWithText';
const TechSettings = ({
  isEdit,
  setEdit,
  profile,
  setProfile,
  editData,
  successFlash,
  errorFlash,
  fetchProfile,
  skeleton
}) => {
  const { current_stack_list, aspiring_stack_list, expertise_list, years_in_tech } =
    profile;

  return isEdit ? (
    <TechSettingsEdit
      setEdit={setEdit}
      profile={profile}
      data={editData}
      successFlash={successFlash}
      setProfile={setProfile}
      errorFlash={errorFlash}
      fetchProfile={fetchProfile}
    />
  ) : (
    <div className="settings-wrapper">
      <div className="flex-wrap">
        <QuestionWithFilters
          question="Which coding stacks do you use?"
          filters={current_stack_list}
          skeleton={skeleton}
        />
        <div style={{ marginRight: '10%' }} />
        <QuestionWithFilters
          question="Which coding stacks do you want to learn?"
          filters={aspiring_stack_list}
          skeleton={skeleton}
        />
      </div>
      <div style={{ marginTop: 48 }} />

      <div className="flex-wrap">
        <QuestionWithFilters
          question="How would you describe your level of expertise?"
          filters={expertise_list}
          skeleton={skeleton}
        />
        <div style={{ marginRight: '10%'}}/>
        <div className="question-block">
          <QuestionWithText
            question="How many years of experience in tech do you have?"
            answer={years_in_tech}
            noTopMargin={true}
            skeleton={skeleton}
          />
        </div>
      </div>
    </div>
  );
};
export default TechSettings;
