import React from 'react'
import PropTypes from 'prop-types'
import './no_results_found.scss'
class NoResultsFound extends React.Component{
  render(){
    return (
      <div className="no-results-found">
        <div className="title">
          There are no results for your search.
        </div>
        {
          this.props.showResourcesDescription &&
          <div className="description">
            We are constantly reviewing these resources. Please e-mail
            <a href="mailto:contact@womenwhocode.com">
              &nbsp;contact@womenwhocode.com&nbsp;
            </a>
            to recommend one!
          </div>
        }
        <button onClick={this.props.resetFilters}>
          CLEAR SEARCH
        </button>
      </div>
    );
  }
}

NoResultsFound.propTypes = {
  showResourcesDescription: PropTypes.bool,
  resetFilters: PropTypes.func.isRequired
};

export default NoResultsFound