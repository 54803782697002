import { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import EventsCard from "../legacy/components/EventsCard";
import EventsSearch from "../legacy/components/EventsSearch";
import FilterButtons from "../legacy/components/FilterButtons";
import Layout from "../legacy/components/Layout";
import NoResultsFound from "../legacy/components/NoResultsFound";
import PageBanner from "../legacy/components/PageBanner";
import "../legacy/stylesheets/events_section.scss";
import EventsService from "../services/EventsService";
import { useNavigate } from "react-router-dom";
import Format from "../legacy/components/utils/Format";
import { locationStateToUrlParams } from "../utils/locations";
import NetworksService from "../services/NetworksService";
import {
  getButtonsStateBasedOnUrlParams,
  getFiltersStateBasedOnUrlParams,
  getNumOfFilters,
  buttonsStateToSearchParams,
  filtersStateToSearchParams,
} from "../utils/eventsFilters";
import useInfiniteScrollPagination from "../hooks/useInfiniteScrollPagination";
import { defaultCards } from "../utils/defaults";
import { useAuth } from "../auth/useAuth";
import ScrollToTopButton from "../components/buttons/ScrollToTopButton";

const format = new Format();

const buttonsInitialState = [
  { text: "New", active: false, value: "new" },
  { text: "Featured", active: false, value: "featured" },
  { text: "Recently Viewed", active: false, value: "recently-viewed", requiresLogin: true },
];

const filtersInitialState = {
  community: [],
  location: [],
  inPersonOnline: [],
  stack: [],
};

const Events = () => {
  const navigate = useNavigate();
  const { loggedIn } = useAuth();

  const { loading: loadingEvents, events: storeEvents } = useSelector(
    (state) => state.events
  );

  const [communities, setCommunities] = useState([]);
  const [communitiesWithIds, setCommunitiesWithIds] = useState({});
  const [events, setEvents] = useState(defaultCards(storeEvents));
  const [searchTerm, setSearchTerm] = useState("");
  const [buttons, setButtons] = useState(
    getButtonsStateBasedOnUrlParams(buttonsInitialState)
  );
  const [filters, setFilters] = useState(
    getFiltersStateBasedOnUrlParams(filtersInitialState)
  );
  const [showMobileFiltersView, setShowMobileFiltersView] = useState(false);
  const { total: numOfFilters } = getNumOfFilters(
    buttons,
    filters
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const updateWindowWidth = (w) => setWindowWidth(window.innerWidth);

  const searchTermRef = useRef();
  const searchLocationRef = useRef();
  const objectRef = useRef();

  const { page: currentPage, setNextPage, resetPage } = useInfiniteScrollPagination(
    objectRef,
    () => {
      fetchEvents({
        event: null,
        page: currentPage,
        query: searchTerm,
        filterObject: filters,
        keepOld: true
      });
    }
  );

  // Update browser url params based on buttons and filters state
  const updateUrlParams = useCallback(
    (buttons, filters) => {
      const filtersUrlParams = Object.keys(filters).reduce((acc, x) => {
        const values = filters[x];
        if (!values.length) return acc;
        if (x === "location") {
          const locationUrlParams = locationStateToUrlParams(filters.location);
          return acc + `&location=${locationUrlParams}`;
        }
        if (x === "community") {
          const communityReducer = (cacc, c) =>
            `${cacc}${cacc ? "," : "&community="}${c}`;
          return acc + filters.community.reduce(communityReducer, "");
        }
        if (["stack", "inPersonOnline"].includes(x))
          return acc + `&${x}=${values.join(",")}`;
        return acc;
      }, "");

      const activeButtons = buttons.filter((x) => x.active);
      const urlParamsString = activeButtons.map(
        (x) => `${x.value}=${x.active}`
      );
      const buttonUrlParams = urlParamsString.join("&");

      const navigateUrl = `${window.location.pathname}?${buttonUrlParams}${filtersUrlParams}`;
      navigate(navigateUrl, { replace: true });
    },
    [navigate]
  );

  const resetSearch = () => {
    setFilters({
      inPersonOnline: [],
      community: [],
      stack: [],
      location: [],
    });
    setSearchTerm("");
    setLocations([]);
    searchTermRef.current.value = "";
    searchLocationRef.current.value = "";
    resetPage();
  };

  const resetButtons = () => {
    setButtons(buttonsInitialState);
  };

  const resetFilters = () => {
    resetPage();
    resetSearch();
    resetButtons();
    setEvents(storeEvents);
    navigate(window.location.pathname, { replace: true });
  };

  const handleFilterButtonClick = (event, value) => {
    event.preventDefault();
    resetPage();

    const newButtons = buttons.map((x) =>
      x.value === value ? { ...x, active: !x.active } : x
    );
    setButtons(newButtons);
  };

  const setLocations = (locations) => {
    setFilters((prevState) => ({ ...prevState, location: locations }));
  };

  const fetchEvents = ({ event, page, query, filterObject, keepOld }) => {
    event?.preventDefault?.();
  
    const filterArray = [];
  
    if (filterObject?.community?.length) {
      const networkIds = filterObject.community.map((c) => communitiesWithIds[c]);
      filterArray.push(`&network_ids=${networkIds.join(",")}`);
    }
  
    const buttonsUrlParams = buttonsStateToSearchParams(buttons);
    const filtersUrlParams = filtersStateToSearchParams(filterObject, query);
    let payload = `${filtersUrlParams}${buttonsUrlParams}`;
  
    if (filterArray.length) {
      payload += filterArray.join("");
    }
    if (page) {
      payload += `&page=${page}`;
    }
  
    EventsService.searchEvents(payload)
      .then((data) => {
        setEvents((prevEvents) => {
          if (data.events && data.events.length > 0) {
            if (keepOld) {
              return [...prevEvents, ...data.events];
            } else {
              return [...data.events];
            }
          } else {
            return [];
          }
        });
        setNextPage(data?.meta?.next_page);
      });
  };
  
  const setFilter = (name, filterValue) => {
    const mappings = { "in-person-online": "inPersonOnline" };
    let filterName = mappings[name] ?? format.toCamelCase(name);

    setFilters((prevState) => ({
      ...prevState,
      [filterName]: filterValue,
    }));
  };

  const fetchNetworks = () => {
    NetworksService.getNetworks().then((data) => {
      const networkTitles = data.networks.map((n) => n.network_title);
      setCommunities(networkTitles);

      const networkTitlesWithIds = Object.fromEntries(
        data.networks.map((n) => [n.network_title, n.id])
      );
      setCommunitiesWithIds(networkTitlesWithIds);
    });
  };

  const toggleShowMobileFiltersView = () => {
    setShowMobileFiltersView(!showMobileFiltersView);
  };

  useEffect(() => {
    updateWindowWidth();
    window.addEventListener("resize", updateWindowWidth);
    fetchNetworks();
  }, []);

  // Set store events if filters and search term are empty
  useEffect(() => {
    if (numOfFilters > 0 || searchTerm || !storeEvents.length) return;
    setEvents(storeEvents);
  }, [loadingEvents, numOfFilters, searchTerm, storeEvents]);

  // Update url params when buttons or filters change
  useEffect(() => {
    updateUrlParams(buttons, filters);
  }, [filters, buttons, updateUrlParams]);

  // Fetch events when buttons, filters or search term change
  useEffect(() => {
    fetchEvents({
      event: null,
      page: 1,
      query: searchTerm,
      filterObject: filters,
      keepOld: false
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttons, filters, searchTerm]);

  return (
    <Layout loading={false}>
      <div id="events-section">
        <div>
          <PageBanner
            text="Women belong in all places where decisions are being made. It shouldn't be that women are the exception. "
            source="Ruth Bader Ginsburg"
            imageUrl="/assets/header-yellow.svg"
            skeleton={loadingEvents}
          />
          <EventsSearch
            communities={communities}
            fetchEvents={fetchEvents}
            filters={filters}
            numOfFilters={numOfFilters}
            ref={[searchTermRef, searchLocationRef]}
            resetFilters={resetFilters}
            setFilter={setFilter}
            searchTerm={searchTerm}
            setLocations={setLocations}
            setSearchTerm={setSearchTerm}
            showMobileFiltersView={showMobileFiltersView}
            skeleton={loadingEvents}
            toggleShowMobileFiltersView={toggleShowMobileFiltersView}
            windowWidth={windowWidth}
          />
          {!showMobileFiltersView && (
            <FilterButtons
              buttons={buttons}
              handler={handleFilterButtonClick}
              resetSearch={resetSearch}
              skeleton={loadingEvents}
              loggedIn={loggedIn}
            />
          )}

          {events?.length > 0 && !showMobileFiltersView ? (
            <div className="events-content eventPage">
              <div className="search-results">
                {events.map((event, idx) => {
                  return (
                    <EventsCard
                      key={`event-${event?.id}-${idx}`}
                      event={event}
                    />
                  );
                })}
                <div id="visor" ref={objectRef}></div>
              </div>
            </div>
          ) : (
            !showMobileFiltersView && (
              <NoResultsFound resetFilters={resetFilters} />
            )
          )}
        </div>
        <ScrollToTopButton hide={currentPage === 1} />
      </div>
    </Layout>
  );
};

export default Events;