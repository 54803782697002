const Search = ({ onChange, placeholder, type, value, maxLength }) => {
  return (
    <div className="input-container">
      <input
        type={type ? type : 'search'}
        placeholder={placeholder || "Search..."}
        className="new-input"
        id={'search'}
        name="q"
        value={value}
        autoComplete="off"
        onChange={onChange}
        maxLength={maxLength}
      />
      {type === 'search' && <ion-icon class="icon" name="search" />}
    </div>
  );
};

export default Search;
