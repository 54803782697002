import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { getProfile, profileReceived } from '../app/slices/authSlice';
import { removeUserTokenCookie } from '../utils/cookies';
import {
  AUTH_TOKEN_KEY,
  AUTH_TOKEN_EXPIRES_AT_KEY,
} from "../app/constants/auth";

const PLANS = ["startup", "grow", "expand"];
const FROM_REDIRECT_URLS = ["events", "jobs", "resources"];

const appendSignupAction = (url) => {
  const separator = url.includes('?') ? '&' : '?';
  return `${url}${separator}action=signup`;
};

const tokenExpired = (expiresAt) => {
  if (expiresAt === null) return false;

  return Date.now() / 1000 > expiresAt;
};

export const useAuth = () => {
  const {
    logout: auth0Logout,
    user,
    isAuthenticated,
    isLoadingAuth0,
    loginWithRedirect,
  } = useAuth0();
  const dispatch = useDispatch();
  const authStore = useSelector((x) => x.auth);
  const { profile, loadingProfile, loadingToken } = authStore;
  const storageHasToken = Boolean(localStorage.getItem(AUTH_TOKEN_KEY));
  const tokenExpiresAt = localStorage.getItem(AUTH_TOKEN_EXPIRES_AT_KEY);
  const loggedIn = Boolean(storageHasToken) && !tokenExpired(tokenExpiresAt);

  const getRedirectUrl = (query = {}) => {
    const defaultRedirectUrl = `${window.location.origin}/dashboard`;
    
    if (PLANS.includes(query?.plan)) {
      if (loggedIn) return `${process.env.REACT_APP_API_ROOT_URL}/subscribe?plan=${query.plan}`;
      return `${window.location.origin}/customer-redirect?url=subscribe?plan=${query.plan}`;
    }

    if (query?.from === "companies") {
      if (loggedIn) return `${process.env.REACT_APP_API_ROOT_URL}/dashboard`;
      return `${window.location.origin}/customer-redirect?url=dashboard`;
    }

    if (FROM_REDIRECT_URLS.includes(query?.from)) {
      return `${window.location.origin}/${query.from}`;
    }

    return defaultRedirectUrl;
  };

  const login = (action, query = {}) => {
    const isRegistering = action === "register";
    let redirectUrl = getRedirectUrl(query);
    if (isRegistering) {
      redirectUrl = appendSignupAction(redirectUrl);
    }

    const obj = {
      redirectUri: redirectUrl
    };

    // Causing looping issue on Dashboard
    // Meant to redirect directly to certain authenticated pages without going through Auth0 API
    // if (loggedIn) {
    //   window.location.replace(redirectUrl);
    // } else {
    //   loginWithRedirect(obj);
    // }

    loginWithRedirect(obj);
  };

  const logout = (params = {}) => {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    localStorage.removeItem(AUTH_TOKEN_EXPIRES_AT_KEY);
    removeUserTokenCookie();
    if (params.state)
      localStorage.setItem("wwc-state", JSON.stringify(params.state));
    auth0Logout({
      returnTo: `${window.location.origin}${params.returnTo ?? ""}`,
    });
  };

  const updateProfile = () => dispatch(getProfile());
  const setProfile = (values) => dispatch(profileReceived(values));

  return {
    user,
    profile,
    updateProfile,
    setProfile,
    isLoading: isLoadingAuth0 || loadingProfile,
    isAuthenticated,
    logout,
    login,
    tokenExpired,
    loggedIn,
    loadingToken,
  };
};
