import * as filestack from 'filestack-js';
import { BaseService } from "./BaseService"

const ProfileService =  {
	getProfile: () => {
		return BaseService.get(`profile/me`)
	},
	getProfileData: async() => {
		try {
			const profileData = await BaseService.get(`profile/profile_page`)
			return profileData
		} catch(e) {
			return {}
		}
	},
	updateProfile: async(values, updateEmail = false) => {
		const payload = { ...values }
		if(!updateEmail)
			delete payload.email
		return BaseService.put(`profiles/self`, payload)
	},
	createProfile: async() => {
		return BaseService.post('profiles', {}, true)
	},
	errorMessages: (statusCode) => {
		if (statusCode === 401) {
			return "We're sorry, your session has timed out. Any changes have not been saved. Please log in to retry.";
		} else if (statusCode === 422) {
			return 'Your input was invalid and your changes could not be saved. Please try again.';
		} else {
			return 'An error occured. Please refresh your browser and try again.';
		}
	},
	updatePassword: async () => {
		try {
			const response = await BaseService.get(`profile/update_password`)
			return  response
		} catch(e) {
			return { redirect_url: null }
		}
	},
	uploadFile: async (filestackBucket, filestackKey, fileType, callback) => {
		const client = filestack.init(filestackKey)
		const options = {
			accept: fileType,
			storeTo: {
				location: "s3",
				container: filestackBucket,
				region: "us-west-2",
			},
			onFileUploadFinished: callback
		}
		client.picker(options).open()
	}
}

export default ProfileService
