import './Checkbox.scss';

const Checkbox = ({ checked, label, onChange, ...rest }) => {
  return (
    <label
      className="checkbox-label-container"
      onClick={(e) => {
        onChange(!checked, label);
        e.preventDefault();
      }}
    >
      <div className="checkbox-container">
        <input
          type="checkbox"
          name={label}
          className="hidden-checkbox"
          checked={checked}
          onChange={(e) => {}}
          {...rest}
        />
        <div
          checked={true}
          className="styled-checkbox"
          style={{ backgroundColor: checked ? "#007D7F" : "transparent" }}
        >
          <img src="/images/check.png" className="check-img" alt="Check" />
        </div>
      </div>
      <p className="checkbox-label">{label}</p>
    </label>
  );
};

export default Checkbox;
