import { useEffect, useState } from "react";
import "./filter.scss";
import useDropdown from "../../hooks/useDropdown";
const Filter = ({
  filterType,
  filterOptions,
  selectedFilterOptions,
  showMobileFilterView,
  showFilter,
  setShownFilter,
  setFilter,
}) => {
  const toDashCase = (string) => {
    let output = " ";
    string.split(" ").forEach(function (word, index) {
      word = word.toLowerCase();
      output += index === 0 ? word : "-" + word;
    });
    return output;
  };
  const [filterTypeDashCase] = useState(toDashCase(filterType).trim());
  const [filteredSearch, setFilteredSearch] = useState(filterOptions);
  const addRemoveFilterOption = (filterOption) => {
    let selectedOptions = selectedFilterOptions;
    const index = selectedOptions.indexOf(filterOption);
    if (index > -1) {
      selectedOptions.splice(index, 1);
    } else {
      selectedOptions.push(filterOption);
    }
    if (filterType === "in person / online") {
      filterType = "in-person-online";
    } else if (filterType === "onsite / remote") {
      filterType = "on-site-remote";
    } else if (filterType === "job type") {
      filterType = "job-type";
    } else if (filterType === "topic / stack") {
      filterType = "stack";
    }
    setFilter(filterType, selectedOptions);
  };

  useEffect(() => {
    setFilteredSearch(filteredSearch);
  }, [filteredSearch]);

  useEffect(() => {
    setFilteredSearch(filterOptions);
  }, [filterOptions, showFilter]);

  const changeOptionsBySearch = (searchTerm) => {
    let shownFilterOptions = [];
    for (let i = 0; i < filterOptions.length; i++) {
      if (
        filterOptions[i].toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      ) {
        shownFilterOptions.push(filterOptions[i]);
      }
    }
    setFilteredSearch(shownFilterOptions);
  };

  const { ref } = useDropdown({
    onClickOutside: () =>
      setShownFilter((c) => (c === filterTypeDashCase ? "" : c)),
  });

  return (
    <div className="filter-container" ref={ref}>
      {!showMobileFilterView && (
        <div
          className={
            "filter " +
            filterTypeDashCase +
            " " +
            (showFilter === filterTypeDashCase ? "active" : "") +
            (selectedFilterOptions.length > 0 ? " selected" : "")
          }
          onClick={() => setShownFilter(showFilter ? "" : filterTypeDashCase)}
        >
          <span className="label">
            {(selectedFilterOptions.length === 0 ||
              selectedFilterOptions.length > 1) &&
              filterType}
            {selectedFilterOptions.length === 1 && selectedFilterOptions[0]}
          </span>
          <span className="right-side">
            {selectedFilterOptions.length > 1 && (
              <div className="num-of-selected-filter-options">
                {selectedFilterOptions.length}
              </div>
            )}
            <ion-icon name={showFilter ? "chevron-up" : "chevron-down"} />
          </span>
        </div>
      )}
      {showMobileFilterView && <div className="mobile-label">{filterType}</div>}
      {(showFilter === filterTypeDashCase || showMobileFilterView) && (
        <div className={"filter-options " + filterTypeDashCase + "-filter"}>
          {(filterType === "stack" ||
            filterType === "topic / stack" ||
            filterType === "community") && (
            <div className="input-container">
              <input
                className="options-search-term"
                type="text"
                placeholder="Search"
                onChange={(event) => changeOptionsBySearch(event.target.value)}
              />
              <ion-icon class="icon" name="search" />
            </div>
          )}
          {filteredSearch.map((option, index) => {
            return (
              <div
                className={
                  "filter-option " +
                  (selectedFilterOptions.indexOf(option) > -1 ? "selected" : "")
                }
                key={index}
                onClick={() => addRemoveFilterOption(option)}
              >
                {option}
                {selectedFilterOptions.indexOf(option) === -1 && (
                  <ion-icon class="add-icon" name="add" />
                )}
                {selectedFilterOptions.indexOf(option) > -1 && (
                  <ion-icon class="remove-icon" name="checkmark" />
                )}
              </div>
            );
          })}
          <div
            className="close-icon-container"
            onClick={() => setShownFilter("")}
          >
            <ion-icon class="close-icon" name="close" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Filter;
