const FileUploadButton = ({ width, openFile, fileRef, onChange }) => {
  return (
    <button
      className="outline-button"
      style={{ width: width ? width : 'fit-content' }}
      onClick={openFile}
    >
      <div className="upload-container">
        <img src="/images/upload.png" className="upload-img" alt="File upload"/>
        <p className="upload-button-text">click to upload</p>
      </div>
      <input type="file" hidden ref={fileRef} onChange={onChange}/>
    </button>
  )
}

export default FileUploadButton
