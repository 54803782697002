const FilterItem = ({ text, isEdit, loading }) => {
  return (
    <div
      className={`filter-option`}
      onClick={() => {}}
      style={{
        background: isEdit ? "#007D7F" : "",
        color: isEdit ? "#FFFFFF" : "",
      }}
    >
      {text}
      {isEdit && <ion-icon class="remove-icon" name="close" />}
    </div>
  );
};

export default FilterItem;
