import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Admin from "./pages/Admin";
import ApplaudHer from "./pages/ApplaudHer";
import ApplaudHerForm from "./pages/ApplaudHerForm";
import AuthPage from "./pages/AuthPage";
import CustomerRedirect from "./pages/CustomerRedirect";
import Dashboard from "./pages/Dashboard";
import Donate from "./pages/Donate";
import Events from "./pages/Events";
import Favorites from "./pages/Favorites";
import Home from "./pages/LandingPage";
import Jobs from "./pages/Jobs";
import LogoutPage from "./pages/LogoutPage";
import ProfileSection from "./pages/Profile";
import Resources from "./pages/Resources";
import Settings from "./pages/Settings";
import ScrollToTop from "./components/ScrollToTop";
import { useAppStorage } from "./auth/useAppStorage";

const RouterNavigator = () => {
  const { loggedOut } = useAppStorage();

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<AuthPage action="login" />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/signup" element={<AuthPage action="register" />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="applaudher" element={<ApplaudHer />} />
        <Route path="applaudher/new" element={<ApplaudHerForm />} />
        <Route path="customer-redirect" element={<CustomerRedirect />} />
        <Route path="donate" element={<Donate />} />
        <Route path="events" element={<Events />} />
        <Route path="jobs" element={<Jobs />} />
        <Route path="resources" element={<Resources />} />
        <Route
          path="dashboard"
          element={
            <RequireAuth loggedOut={loggedOut}>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path="favorites"
          element={
            <RequireAuth loggedOut={loggedOut}>
              <Favorites />
            </RequireAuth>
          }
        />
        <Route
          path="settings"
          element={
            <RequireAuth loggedOut={loggedOut}>
              <Settings />
            </RequireAuth>
          }
        />
        <Route
          path="profile"
          element={
            <RequireAuth loggedOut={loggedOut}>
              <ProfileSection />
            </RequireAuth>
          }
        />
      </Routes>
    </Router>
  );
};

const RequireAuth = ({ children, loggedOut }) => {
  if (loggedOut === true) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default RouterNavigator;
