import "./Skeleton.scss";

const Skeleton = ({ className = "", rounded, w, h, mb, ml, mt, mr, radius, style }) => {
  return (
    <div
      className={`Skeleton ${className} ${rounded && 'Skeleton--rounded'}`}
      style={{
        [h && 'height']: h,
        [w && 'width']: w,
        [mb && 'marginBottom']: mb,
        [ml && 'marginLeft']: ml,
        [mt && 'marginTop']: mt,
        [mr && 'marginRight']: mr,
        [radius && 'borderRadius']: radius,
        ...style
      }}
    >
      <div className="Skeleton--loading"></div>
    </div>
  );
};

export default Skeleton;
