import { urlParamsToLocationsArray  } from "./locations";
import queryString from 'query-string'

const getButtonsStateBasedOnUrlParams = (initialState) => {
	const params = queryString.parse(window.location.search);
	const newButtons = initialState.map((x) => ({
		...x,
		active: Boolean(params[x.value]),
	}));
	return newButtons;
};
const getFiltersStateBasedOnUrlParams = (initialState) => {
	const params = queryString.parse(window.location.search);

	const locationsObjectsArray = urlParamsToLocationsArray(params.location)
	return ({
		...initialState,		
		inPersonOnline: params.inPersonOnline
			? params.inPersonOnline.split(',')
			: [],
		community: params.community
			? params.community.split(',')
			: [],
		stack: params.stack
			? params.stack.split(',')
			: [],
		location: locationsObjectsArray,
	});
}
const getNumOfFilters = (buttons, filters) => {
	const buttonsCount = buttons.filter(x => x.active).length
  const filtersCount = Object.values(filters).filter(x => x.length).length
	return {
		buttons: buttonsCount,
		filters: filtersCount,
		total: buttonsCount + filtersCount
	}
}
const buttonsStateToSearchParams = buttons =>
	buttons.reduce((acc, x) => {
		if(!x.active) return acc;
		if (x.value === 'new') return acc + '&new_events_only=true';
		if (x.value === 'featured') return acc + '&featured_only=true';
		if (x.value === 'recently-viewed') return acc + '&recently_viewed=true';
		return acc;
	}, '')

const filtersStateToSearchParams = (filters, query) => {
	let filtersArr = [];

	if (query) {
		filtersArr.push(`&query=${query}`);
	}
	if (filters.location.length) {
		let event_cities = filters.location.map((i) => i.address_attributes.city);
		let coordinates = filters.location.map((i) => [
			i.address_attributes.latitude,
			i.address_attributes.longitude,
		]);

		filtersArr.push(`&event_city=${event_cities.join(',')}`);
		filtersArr.push(`&coordinates=${coordinates.join(',')}`);
	}

	if (filters.stack.length) {
		filtersArr.push(`&stack_list=${filters.stack.join(',')}`);
	}
	if (filters.inPersonOnline.length) {
		let remoteFilter = filters.inPersonOnline.map((i) =>
			i === 'Online' ? true : false
		);
		filtersArr.push(`&remote=${remoteFilter.join(',')}`);
	}
	return filtersArr.join('')
}

export {
	getButtonsStateBasedOnUrlParams,
	getFiltersStateBasedOnUrlParams,
	getNumOfFilters,
	buttonsStateToSearchParams,
	filtersStateToSearchParams
}
