import FavoritesButton from "./FavoritesButton";

const NoFavorites = (props) => {
  const { loading } = props;
  return (
    <div className="no-favorites-wrapper">
      <div className="content-wrapper">
        {loading ? (
          <p className="message">Loading...</p>
        ) : (
          <>
            <p className="message">
              There are no favorites saved. Check out the dashboard!
            </p>
            <FavoritesButton text="go to dashboard" link="/dashboard" />
          </>
        )}
      </div>
    </div>
  );
};

export default NoFavorites