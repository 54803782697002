import React, { useState, useEffect } from "react";
import Filter from "./Filter";
import "../stylesheets/resources_search.scss";
import TagsService from "../../services/TagsService";
import SuggestionsService from "../../services/SuggestionsService";
import $ from "jquery";
import "jquery-ui/themes/base/core.css";
import "jquery-ui/themes/base/theme.css";
import "jquery-ui/themes/base/autocomplete.css";
import "jquery-ui/ui/core";
import "jquery-ui/ui/widgets/autocomplete";
import "./autocomplete.scss";
import Skeleton from "../../components/Skeleton";
import debounce from "just-debounce-it";

const ResourcesSearch = React.forwardRef((props, ref) => {
  const {
    fetchResources,
    filters,
    numOfFiltersSet,
    resetFilters,
    resetPage,
    setSearchTerm,
    search,
    setFilter,
    showMobileFiltersView,
    skeleton,
    toggleShowMobileFiltersView,
    windowWidth,
  } = props;
  const [showFilter, setShowFilter] = useState("");
  const [stacks, setStacks] = useState([]);
  const [formats, setFormats] = useState([]);
  const [levels, setLevels] = useState([]);

  const isMobile = windowWidth <= 720;

  const fetchSearchSuggestions = () => {
    SuggestionsService.getSuggestions("Resource").then((data) => {
      $("#resource-search-term").autocomplete({
        source: function (request, response) {
          var results = $.ui.autocomplete.filter(data, request.term);
          response(results.slice(0, 10));
        },
        minLength: 1,
      });
    });
  };
  const fetchStacks = () => {
    TagsService.getStacks().then((data) => {
      if (data && data.list) {
        const stacks = data?.list?.map((x) => x.name);
        setStacks(stacks);
      }
    });
  };
  const fetchFormats = () => {
    TagsService.getFormats().then((data) => {
      if (data && data.list) {
        const formats = data.list.map((x) => x.name);
        setFormats(formats);
      }
    });
  };
  const fetchLevels = () => {
    TagsService.getLevels().then((data) => {
      if (data && data.list) {
        const levels = data.list.map((x) => x.name);
        setLevels(levels);
      }
    });
  };

  useEffect(() => {
    fetchSearchSuggestions();
    fetchStacks();
    fetchFormats();
    fetchLevels();
  }, []);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const debouncedHandleChange = debounce(handleChange, 500);

  return (
    <form className="resources-search">
      {!showMobileFiltersView && (
        <div className="top-row">
          <div className="input-container">
            {skeleton ? (
              <Skeleton className="search-term" />
            ) : (
              <>
                <input
                  className="search-term"
                  id="resource-search-term"
                  ref={ref}
                  type="text"
                  placeholder="What"
                  autoComplete="off"
                  onChange={debouncedHandleChange}
                />
                <ion-icon class="icon" name="search" />
              </>
            )}
          </div>
          {!isMobile && (
            <div
              className={
                "filters-icon-container " +
                (numOfFiltersSet > 0 ? "filters-set" : "")
              }
              onClick={toggleShowMobileFiltersView}
            >
              <ion-icon class="filters-icon" name="options" />
              <span className="num-of-filters-set">{numOfFiltersSet}</span>
            </div>
          )}
          {skeleton ? (
            <Skeleton w={87} h={48} radius={8} />
          ) : (
            <div style={{ display: 'flex', width: isMobile ? '100%' : ''}}>
              <button
                type="submit"
                id="search-submit"
                onClick={(event) => {
                  event.preventDefault();
                  resetPage();
                  fetchResources({
                    event,
                    page: 1,
                    query: search.term,
                    filterObject: filters,
                    keepOld: false
                  });
                  setShowFilter("");
                }}
              >
                SEARCH
              </button>
              {isMobile && (
                <div
                  className={
                    "filters-icon-container " +
                    (numOfFiltersSet > 0 ? "filters-set" : "")
                  }
                  onClick={toggleShowMobileFiltersView}
                >
                  <ion-icon class="filters-icon" name="options" />
                  <span className="num-of-filters-set">{numOfFiltersSet}</span>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {(!isMobile || showMobileFiltersView) && (
        <div className="bottom-row">
          {showMobileFiltersView && (
            <div>
              <div
                className="close-icon-container"
                onClick={toggleShowMobileFiltersView}
              >
                <ion-icon class="close-icon" name="close" />
              </div>
              <div className="filters-label">Filters</div>
            </div>
          )}
          {skeleton ? (
            <>
              <Skeleton
                className="filter-container"
                w="33%"
                h={36}
                radius={40}
              />
              <Skeleton
                className="filter-container"
                w="33%"
                h={36}
                radius={40}
              />
              <Skeleton
                className="filter-container"
                w="33%"
                h={36}
                radius={40}
              />
            </>
          ) : (
            <>
              <Filter
                showFilter={showFilter}
                filterType="level"
                filterOptions={levels}
                selectedFilterOptions={filters.level}
                setShownFilter={setShowFilter}
                setFilter={setFilter}
                showMobileFilterView={showMobileFiltersView}
              />
              <Filter
                showFilter={showFilter}
                filterType="format"
                filterOptions={formats}
                selectedFilterOptions={filters.format}
                setShownFilter={setShowFilter}
                setFilter={setFilter}
                showMobileFilterView={showMobileFiltersView}
              />
              <Filter
                showFilter={showFilter}
                filterType="topic / stack"
                filterOptions={stacks}
                selectedFilterOptions={filters.stack}
                setShownFilter={setShowFilter}
                setFilter={setFilter}
                showMobileFilterView={showMobileFiltersView}
              />
            </>
          )}
          {skeleton ? (
            <Skeleton w={87} h={36} radius={8} />
          ) : (
            <>
              {showMobileFiltersView ? (
                <div className="mobile-filter-buttons">
                  <button
                    className="apply-button"
                    type="button"
                    onClick={(event) => {
                      resetPage();
                      fetchResources({
                        event,
                        page: 1,
                        query: search.term,
                        filterObject: filters,
                        keepOld: false
                      });
                      toggleShowMobileFiltersView();
                    }}
                  >
                    APPLY
                  </button>
                  <button
                    className="reset-button"
                    type="button"
                    onClick={resetFilters}
                  >
                    RESET
                  </button>
                </div>
              ) : (
                <button
                  className="reset-button"
                  type="button"
                  onClick={resetFilters}
                >
                  RESET
                </button>
              )}
            </>
          )}
        </div>
      )}
    </form>
  );
});

export default ResourcesSearch;
