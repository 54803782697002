import { createSlice } from "@reduxjs/toolkit"

const alertsSlice = createSlice({
	name: 'alerts',
	initialState: {
		activeAlert: null,
		queue: [],
	},
	reducers: {
		pushAlert(state, action) {
			if(!state.queue.length && !state.activeAlert)
				return { ...state, activeAlert: action.payload }

			return {
				...state,
				queue: [...state.queue, action.payload]
			}
		},
		removeActiveAlert(state) {
			const nextAlert = state.queue[0] ?? null
			const queue = state.queue.slice(1)
			
			return { ...state, activeAlert: nextAlert, queue }
		}
	},
})

const dispatchActionAndPushAlert = (message, action) => async (dispatch) => {
	dispatch(action)
	dispatch(pushAlert({ message }))

	setTimeout(() => {
		dispatch(removeActiveAlert())
	}, 3000);
}

export const { reducer: alertsReducer } = alertsSlice
export const { pushAlert, removeActiveAlert } = alertsSlice.actions
export { dispatchActionAndPushAlert }
