import React from "react";
import PropTypes from "prop-types";
import './copy_link.scss'

class CopyLink extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      linkCopied: false
    }
    this.copyLink = this.copyLink.bind(this);
  }
  copyLink(){
    navigator.clipboard.writeText(this.props.url).then(() => {
      this.setState({
        linkCopied: true
      });
      setTimeout(() => {
        this.setState({
          linkCopied: false
        })
      }, 1500);
    });
  }
  render(){
    return (
      <div className="copy-link" onClick={this.copyLink}>
        <ion-icon name="share-social" class={this.props.classStyles + ' share-social'} />
        {
          this.state.linkCopied &&
          <div className="link-copied">
            Link has been copied to clipboard!
          </div>
        }
      </div>
    );
  }
}

CopyLink.propTypes = {
  url: PropTypes.string.isRequired
};

export default CopyLink
