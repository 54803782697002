import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import EventsCard from "./EventsCard";
import Resource from "./Resource";
import JobCard from "../../components/cards/JobCard";
import "./carousel_container.scss";

const CarouselContainer = ({
  carouselType,
  label,
  linkedToUrl,
  setYoutubeUrl,
}) => {
  const [cards, setCards] = useState([null, null, null, null, null, null]);
  const [windowWidth] = useState(window.innerWidth);
  const [showTitle, setShowTitle] = useState(false);
  const [atBeginningOfScroll, setAtBeginningOfScroll] = useState(true);
  const [atEndOfScroll, setAtEndOfScroll] = useState(false);
  const { loading: loadingEvents, events } = useSelector(
    (state) => state.events
  );
  const { loading: loadingjobs, jobs } = useSelector((state) => state.jobs);
  const { loading: loadingResources, resources } = useSelector(
    (state) => state.resources
  );

  const loadingData = loadingEvents || loadingjobs || loadingResources;

  useEffect(() => {
    if (loadingData) return;
    if (carouselType === "events") setCards(events);
    if (carouselType === "jobs") setCards(jobs);
    if (carouselType === "resources") setCards(resources);
  }, [carouselType, events, jobs, loadingData, resources]);

  const containerRef = React.createRef(null);

  const offset = windowWidth > 420 ? 332 : windowWidth;
  const panOffset = 70;
  const panInterval = 10;

  const stopPan = () => {
    clearInterval(scrollToken);
  };

  let scrollToken = null;

  const panContainer = (direction) => {
    scrollToken = setInterval(() => {
      if (containerRef.current) {
        let scrollLeft = containerRef.current?.scrollLeft ?? 0;

        if (direction === "left") {
          containerRef.current.scrollLeft = scrollLeft - panOffset;
        } else if (direction === "right") {
          containerRef.current.scrollLeft = scrollLeft + panOffset;
        }
      }
    }, panInterval);
  };

  const scrollLeft = () => {
    stopPan();
    containerRef.current.scrollLeft = containerRef.current.scrollLeft - offset;
  };

  const scrollRight = () => {
    stopPan();
    containerRef.current.scrollLeft = containerRef.current.scrollLeft + offset;
  };

  const containerScrollCallback = (ref) => {
    setAtBeginningOfScroll(ref.current.scrollLeft <= 0);
    setAtEndOfScroll(
      ref.current.scrollWidth - ref.current.scrollLeft - 1 <
        ref.current.clientWidth
    );
  };

  const toggleShowTitle = () => {
    setShowTitle(!showTitle);
  };

  return (
    <div className="carousel-container">
      <Link to={linkedToUrl}>
        <h3 onMouseEnter={toggleShowTitle} onMouseLeave={toggleShowTitle}>
          {label}
          {showTitle && (
            <ion-icon class="icon" name="chevron-forward-outline"></ion-icon>
          )}
        </h3>
      </Link>
      {!atBeginningOfScroll && cards.length > 0 && (
        <div className="left-scroll-section">
          <div
            className="left-scroll-button"
            onClick={scrollLeft}
            onMouseOver={() => panContainer("left")}
            onMouseOut={stopPan}
          >
            <ion-icon name="chevron-back-outline" />
          </div>
        </div>
      )}
      <div
        className={carouselType === "jobs" ? "job-cards" : "cards"}
        ref={containerRef}
        onScroll={() => containerScrollCallback(containerRef)}
      >
        {cards.map((card, index) => {
          if (carouselType === "events") {
            return (
              <a
                href={card?.href}
                key={index}
                target="_blank"
                rel="noreferrer"
                style={{ display: 'inline-block' }}
              >
                <EventsCard event={card} cardType="Dashboard" />
              </a>
            );
          } else if (carouselType === "resources") {
            return (
              <Resource
                resource={card}
                key={index}
                setYoutubeUrl={setYoutubeUrl}
              />
            );
          } else {
            return <JobCard job={card} key={index} />;
          }
        })}
      </div>
      {!atEndOfScroll && cards.length > 0 && (
        <div className="right-scroll-section">
          <div
            className="right-scroll-button"
            onClick={scrollRight}
            onMouseOver={() => panContainer("right")}
            onMouseOut={stopPan}
          >
            <ion-icon name="chevron-forward-outline" />
          </div>
        </div>
      )}
    </div>
  );
};

export default CarouselContainer;
