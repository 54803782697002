import React from 'react'
import { ReactComponent as CloudUpload } from "../../../components/icons/cloud-upload.svg";

function FormWithButtons({
  header,
  buttons,
  step,
  info,
  property,
  values,
  setValues,
  fileBucket,
  fileKey,
  uploadFile,
  goToPage,
  noHeader,
  width
}){

  const renderButtons = (buttons) => {

    const truncateText = (text) => {
      return text.length > 20 ? `${text.substring(0, 20)}...` : text;
    }
    const setFile = (data) =>
      setValues({
        ...values,
        resume_filename: data.filename,
        resume_url: data.url,
      })

    return buttons.map((button, index) => (
      <div key={button}>
        <button
          className="outline-button"
          style={{ width: width ? width : '100%' }}
          onClick={() => {
            if(fileBucket){
              uploadFile(fileBucket, fileKey, 'application/pdf', setFile)
              return;
            }
            const newValues = {...values, [property]: button}
            setValues(newValues)
            goToPage(step + 1, false, newValues)
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {
              fileBucket
                ? <CloudUpload style={{ marginRight: 16 }} />
                : null
            }
            {
              values[property] && fileBucket ?
                <p title={values[property]}
                  style={{
                    margin: 0,
                    fontSize: 14,
                    lineHeight: 1.2
                  }}
                >
                  {truncateText(values[property])}
                </p> :
              button
            }
            {
              !fileBucket && values[property] === button &&
              <ion-icon name="checkmark-outline"></ion-icon>
            }
          </div>
        </button>
        {
          index !== buttons.length -1 ?
            <div style={{ marginBottom: 25 }}/> :
          null
        }
      </div>
    ))
  }
  return(
    noHeader ?
    renderButtons(buttons) :
    <React.Fragment>
      <h4 className="form-header">{header}</h4>
      { renderButtons(buttons) }
      <div style={{ marginBottom: 32 }}/>
    </React.Fragment>
  )
}

export default FormWithButtons
