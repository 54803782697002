import { BaseService } from "./BaseService";

// Determines the time zone of the browser client. Event dates are displayed in browser's timezone.
const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const upcoming_param = `upcoming=true&timezone=${browserTimeZone}`;

const EventsService =  {
	getEvents: async() => {
		try {
			const data = await BaseService.get(`events?${upcoming_param}`);
			return { events: data.events, ...data };
		} catch(e) {
			console.error(e);
			return { events: [] };
		}
	},
	searchEvents: async(payload) => {
		try {
			const data = await BaseService.get(`events/search?${upcoming_param}${payload}`);
			return { events: data.events, ...data };
		} catch(e) {
			console.error(e);
			return { events: [] };
		}
	},
	updateEvent: async(event) => {
		try {
			return await BaseService.put(`events/${event.id}/viewed`);
		} catch(e) {
			console.error(e);
			return { success: false };
		}
	}
}

export default EventsService;
