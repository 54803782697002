import "./Button.scss";

const Button = (props) => {
  return (
    <button
      className={`Button ${props.className || ""} ${
        props.outline ? "Button--outline" : ""
      }`}
      {...props}
    >
      {props.children}
    </button>
  );
};

export default Button;
