import React from 'react';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';
import LoadingOverlay from '../components/LoadingOverlay';

const CustomerRedirect = () => {
  const location = useLocation();
  const { logout } = useAuth();
  const loadingProfile = useSelector(x => x.auth.loadingProfile);
  const isProfileReady = useSelector(x => x.auth.profile);
  const query = queryString.parse(location.search);

  const constructReturnToUrl = () => {
    const nestedQuery = query['url'] ? queryString.parseUrl(query['url']).query : {};
    if (nestedQuery.plan) {
      return `/login?plan=${nestedQuery.plan}`;
    }
    return '/login?from=companies';
  };

  // "unauthorized" error can be removed once fully migrated to Auth0 Actions
  if (query.error === "unauthorized" || query.error === "access_denied") {
    return logout({
      returnTo: constructReturnToUrl(),
      state: {
        type: "error",
        message: "Please check your email to confirm your account.",
      },
    });
  }

  if (loadingProfile) return <LoadingOverlay />;

  const redirectUrl = query['url'] || 'dashboard';

  if (isProfileReady) {
    window.location.replace(`${process.env.REACT_APP_API_ROOT_URL}/${redirectUrl}`);
    return null;
  }

  return <LoadingOverlay />;
};

export default React.memo(CustomerRedirect);




