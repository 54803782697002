import Radio from "./Radio";

const QuestionWithRadio = ({
  question,
  answers,
  radioOnChange,
  value,
  name,
  noTopMargin,
  noWidth,
}) => {
  return (
    <div style={{ marginTop: noTopMargin ? null : 48 }}>
      <h5 className="text-header">{question}</h5>
      <div style={{ marginBottom: 32 }} />
      {answers?.map((answer) => {
        return (
          <Radio
            label={answer}
            name={name}
            value={answer}
            key={answer}
            checked={value === answer}
            onChange={radioOnChange}
          />
        );
      })}
    </div>
  );
};

export default QuestionWithRadio;
