import { FormWithDropdown } from "../../../legacy/components/NewOnboarding";

const Step4 = ({ yearDropDown, values, setValues }) => (
  <FormWithDropdown
    options={yearDropDown()}
    setOption={setValues}
    values={values}
    property="starter_year_in_tech"
    label="Select Year"
  />
);

export default Step4;
