import { FormWithButtons } from "../../../legacy/components/NewOnboarding";
import ProfileService from "../../../services/ProfileService";

const Step = ({ values, setValues, fileBucket, fileKey }) => (
  <FormWithButtons
    buttons={["click to upload"]}
    values={values}
    setValues={setValues}
    property="resume_filename"
    fileBucket={fileBucket}
    fileKey={fileKey}
    uploadFile={ProfileService.uploadFile}
  />
);

export default Step;
