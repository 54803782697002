import { useEffect } from "react"
import { useAuth } from "../auth/useAuth"

const LogoutPage = () => {
	const { logout } = useAuth()
  useEffect(logout, [logout])
  return(
    <div className="new-experience-container"></div>
  )
}

export default LogoutPage