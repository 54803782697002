import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import BookmarkingsService from "../../services/BookmarkingsService";
import { useAuth } from '../../auth/useAuth';
import { addFavorite, removeFavorite } from "../../app/slices/favoritesSlice";
import { dispatchActionAndPushAlert } from "../../app/slices/alertsSlice";
import './favorite.scss';

const Favorite = (props) => {
  const { button, favorite, item, type } = props;
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const { login } = useAuth();
  const [isFavorite, setIsFavorite] = useState(!!favorite);

  useEffect(() => {
    setIsFavorite(!!favorite);
  }, [favorite]);

  const toggleFavorite = () => {
    setIsFavorite((prevIsFavorite) => {
      const action = prevIsFavorite ? 'delete' : 'post';
      updateBaseOnType(action);
      return !prevIsFavorite;
    });
  };

  const updateBaseOnType = (method) => {
    const typeText = `${type.charAt(0).toUpperCase()}${type.slice(1, type.length - 1)}`;
    if(method === 'post') {
      BookmarkingsService.addBookmarking(type, item.id)
        .then(() => {
          const message = `${typeText} has been added to your saved items`;
          dispatch(dispatchActionAndPushAlert(message, addFavorite({ item, type })));
        })
    } else {
      BookmarkingsService.deleteBookmarking(type, item.id)
        .then(() => {
          const message = `${typeText} has been removed from your saved items`;
          dispatch(dispatchActionAndPushAlert(message, removeFavorite({ id: item.id, type})));
        })
    }
  };

  if(!item.id) return null;
  if (!isAuthenticated) {
    return (
      <div 
        className="favorite"
        onClick={() => {
          login('login', { from: type })
        }}
      >
        {
          button ? (
            <button type="button">
              <ion-icon class="favorite-icon" name="star-outline" />
              SAVE
            </button>
          ) : (
            <ion-icon name="star-outline" />
          )
        }
      </div>
    )
  };

  return (
    <div className="favorite" onClick={event => {
      event.preventDefault(); 
      event.stopPropagation(); 
      toggleFavorite();
    }}>
      { !button &&
        <div>
          {
            !isFavorite && (
              <ion-icon name="star-outline" />
            )
          }
          {
            isFavorite && (
              <ion-icon name="star" />
            )
          }
        </div>
      }
      { button &&
        <button className={isFavorite ? 'already-saved' : ''} type="button">
          <ion-icon class="favorite-icon" name={isFavorite ? 'star' : 'star-outline'} />
          {isFavorite ? 'SAVED' : 'SAVE'}
        </button>
      }
    </div>
  );
}

Favorite.defaultProps = {
  button: false,
  item: {}
};

Favorite.propTypes = {
  type: PropTypes.string.isRequired,
  item: PropTypes.object,
  button: PropTypes.bool,
  favorite: PropTypes.bool.isRequired
};

export default Favorite;
