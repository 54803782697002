import React from 'react';
import { useSelector } from 'react-redux';
import Favorite from './Favorite';
import CopyLink from './CopyLink';
import Skeleton from '../../components/Skeleton';
import './resources_wide.scss';

const ResourceWide = ({ resource, setYoutubeUrl }) => {
  const {  
    title, 
    url, 
    image_url, 
    format_list, 
    topic_list, 
    level_list 
  } = resource ?? {};

  const favorites = useSelector(state => state.favorites.resources) || [];
  const isFavorite = favorites.some(favoriteResource => favoriteResource?.id === resource?.id);

  return (
    <div className="card">
      {resource && format_list?.[0] === 'Video' && (
        <div className="youtube-iframe-container">
          <iframe
            src={url}
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title="Youtube Iframe"
            allowFullScreen
          />
          <div
            className="youtube-iframe-overlay"
            onClick={() => setYoutubeUrl(url)}
          />
        </div>
      )}
      {resource && format_list?.[0] !== 'Video' && (
        <a href={url} target="_blank" className="resource-img-link" rel="noreferrer">
          <img
            alt="Article"
            src={image_url || '/images/defaults/resource-thumbnail.jpg'}
            className="resource-img"
          />
        </a>
      )}
      {!resource && (
        <Skeleton className="resource-img resource-img-skeleton" h={132} w="100%" />
      )}
      <div className="resource-content">
        {resource ? (
          <div
            onClick={() =>
              format_list[0] === 'Video'
                ? setYoutubeUrl(url)
                : window.open(url, '_blank')
            }
            className="content-wrapper"
          >
            <h6 className="resource-title">{title}</h6>
            <p className="resource-tag">{topic_list.join(' | ')}</p>
            <p className="resource-level">{level_list.join(', ')}</p>
            <p className="resource-format">{format_list.join(', ')}</p>
          </div>
        ) :  (
          <div className="content-wrapper">
            <Skeleton w="min(225px, 100%)" h={16} mb={5} rounded />
            <Skeleton w="min(225px, 100%)" h={16} mb={7} rounded />
            <Skeleton w={69} h={16} mb={5} rounded />
            <Skeleton w={69} h={16} rounded />
          </div>
        )}
        <div className="resource-right">
          {resource ? (
            <div className="resource-icons">
              <span className="resource-icon">
                <Favorite
                  type="resources"
                  item={{ ...resource }}
                  favorite={isFavorite}
                />
              </span>
              <span className="resource-icon">
                <CopyLink url={url} classStyles="favorite" />
              </span>
            </div>
          ) : 
            <div className="resource-icons-skeleton">
              <Skeleton w={40} h={40} radius={8} />
              <Skeleton w={40} h={40} radius={8} />
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default ResourceWide;
