import donateImage from "../images/dashboard/donate.png";
import '../stylesheets/donate.scss';

const DonateBanner = () => {
  return (
    <div className="donate-banner">
      <div className="donate-banner-container">
        <a href="/donate" className="donate-banner-link">
          <div
            className="donate-banner-image"
            style={{
              backgroundImage: `url(${donateImage})`,
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className="donate-banner-text">
              <h1 className="donate-banner-header">Become a <br/>supporting <br/>member</h1>
              <p className="donate-banner-description">Every dollar donated directly <br/>supports programs that are <br/>changing the face of tech.</p>
              <button className="donate-banner-button"><strong>Create Change</strong></button>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default DonateBanner;
