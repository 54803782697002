import React from 'react'
import { LocationSearch } from '../LocationSearch'
import Search from '../Search'

function FormWithSearch({
  setSearch,
  filterComponent,
  edit,
  locationSearch,
  placeholder,
  setLocation,
  searchId,
  type,
  maxLength,
  value
}){
  return(
    <React.Fragment>
      {
        locationSearch ?
        <LocationSearch
          id={searchId}
          search={locationSearch}
          setLocations={setLocation}
        /> :
        <Search
          type={type}
          onChange={setSearch}
          placeholder={placeholder}
          maxLength={maxLength}
          value={value}
        />
      }
      {
        !locationSearch &&
        <React.Fragment>
          <div className="filter-option-container"
            style={{ height: edit ? 'fit-content' : null }}
          >
            { filterComponent }
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  )
}

export default FormWithSearch
