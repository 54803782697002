import { useState, useEffect } from 'react'

const useForm = (initialState, onSubmit, validationSchema) => {

	const [inputs, setInputs] = useState(initialState)
	const [errors, setErrors] = useState({})

	const subscribe = fieldName => value => {
		setInputs({ ...inputs, [fieldName]: value })
	}
	const errorsReducer = (acc, e) => ({ ...acc, [e.path]: e.message })

	const submit = (e) => {
		e.preventDefault()
		validationSchema.validate(inputs, { abortEarly: false })
			.then(() => {
				setErrors({})
				onSubmit(inputs)
			}).catch((err) => {
				const newErrors = err.inner.reduce(errorsReducer, {})
				setErrors(newErrors)
			});
	}

	useEffect(() => {
		// re-validate only if there is any error
		if(!Object.values(errors).length) return;

		validationSchema.validate(inputs, { abortEarly: false })
			.then(() => {
				setErrors({})
			}).catch((err) => {
				const newErrors = err.inner.reduce(errorsReducer, {})
				setErrors(newErrors)
			});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs])

	const reset = () => {
		setErrors({})
		setInputs(initialState)
	}

	return { subscribe, inputs, errors, submit, reset }
}

export default useForm
