const ConnectFooter = () => {
  return (
    <section className="connect-footer">
      <div className="connect-footer__links">
        <a href="https://www.womenwhocode.com/tos">Terms of Service</a>
        <a href="https://www.womenwhocode.com/privacy-policy">Privacy Policy</a>
        <a href="https://www.womenwhocode.com/codeofconduct">Code of Conduct</a>
        <a href="mailto:contact@womenwhocode.com">Contact Us</a>
      </div>

      <div className="connect-footer__textContainer">
        <p className="text">
          © 2022 Women Who Code. All rights reserved.
        </p>
      </div>
    </section>
  )
}

export default ConnectFooter
