/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';
import queryString from 'query-string';
import Alert from '../components/Alert';
import useAlert from '../hooks/useAlert';

const AuthPage = ({ action }) => {
  const { login } = useAuth();
  const { showAlert, alert } = useAlert();
  const [state, setState] = useState(
    JSON.parse(localStorage.getItem("wwc-state"))
  );

  const location = useLocation();
  const query = queryString.parse(location.search);
	let alertDuration = 2500;

	useEffect(() => {
		if(state?.message) {
			const type = state.type
			if(type === 'error') alertDuration = 15000;
			showAlert(type, alertDuration)
		}
		if(query?.message && !state?.message) {
			setState({
				message: query?.message,
				type: query.success === 'true' ? 'success' : 'error'
			})
		}
  }, [state]);

	useEffect(() => {
		if(query?.message || state?.message) {
			setTimeout(() => {
				localStorage.removeItem("wwc-state");
				login(action, query);
			}, alertDuration);
			return;
		} else {
			login(action, query);
		}
  }, []);

	return(
    <div className="new-experience-container">
      <Alert {...alert} size="md">
				{state?.message}
			</Alert>
    </div>
	);
}

export default AuthPage;
