import { useEffect } from 'react'
import { useAuth } from '../auth/useAuth';

const usePageData = ({ loading, service, callback }) => {
  const { isLoading } = useAuth();
	const getData = () => {
		if(!loading || isLoading) return;
		service().then(callback)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(getData, [isLoading])
}

export default usePageData
