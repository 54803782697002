import { LocationSearch } from "./LocationSearch";
import Search from "./Search";

function FormWithSearch({
  setSearch,
  filterComponent,
  edit,
  locationSearch,
  placeholder,
  setLocation,
  searchId,
  type,
  maxLength,
  value,
}) {
  return (
    <>
      {locationSearch ? (
        <LocationSearch
          id={searchId}
          search={locationSearch}
          setLocations={setLocation}
        />
      ) : (
        <Search
          type={type}
          onChange={setSearch}
          placeholder={placeholder}
          maxLength={maxLength}
          value={value}
        />
      )}
      {!locationSearch && (
        <>
          <div style={{ marginTop: 20 }} />
          <div
            className="filter-option-container"
            style={{ height: edit ? 'fit-content' : null }}
          >
            {filterComponent}
          </div>
        </>
      )}
    </>
  );
}

export default FormWithSearch;