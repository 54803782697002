import RadioButtons from "../../buttons/RadioButtons";
import { ReactComponent as HandThumbUp } from "../../icons/hand-thumb-up.svg";
import { ReactComponent as HandThumbDown } from "../../icons/hand-thumb-down.svg";

const Step6 = ({ values, setValues }) => {
  const options = [
    {
      text: "Yes",
      Icon: HandThumbUp,
    },
    {
      text: "No",
      Icon: HandThumbDown,
    },
  ];
  const selectedValues = values.information_consented_at;
  const handleChange = (newValues) => {
    setValues((state) => ({ ...state, information_consented_at: newValues }));
  };

  return (
    <>
      <RadioButtons
        options={options}
        selectedValues={selectedValues}
        onChange={handleChange}
      />
      <p className="form-info-text">
        Career Nav contains great career tips, including changing roles, finding
        opportunities, salary negotiation, career mapping, demystifying the
        leadership ladder, scholarships, etc.
      </p>
    </>
  );
};

export default Step6;
