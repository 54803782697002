import { FormWithSearch } from "../../../legacy/components/NewOnboarding";

const Step12 = ({ currentLocation, updateCurrentLocation }) => (
  <FormWithSearch
    locationSearch={currentLocation}
    searchId="location"
    setLocation={(locations) =>
      updateCurrentLocation(locations[locations.length - 1])
    }
  />
);
export default Step12;
