import './Input.scss'

const Input = ({ onChange, label, name, type = "Input", maxLength, error, ...props }) => {
	const handleChange = e => {
		const val = e.target.value
		if(val.length > maxLength) return;
		onChange(val)
	}
	return (
		<>
			{label && (
				<label className="Input__label" htmlFor={name}>
					{label}
				</label>
			)}
			{type === 'Input' && (
				<input {...props} className={`${props.className} Input ${error && 'Input--error'}`} onChange={handleChange} id={name} />
			)}
			{type === 'TextArea' && (
				<div className={`TextAreaContainer ${error && 'TextAreaContainer--error'}`}>
					<textarea {...props} className={`${props.className} TextArea Input`} onChange={handleChange} />
					{maxLength && (
						<span className="TextAreaContainer__maxLength">{props.value.length}/{maxLength} characters</span>
					)}
				</div>
			)}
			{error && (
				<span className="Input__error">{error}</span>
			)}
		</>
	)
}

export default Input
