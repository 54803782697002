import './Modal.scss'
import Title from '../Title'

const Modal = ({ children, isOpen, close, title }) => {
	if(!isOpen) return null;
	return(
		<div className="overlay">
				<div className="overlay__inner">
					<div className="overlay__content">
						<div className="Modal">
							<section className="Modal__header">
								<Title className="Modal__header__title">{title}</Title>
								<img
									className="Modal__header__closeButton"
									src="/images/x.png"
									alt="Close modal"
									onClick={close}
								/>
							</section>
							<section className="Modal__body">
								{children}
							</section>
						</div>
					</div>
			</div>
		</div>
	)
}

export default Modal
