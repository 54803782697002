import { BaseService } from "./BaseService";

const ApplaudHersService = {
  getApplaudHers: async (payload) => {
    let url = 'applaud_hers';
    if (payload) url = `applaud_hers?${payload}`;
    try {
      let data = await BaseService.get(url);
      return { applaudHers: data.applaud_hers, meta: data.meta };
    } catch (e) {
      console.error(e);
      return { applaudHers: [], meta: {} };
    }
  },
  createApplaudHer: async ({ firstName, lastName, socialUrl, description, network, captcha }) => {
    let url = 'applaud_hers';
    const payload = {
      "g-recaptcha-response": captcha,
      first_name: firstName,
      last_name: lastName,
      social_url: socialUrl,
      network_id: network,
      description
    }
    try {
      let response = await BaseService.post(url, payload);
      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
}

export default ApplaudHersService;