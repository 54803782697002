import React from 'react'
import ConnectFooter from '../components/ConnectFooter'
import headerImage from '../images/landing-page/header.png'

const OnboardingSection = ({ auth0Login }) => {

  return (
    <div className="onboarding-container">
      <div className="content-container">
        <nav className="navbar"></nav>
        <div className="flexrow hero-section">
          <div className="hide-on-desktop image-column">
            <img src={headerImage} className="image" alt="Onboarding Header" />
          </div>

          <div className="content-column">
            <h2 className="header">
              Empower diverse women to excel in technology careers.
            </h2>
            <div>
              <p className="body1">
                We envision a world where women are proportionally represented
                as technical leaders, executives, founders, VCs, board members,
                and software engineers.
              </p>
              <p className="body1">
                Become a member and get access to all our member benefits.
              </p>
            </div>

            <div className="content-column__buttons">
              <button
                className="onboarding-login-button"
                onClick={() => auth0Login("register")}
              >
                Sign Up
              </button>
              <button
                className="onboarding-login-button"
                onClick={() => auth0Login("login")}
              >
                Login
              </button>
            </div>
          </div>

          <div className="image-column hide-on-mobile">
            <img src={headerImage} className="image" alt="Onboarding Header" />
          </div>
        </div>
      </div>

      <form
        className="button_to js-button-auth0"
        id="Auth0Login"
        method="post"
        action="/auth/auth0"
        style={{ display: "none" }}
      >
        <button data-turbo="false" type="submit">
          Sign Up
        </button>
        <input type="hidden" name="prompt" value="login" autoComplete="off" />
      </form>

      <ConnectFooter />
    </div>
  );
};

export default OnboardingSection
