import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAuth } from "../auth/useAuth";
import EventsCard from "../legacy/components/EventsCard";
import FavoritesButton from "../legacy/components/FavoritesButton";
import Layout from "../legacy/components/Layout";
import JobCard from "../components/cards/JobCard";
import NoFavorites from "../legacy/components/NoFavorites";
import Resource from "../legacy/components/Resource";
import Tab from "../legacy/components/Tab";
import "../legacy/stylesheets/favorites_section.scss";
import ApplyToJobModal from "../components/modals/ApplyToJobModal";
import useAlert from "../hooks/useAlert";

const Favorites = () => {
  const [selectedTab, setSelectedTab] = useState("events");
  const [modalOpen, setModalOpen] = useState(false);
  const { showAlert } = useAlert()
  const { isAuthenticated, isLoading } = useAuth();
  const [selectedJob, setSelectedJob] = React.useState({});
  const favorites = useSelector((state) => state.favorites);

  const displaySelectedTab = (tabSelected) => {
    switch (tabSelected) {
      case "events":
        return tabContent(favorites.events, tabSelected);
      case "resources":
        return tabContent(favorites.resources, tabSelected);
      default:
        return tabContent(favorites.jobs, tabSelected);
    }
  };
  const selectTab = (e, tabSelected) => {
    e.preventDefault();
    setSelectedTab(tabSelected);
  };

  const applyToJob = () => {
    setModalOpen(true);
  };

  const tabContent = (contentList, tabSelected) => {
    if (!contentList) return <NoFavorites />;
    if (contentList?.length === 0) return <NoFavorites />;
    return contentList?.map((content, index) => {
      return (
        <div className="favorite-cards-wrapper" key={content.id}>
          {tabSelected === "events" ? (
            <EventsCard
              event={content}
              cardType="Favorite"
              button={<FavoritesButton text="register" link="#" />}
            />
          ) : tabSelected === "resources" ? (
            <Resource resource={content} />
          ) : tabSelected === "jobs" ? (
            <JobCard
              job={content}
              key={`job-card-${index}`}
              width={332}
              favoritePage={true}
              button={true}
              apply={applyToJob}
              setSelectedJob={setSelectedJob}
            />
          ) : null}
        </div>
      );
    });
  };

  if (!isLoading && !isAuthenticated) return <Navigate to="/" replace />;

  return (
    <Layout loading={false}>
      <ApplyToJobModal
        close={() => setModalOpen(false)}
        open={modalOpen}
        selectedJob={selectedJob}
        showAlert={showAlert}
      />
      <div id="favorites-section">
        <div style={{ marginBottom: 66 }}>
          <h1 className="fav-title">Saved Items</h1>
        </div>
        <div>
          <Tab
            selectedTab={selectedTab}
            selected={selectTab}
            tabList={["Events", "Resources", "Jobs"]}
          />
        </div>
        <div className="favorites-content">
          {displaySelectedTab(selectedTab)}
        </div>
      </div>
    </Layout>
  );
};

export default Favorites;
