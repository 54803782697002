// src/auth/auth0-provider-with-history.js

import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const defaultRedirectUri = `${window.location.origin}/dashboard`;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      cookieDomain='womenwhocode.com'
      redirectUri={defaultRedirectUri}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
