import { createSlice } from "@reduxjs/toolkit"
import ProfileService from "../../services/ProfileService"
import { AUTH_TOKEN_KEY } from "../constants/auth";

const initialState = {
  error: false,
  loadingProfile: true,
  profile: null,
  user: null,
  loadingToken: !Boolean(localStorage.getItem(AUTH_TOKEN_KEY)),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    profileLoading(state) {
      return { ...state, loadingProfile: true };
    },
    profileReceived(state, action) {
      return {
        ...state,
        loadingProfile: false,
        profile: action.payload,
      };
    },
    handleError(state, action) {
      console.log("HANDLE ERROR");
      return {
        ...state,
        error: true,
      };
    },
    receivedToken(state) {
      return {
        ...state,
        loadingToken: false,
      };
    },
    loggedOut() {
      return { ...initialState, loadingToken: false };
    },
  },
});

const getProfile = () => async (dispatch) => {
  try {
    dispatch(profileLoading());
    const response = await ProfileService.getProfile();
    dispatch(profileReceived(response));
  } catch (e) {
    console.log("Got error:", e);
    // dispatch(handleError(e))
  }
};

export const { reducer: authReducer } = authSlice;
export const {
  profileLoading,
  profileReceived,
  loggedOut,
  handleError,
  receivedToken,
} = authSlice.actions;
export { getProfile }
