import React from 'react';
import { Link } from 'react-router-dom';
import './logged_out_top_bar.scss';
import logo from '../images/landing-page/wwcode-logo-white.svg';
import { useAuth } from '../../auth/useAuth';

const LoggedOutTopBar = ({ user, removeLogin }) => {
  const wordpressUrl = process.env.REACT_APP_WORDPRESS_URL;
  const wordpressCompanyUrl = process.env.REACT_APP_WORDPRESS_COMPANY_URL;
  const {login} = useAuth();
    return (
      !user && (
      <div
        className="logged-out-top-bar"
        style={{ justifyContent: removeLogin ? 'space-between' : null }}
      >
        <a href={wordpressUrl}>
          <img className="logo" alt="Logged Out Top Bar Logo" src={logo} />
        </a>
        <div className="links">
          <Link to="/events">EVENTS</Link>
          <Link to="/resources">RESOURCES</Link>
          <Link to="/jobs">JOBS</Link>
          <a href={wordpressCompanyUrl}>HIRE</a>
        </div>
        {removeLogin ? null : (
          <React.Fragment>
            <div className="buttons">
              <button className="sign-up-button" onClick={() => login('register')}>
                SIGN UP
              </button>
              <a href="/donate">
                <button className="donate-button">DONATE</button>
              </a>
              <button className="login-button" onClick={() => login('login')}>
                <ion-icon class="person-icon" name="person"></ion-icon>
                LOG IN
              </button>
              <div className="person-icon-container" onClick={login}>
                <ion-icon class="person-icon" name="person"></ion-icon>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    ));
};

export default LoggedOutTopBar;
