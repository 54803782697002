import {
  FilterOption,
  FormWithSearch,
} from "../../../legacy/components/NewOnboarding";

const Step9 = ({
  getTagsFromRequest,
  setAspireStack,
  aspireStack,
  values,
  setValues,
}) => (
  <FormWithSearch
    type="search"
    setSearch={(event) => {
      const { value } = event.target;
      setTimeout(() => {
        getTagsFromRequest(value, setAspireStack);
      }, 1000);
    }}
    filterComponent={
      <FilterOption
        filterClassName="filter-option-modal"
        options={aspireStack.slice(0, 20)}
        property="aspiring_stack_list"
        selectedOption={values}
        setSelectedOption={setValues}
      />
    }
  />
);

export default Step9;
