import { BaseService } from "./BaseService";

const TagsService =  {
	getStacks: async(value) => {
    try {
      let query = value ? `tags?context=stack&query=${value}` : `tags?context=stack`;
			const data = await BaseService.get(query);
			return data;
		} catch(e) {
			console.error(e)
		}
  },
	getFormats: async() => {
    try {
        const data = await BaseService.get(`tags?context=format`);

        if (data && Array.isArray(data.list)) {
            const filteredList = data.list.reduce((accumulator, currentTag) => {
                const uppercaseName = currentTag.name.toUpperCase();

                if (!accumulator.has(uppercaseName) || currentTag.name === uppercaseName) {
                    accumulator.set(uppercaseName, currentTag);
                }

                return accumulator;
            }, new Map());

            data.list = Array.from(filteredList.values());
        }

        return data;
    } catch (e) {
        console.error(e);
    }
},
  getLevels: async() => {
    try {
			const data = await BaseService.get(`tags?context=level`);

			if (data && Array.isArray(data.list)) {
					const filteredList = data.list.reduce((accumulator, currentTag) => {
							const uppercaseName = currentTag.name.toUpperCase();
			
							if (!accumulator.has(uppercaseName) || currentTag.name === uppercaseName) {
									accumulator.set(uppercaseName, currentTag);
							}
			
							return accumulator;
					}, new Map());
			
					data.list = Array.from(filteredList.values());
			}
			
			return data;
		} catch(e) {
			console.error(e)
		}
	},
}

export default TagsService;
