export const searchList = (event, setTech, techList, property, values) => {
  const newTechList = techList.filter((tech) => {
    const valueLowerCase = event.target.value.toLowerCase();
    if (Array.isArray(tech))
      return tech[0].toLowerCase().includes(valueLowerCase);
    return tech.toLowerCase().includes(valueLowerCase);
  });

  if (event.target.value === "") {
    setTech(values[property]);
  } else {
    setTech(newTechList);
  }
};

export const getArrayDifference = (newList, currentList) => {
  return newList
    .filter((x) => !currentList.includes(x))
    .concat(currentList.filter((x) => !newList.includes(x)));
};

export const updateObjectAttributes = (
  attrObject,
  currentAttrList,
  attrDiff,
  attribute
) => {
  let newAttrObject = { ...attrObject };

  const AddAttribute = () => {
    newAttrObject[Object.keys(newAttrObject).length] = {
      id: "",
      [attribute]: attrDiff,
    };
  };

  const removeAttribute = () => {
    Object.values(newAttrObject).forEach((attr, index) => {
      const { [attribute]: value } = attr;
      if (value === attrDiff) {
        newAttrObject[index]["_destroy"] = "true";
      }
    });
  };

  // if attribute already exists in current list, remove else add
  currentAttrList.includes(attrDiff) ? removeAttribute() : AddAttribute();

  return newAttrObject;
};

export const getArrayObjDifference = (arr1, arr2) => {
  function getDifference(array1, array2) {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return (
          object1.latitude === object2.latitude &&
          object1.longitude === object2.longitude
        );
      });
    });
  }

  const diff = [...getDifference(arr2, arr1), ...getDifference(arr1, arr2)];

  return diff;
};

export const yearDropDown = () => {
  let yearDropDownList = [];
  let currentYear = new Date().getFullYear();
  let earliestYear = 1970;
  while (currentYear >= earliestYear) {
    yearDropDownList.push(currentYear.toString());
    currentYear -= 1;
  }

  return yearDropDownList;
};

export const getStringListFromArray = (arr) => {
  return arr.length ? arr.reduce((acc, x) => `${acc}, ${x}`) : "N/A";
};
