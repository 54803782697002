/* eslint-disable react-hooks/exhaustive-deps */
import {  useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import ProfileService from "../services/ProfileService";
import { fetchEvents } from "../app/slices/eventsSlice";
import { fetchJobs } from "../app/slices/jobsSlice";
import { fetchResources } from "../app/slices/resourcesSlice";
import { fetchFavorites } from "../app/slices/favoritesSlice";
import { getProfile, receivedToken } from "../app/slices/authSlice";
import { useAuth } from "./useAuth";
import { setUserTokenCookie } from "../utils/cookies";
import {
  AUTH_TOKEN_KEY,
  AUTH_TOKEN_EXPIRES_AT_KEY,
} from "../app/constants/auth";

export const useAppStorage = () => {
  const [loggedOut, setLoggedOut] = useState(null);
  const {
    isAuthenticated,
    isLoading: isLoadingAuth0,
    getAccessTokenSilently,
  } = useAuth0();
  const { logout, tokenExpired } = useAuth();
  const dispatch = useDispatch();
  const authStore = useSelector((x) => x.auth);
  const { error } = authStore;
  const storageHasToken = Boolean(localStorage.getItem(AUTH_TOKEN_KEY));
  const tokenExpiresAt = localStorage.getItem(AUTH_TOKEN_EXPIRES_AT_KEY);
  const cypressOverride = !!window.Cypress;

  const dispatchEffects = () => {
    dispatch(fetchEvents());
    dispatch(fetchJobs());
    dispatch(fetchResources());
  };

  const setAccessToken = useCallback(
    (value, expireSeconds) => {
      const parsed = `"${value}"`;
      const expiresAtUnixTimestamp = Date.now() / 1000 + expireSeconds;
      localStorage.setItem(AUTH_TOKEN_KEY, parsed);
      localStorage.setItem(AUTH_TOKEN_EXPIRES_AT_KEY, expiresAtUnixTimestamp);
      dispatch(receivedToken());
    },
    [dispatch]
  );

  const authenticateUser = useCallback(() => {
    if (cypressOverride) return;
    getAccessTokenSilently({ detailedResponse: true })
      .then((x) => {
        setAccessToken(x.id_token, x.expires_in);
      })
      .catch((e) => {
        // swallow the login required error
        return;
      });
  }, [setAccessToken, getAccessTokenSilently]);

  useEffect(() => {
    // Session expired
    if (tokenExpired(tokenExpiresAt)) {
      logout();
    }
    // Get jwt (logged in)
    if (cypressOverride || isLoadingAuth0 || (isAuthenticated && storageHasToken)) return;
    authenticateUser();
  }, [
    isAuthenticated,
    storageHasToken,
    isLoadingAuth0,
    tokenExpired,
    logout,
    authenticateUser,
  ]);

  useEffect(() => {
    if (error) {
      logout();
    }
  }, [error, logout]);

  useEffect(() => {
    if (isLoadingAuth0) return;

    // get data (logged in)
    if (cypressOverride || (isAuthenticated && storageHasToken)) {
      ProfileService.createProfile().then((x) => {
        setUserTokenCookie(x.userToken);
        dispatch(getProfile());
        dispatch(fetchFavorites());
      });
      setLoggedOut(false);
    } else {
      // get data (logged out)
      setLoggedOut(true);
    }
    dispatchEffects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageHasToken, isLoadingAuth0]);

  return { loggedOut };
};