import { createSlice } from "@reduxjs/toolkit";
import EventsService from "../../services/EventsService";

const eventsSlice = createSlice({
    name: 'events',
    initialState: {
        loading: true,
        events: [],
    },
    reducers: {
        eventsLoading(state) {
            return { ...state, loading: true };
        },
        eventsReceived(state, action) {
            if (state.loading) {
                return {
                    loading: false,
                    events: action.payload,
                };
            }
        },
    },
});

const fetchEvents = () => async (dispatch) => {
    dispatch(eventsLoading());
    const response = await EventsService.getEvents();
    const action = eventsReceived(response.events);
    dispatch(action);
};

const favoriteEventsSelector = (state) => ({
    ...state.events,
    events: state.events.events.filter((x) => Boolean(x.favorite)),
});

export const { reducer: eventsReducer } = eventsSlice;
export const { eventsLoading, eventsReceived } = eventsSlice.actions;
export { fetchEvents, favoriteEventsSelector };

