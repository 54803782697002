class Format {
  removeUnderscrore(string){
    if(!string) return
    if(string === null){
      return
    }
    let newString = ""
    if(string.includes("_")){
      newString = string.replace("_", " ")
    }
    if(string.includes("na")){
      newString = ""
    }
    return newString
  }

  capitalizeFirstLetter(value, separator){
    if(!value || !separator) return
    let listOfWords;
    if(value === null || value === ""){
      return
    }

    if(typeof value === "string"){
      listOfWords = value.replace(" ","-").split("-")

      const capitalWords =  listOfWords.map(word => {
        return word.charAt(0).toUpperCase() + word.substring(1)
      })
      return capitalWords.join(separator)
    }else{
      listOfWords = value
      const capitalWords =  listOfWords.map(word => {
        return word.charAt(0).toUpperCase() + word.substring(1)
      })

      return capitalWords.join(separator)
    }
  }

  abbrTimezone(timezone, timezoneObj = {}) {
    let timezoneList = Object.keys(timezoneObj)
    return timezoneList.includes(timezone) ? timezoneObj[timezone]["abbr"] : timezone
  }

  abbrTimezoneWithOffset(timezone, timezoneObj = {}) {
    let timezoneList = Object.keys(timezoneObj)
    return timezoneList.includes(timezone) ? `${timezoneObj[timezone]["abbr"]} (${timezoneObj[timezone]["utc_offset"]})` : timezone
  }

  displayTimezone(timezone){
    if(timezone === null || timezone === "") return

    return timezone.includes("Timezone") ? "" : " Timezone"
  }

  truncate(string, value) {
    let newString = ''
    if(string.length > value) {
      newString = string.substring(0, value) + "...";
    } else {
      newString = string;
    }

    return newString;
  }

  toCamelCase(str) {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  }
}

export default Format
