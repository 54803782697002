import { useState, useEffect } from 'react'

const useAlert = () => {
	const [show, setShow] = useState(false)
	const [type, setType] = useState(null)
	const [duration, setDuration] = useState(3500)

	const showAlert = (t, d) => {
		if(!d) return;
		if(!['error', 'success'].includes(t)) return;

		setType(t)
		setDuration(d)
		setShow(true)
	}

	useEffect(() => {
		if(!show) return;
		setTimeout(() => {
			setShow(false);
			setType(null)
		}, duration);
	}, [duration, show]);

	return { showAlert, alert: { show, type } }
}

export default useAlert
