const includesLc = (arr, x) => {
  if(!arr || arr.length === 0) return false
  return arr.map(y => typeof y === 'object' ? y.title.toLowerCase() : y.toLowerCase()).includes(typeof x === 'object' ? x.title.toLowerCase() : x.toLowerCase())
}

function FilterOption({
  options,
  selectedOption,
  setSelectedOption,
  property,
  filterClassName= "filter-option"
}){
  options = options || []
  const slicedOptions = options.map(x => x.name || x)
  const selected = selectedOption[property] ? selectedOption[property] : []
  const selectedFiltered = selected.filter(x => !includesLc(slicedOptions, x))
  const list = [
    ...selectedFiltered,
    ...slicedOptions
  ]

  const clickHandler = option => () => {
    const newList = includesLc(selected, option)
      ? selected.filter(t => t !== option)
      : selected.concat(option)

    setSelectedOption({ ...selectedOption, [property]: newList })
  }

  return list.map(option => {
    return (
      <div
        className={`${filterClassName}${includesLc(selected, option) ? ' selected' : ''}`}
        key={typeof option === 'object' ? option.title : option}
        onClick={clickHandler(option)}
      >
        {typeof option === 'object' ? option.title : option}
        {
          includesLc(selected, option) ?
          <ion-icon class="remove-icon" name="checkmark"/> :
          <ion-icon class="add-icon" name="add"/>
        }
      </div>
    )
  })
}

export default FilterOption
