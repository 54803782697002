import Skeleton from "../../components/Skeleton";
import FilterItem from "./FilterItem";

const QuestionWithFilters = ({ question, filters, skeleton, loading }) => {
  const renderFilters = (items) => {
    let list = items;
    if (skeleton) {
      return (
        <div style={{ display: "flex", flexWrap: "wrap", gap: 12 }}>
          {Array.from({ length: 8 }, (_, index) => {
            const randomWidth = Math.floor(Math.random() * (150 - 80 + 1) + 80);

            return <Skeleton key={`skeleton-${index}`} w={randomWidth} h={36} rounded={100} />;
          })}
        </div>
      );
    }

    if (items.length === 0) {
      return <p className="text-answer">N/A</p>;
    }
    if (typeof items === "string") {
      list = items.split(",");
    }
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {list.map((item) => {
          if (item.name) {
            return (
              <FilterItem text={item.name} key={item.name} loading={loading} />
            );
          }
          return <FilterItem text={item} key={item} loading={loading} />;
        })}
      </div>
    );
  };
  if (!filters || Object.keys(filters).length === 0) {
    return (
      <div className="question-block">
        {skeleton ? (
          <Skeleton h={32} w={350} rounded={100} />
        ) : (
          <h5 className="text-header">{question}</h5>
        )}
        <div style={{ marginBottom: 32 }} />
        {skeleton ? (
          <Skeleton h={28} w={120} rounded={100} />
        ) : (
          <p className="text-answer">N/A</p>
        )}
      </div>
    );
  }

  return (
    <div className="question-block">
      {skeleton ? (
        <Skeleton h={32} w={350} rounded={100} />
      ) : (
        <h5 className="text-header">{question}</h5>
      )}
      <div style={{ marginBottom: filters.length === 0 ? 32 : 20 }} />
      {renderFilters(filters)}
    </div>
  );
};

export default QuestionWithFilters;
