import { BaseService } from "./BaseService"

const SettingsService =  {
	getSettings: async() => {
		try {
			const settings = await BaseService.get(`settings/base`)
			return settings
		} catch(e) {
			return {}
		}
	}
}

export default SettingsService
