import './Select.scss';

const Select = ({ className, label, name, options, onChange, value, error }) => {
  const handleChange = (e) => {
    onChange(e);
  };

  return (
    <>
      {label && (
        <label className="Select__label" htmlFor={name}>
          {label}
        </label>
      )}
      <select
        className={`Select ${className} ${error ? 'Select--error' : ''}`}
        id={name}
        name={name}
        value={value}
        onChange={handleChange}
      >
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.title}
          </option>
        ))}
      </select>
      {error && <span className="Select__error">{error}</span>}
    </>
  );
};

export default Select;
