import Skeleton from "../../components/Skeleton";
import "./filter_buttons.scss";
const FilterButtons = ({ buttons, handler, skeleton, loggedIn }) => {
  const filteredButtons = buttons.filter((x) =>
    x.requiresLogin ? loggedIn : true
  );
  return (
    <section className="FilterButtons">
      {skeleton
        ? filteredButtons.map((x) => (
            <Skeleton w={223} h={58} rounded key={x.value} />
          ))
        : filteredButtons.map((x) => (
            <div
              className={`button__background ${
                x.active && "button__background--active"
              }`}
              onClick={(event) => handler(event, x.value)}
              key={x.value}
            >
              <span className="button__text">{x.text}</span>
            </div>
          ))}
    </section>
  );
};
export default FilterButtons;
