import "./Radio.scss";

const Radio = ({ value, onChange, name, label, checked, ...rest }) => {
  return (
    <label className="radio-label" htmlFor={name + label}>
      <input
        id={name + label}
        type="radio"
        name={name}
        className="native-radio"
        checked={checked}
        value={value}
        onChange={(event) => {
          onChange(event);
        }}
        {...rest}
      />
      <div className="custom-radio" />
      <p style={{ margin: "0 0 0 8px" }}>{label}</p>
    </label>
  );
};

export default Radio;
