import { useEffect, useState } from "react";
import "./ScrollToTopButton.scss"

const ScrollToTopButton = ({ hide }) => {
  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const show = !hide && scrollPosition > 0;

  return (
    <div
      className={`ScrollToTopButton ${show ? "visible" : "invisible"}`}
      onClick={goToTop}
    >
      <ion-icon className="icon" name="chevron-up-outline" />
    </div>
  );
};

export default ScrollToTopButton;
