import * as yup from 'yup'

const schema = yup.object().shape({
  email: yup.string()
		.required("Please enter an email.")
		.email("Please enter a valid email format."),
  link: yup.string()
		.required("Please enter a url.")
		.url("Please enter a valid url format."),
  message: yup.string()
		.required("Please enter a message."),
	captcha: yup.string().nullable()
		.required("Please verify you are a human.")
})

export default schema
