import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "./Tooltip.scss";

export const Tooltip = ({ ...rest }) => {
  return (
    <Tippy
      arrow={false}
      placement="bottom"
      theme="default"
      {...rest}
    />
  );
};
