import React from "react";
import { useSelector } from "react-redux";
import ReactMarkdown from 'react-markdown';
import Format from "./utils/Format";
import CopyLink from "./CopyLink";
import "./events_card.scss";
import Favorite from "./Favorite";
import EventsService from "../../services/EventsService";
import Skeleton from "../../components/Skeleton";

const EventsCard = ({ event, cardType }) => {
  const format = new Format();

  const {
    id,
    title,
    digital,
    end_time,
    start_time,
    network_title,
    recurring,
    weekday,
    day,
    month,
    featured,
    photo_url,
    href,
    details,
    user_time_zone,
  } = event ?? {};

  const favorites = useSelector((state) => state.favorites.events) || [];
  const isFavorite = favorites.some((favoriteEvent) => favoriteEvent?.id === id);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const updateEventAndRedirectToUrl = () => {
    EventsService.updateEvent(event);
    openInNewTab(event.href);
  };

  return (
    <div className={`${`event${cardType ?? ''}CardContainer`}`}>
      <article className={`${`event${cardType ?? ''}Card`}`}>
        <header className="header">
          {id ? (
            <div className={`header__type ${!digital && "header__type--gray"}`}>
              <span className="header__typeText">
                {digital === true ? "ONLINE" : "IN PERSON"}
              </span>
            </div>
          ) : null}
          {featured && (
            <div
              className="header__type header__type--featured"
            >
              <span className="header__typeText">
                FEATURED
              </span>
            </div>
          )}
          {id ? (
            <img
              src={photo_url}
              className="header__image"
              alt=""
            />
          ) : (
            <Skeleton className="header__image" />
          )}
          <section className="header__information">
            <time className="information__day">
              {id ? (
                <span>{weekday},</span>
              ) : (
                <Skeleton w={90} mb={8} rounded />
              )}
              {id ? (
                <span className="information__day--bold">
                  {month} {day}
                </span>
              ) : (
                <Skeleton w={94} mb={5} rounded />
              )}
              {id ? (
                <span>
                  {start_time} - {end_time} {user_time_zone}
                </span>
              ) : (
                <Skeleton w={152} rounded />
              )}
            </time>
          </section>
        </header>
        <section className="body">
          <div className="body__heading">
            {id ? (
              <h3 className="body__title">
                {format.truncate(
                  title ?? "",
                  `${cardType === "Dashboard" ? 48 : 64}`
                )}
              </h3>
            ) : (
              <Skeleton
                className="body__title"
                w={225}
                h={24}
                mb={24}
                rounded
              />
            )}
            {cardType === "Dashboard" && (
              <Favorite type="events" item={{...event}} favorite={isFavorite} />
            )}
          </div>
          {id ? (
            <span className="body__recurring">{recurring && "Recurring"}</span>
          ) : (
            <Skeleton
              className="body__recurring"
              w={63}
              h={12}
              mb={12}
              rounded
            />
          )}
          {id ? (
            <span className="body__community">
              {network_title &&
                format.truncate(
                  network_title, 
                  window.innerWidth < 768 ? 28 : 38
                )}
            </span>
          ) : (
            <Skeleton
              className="body__recurring"
              w={77}
              h={12}
              mb={12}
              rounded
            />
          )}
          {id ? (
            <div className="body__text">
              {details &&
                (
                  <ReactMarkdown>
                    {
                      format.truncate(
                        details,
                        `${cardType === "Dashboard" ? 168 : 198}`
                      )
                    }
                  </ReactMarkdown>
                )
              }
            </div>
          ) : (
            <Skeleton className="body__text" w="100%" rounded />
          )}
          {id
            ? !(cardType === "Dashboard") && (
                <footer className="body__buttons">
                  <button
                    className="event-registerbtn"
                    onClick={updateEventAndRedirectToUrl}
                  >
                    REGISTER
                  </button>
                  <Favorite
                    type="events"
                    item={{...event}}
                    favorite={isFavorite}
                    button
                  />
                  <div className="mobile-copy-link-container">
                    <CopyLink url={href} />
                  </div>
                  <button className="copy-link-button" type="button">
                    <CopyLink url={href} />
                    SHARE
                  </button>
                </footer>
              )
            : !(cardType === "Dashboard") && (
                <footer className="body__buttons">
                  <Skeleton w={97} h={48} radius={8} />
                  <Skeleton w={99} h={48} radius={8} ml={12} />
                </footer>
              )}
        </section>
      </article>
    </div>
  );
};

export default EventsCard;
