import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import { createPortal } from "react-dom";
import { Transition } from "react-transition-group";
import "./Toast.scss";

export const TOAST_DELAY = 100;
export const TOAST_TIMEOUT = 3500;
export const TOAST_ANIMATION_DURATION = 300;
export const TOAST_GAP = 20;
export const TOAST_HEIGHT = 80;
export const TOAST_OFFSET = 80;

export const Toast = ({ variant, message, ...rest }) => {
  switch (variant) {
    case "error":
      return (
        <div className="status-changed error-status" {...rest}>
          <p style={{ margin: 0 }}>{message}</p>
        </div>
      );
    case "default":
    default:
      return (
        <div className="status-changed" {...rest}>
          <p style={{ margin: 0 }}>{message}</p>
        </div>
      );
  }
};

export const StackedToast = ({ id, dismissed, ...rest }) => {
  const { removeToast, toasts } = useToast();
  const [initialized, setInitialized] = useState(false);

  const count =
    1 +
    Object.values(toasts)
      .filter(({ dismissed }) => !dismissed)
      .map(({ id }) => id)
      .indexOf(id);

  const initialPosition = -(
    count * (TOAST_GAP * 2 + TOAST_HEIGHT) +
    TOAST_OFFSET
  );

  const defaultStyle = {
    transition: `top ${TOAST_ANIMATION_DURATION}ms ease-in-out`,
    top: initialPosition,
  };

  const transitionStyles = {
    entering: { top: 0 },
    entered: { top: 0 },
    exiting: { top: initialPosition },
    exited: { top: initialPosition },
  };

  // Minor delay to initialize toast and include for 0 position
  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialized(true);
    }, TOAST_DELAY);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(id);
    }, TOAST_TIMEOUT);

    return () => {
      clearTimeout(timer);
    };
  });

  if (!dismissed) {
    return (
      <Transition in={initialized} timeout={TOAST_TIMEOUT}>
        {(state) => (
          <Toast
            onClick={() => removeToast(id)}
            style={{ ...defaultStyle, ...transitionStyles[state] }}
            {...rest}
          />
        )}
      </Transition>
    );
  }
  

  return <></>;
};

const ToastController = ({ toasts }) => {
  return createPortal(
    <div className="stacked-status-changed">
      {Object.keys(toasts).map((key) => {
        const props = toasts[key];
        return <StackedToast key={props.id} {...props} />;
      })}
    </div>,
    document.getElementById("root")
  );
};

const ToastContext = createContext({
  addToast: () => {
    throw new Error("Missing ToastsProvider.");
  },
  removeToast: () => {
    throw new Error("Missing ToastsProvider.");
  },
  toasts: {},
});

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState({});

  let id = 0;

  const addToast = useCallback(
    (toastProps) => {
      id++;

      setToasts((prevState) => ({
        ...prevState,
        [id]: { id, ...toastProps, dismissed: false },
      }));
    },
    [setToasts, id]
  );

  const removeToast = useCallback(
    (id) => {
      setToasts((prevState) => {
        let state = {
          ...prevState,
          [id]: {
            ...prevState[id],
            dismissed: true,
          },
        };

        return state;
      });
    },
    [setToasts]
  );

  return (
    <ToastContext.Provider value={{ addToast, removeToast, toasts }}>
      {!!Object.keys(toasts)?.length && <ToastController toasts={toasts} />}
      {children}
    </ToastContext.Provider>
  );
};

export function useToast() {
  return useContext(ToastContext);
}
