import { FormWithDropdown } from "../../../legacy/components/NewOnboarding";

const Step11 = ({ yearsInTech, values, setValues }) => (
  <FormWithDropdown
    options={yearsInTech}
    setOption={setValues}
    values={values}
    property="years_in_tech"
    label="Select Years"
  />
);
export default Step11;
