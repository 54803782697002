import React from 'react'
import Input from './Input'
import FileUploadButton from '../FileUploadButton'

function FormWithInputs({
  header,
  inputs,
  fileType,
  values,
  setValues,
  name,
  maxLength,
}){
  const fileRef = React.useRef()
  const [error] = React.useState(false)

  const renderInputs = (inputs) => {
    return inputs.map(input => (
      <Input
        label={input.label}
        name={input.name}
        placeholder={input.placeholder}
        onChange={(e) => getInputValues(e, input.name)}
        key={input.name}
        error={error}
        maxLength={maxLength}
        value={values[input.name]}
      />
    ))
  }

  const getInputValues = (event, label) => {
    if(fileType){
      setValues({...values, [label]: event.target.files[0].name})
    }else{
      setValues({...values, [label]: event.target.value})
    }
  }

  const openFile = () => {
    fileRef.current.click()
  }

  const renderFileInput = () => {
    return(
      <FileUploadButton
        width="100%"
        openFile={openFile}
        fileRef={fileRef}
        onChange={(e) => getInputValues(e, name)}
      />
    )
  }
  return(
    <React.Fragment>
      <h4 className="form-header">{header}</h4>
      { fileType ? renderFileInput() : renderInputs(inputs) }
    </React.Fragment>
  )
}

export default FormWithInputs
