import "./ProgressBar.scss";

function ProgressBar({ step, steps, goToPage }) {
  const progressPercent = (step / steps) * 100;
  return (
    <div className="ProgressBar">
      <div className="progress-container">
        <div
          className="progress"
          style={{ width: `${Math.floor(progressPercent)}%` }}
        ></div>
      </div>
      <div className="ProgressBar__Steps">
        <div className="step-container">
          <p className="current-step current-step-bottom">
            Step {step} of {steps}
          </p>
        </div>
        <div
          className="skip-button"
          onClick={() => goToPage(step + 1, true)}
        >
          <h6 className="skip-text">Skip Step</h6>
          <img src="/images/chevron-forward.png" alt="Right arrow" />
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;
