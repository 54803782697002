import { Provider } from "react-redux";
import { useEffect } from "react";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import "./legacy/stylesheets/new_dashboard.scss";
import RouterNavigator from "./RouterNavigator";
import { store } from "./app/store";
import { ToastProvider } from "./components/Toast";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_TRACKING_ID
}

const cypressOverride = !!window.Cypress;

if (!cypressOverride && process.env.REACT_APP_G4_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_G4_TRACKING_ID)
  TagManager.initialize(tagManagerArgs)
}

const App = () => {
  useEffect(() => {
    if (!cypressOverride) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }
  }, []);

  return (
    <Provider store={store}>
      <Auth0ProviderWithHistory>
        <ToastProvider>
          <RouterNavigator />
        </ToastProvider>
      </Auth0ProviderWithHistory>
    </Provider>
  );
};

export default App;
