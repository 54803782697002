import { useEffect, useState } from "react";
import ProfileService from "../../services/ProfileService";
import Button from "./Button";
import FilterOption from "./FilterOption";
import FormWithSearch from "./FormWithSearch";
import QuestionHeader from "./QuestionHeader";
import QuestionWithRadio from "./QuestionWithRadio";
import { getCurrentLocationState } from "../../utils/locations";
import { searchList } from "./utils/FormUtils";
import { buildDestroyableElementsArray, markDestroyableElements } from "../../utils/functions/destroyableElements";
import { ReactComponent as GlobeAmericasIcon } from "../../components/icons/globe-americas.svg";
import { ReactComponent as BuildingOffice2Icon } from "../../components/icons/building-office-2.svg";
import { ReactComponent as ShuffleIcon } from "../../components/icons/shuffle.svg";
import RadioButtons from "../../components/buttons/RadioButtons";

const LocationSettingsEdit = ({
  data,
  isEdit,
  setEdit,
  successFlash,
  errorFlash,
  profile,
  setProfile,
  fetchProfile,
}) => {
  const timezonesList = data?.time_zones;
  const [timezones, setTimezone] = useState(timezonesList);
  const [values, setValues] = useState(profile);
  const [preferredLocation, setPreferredLocation] = useState({
    location: Object.values(profile?.preferred_locations_attributes),
  });
  const [currentLocation, setCurrentLocation] = useState(
    getCurrentLocationState(profile)
  );

  useEffect(() => {
    const locationInputElement = values.location;
    const locationAutocomplete = new window.google.maps.places.Autocomplete(
      locationInputElement,
      {
        types: ["(cities)"],
      }
    );
    window.google.maps.event.addListener(
      locationAutocomplete,
      "place_changed",
      () => {
        const place = locationAutocomplete.getPlace();
        updateCurrentLocation(place);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const handleRadio = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleWorkLocationChange = (newValues) => {
    setValues((state) => ({ ...state, work_location: newValues }));
  };

  const updateCurrentLocation = (locationObj) => {
    setCurrentLocation({ location: locationObj ? [locationObj] : [] });
    setValues((x) => ({
      ...x,
      location: locationObj?.address_attributes?.formatted_address ?? null,
      latitude: locationObj?.address_attributes?.latitude ?? null,
      longitude: locationObj?.address_attributes?.longitude ?? null,
    }));
  };

  const updatePreferredLocations = (locations) => {
    setPreferredLocation({ location: locations });
    setValues((x) => ({ ...x, preferred_locations_attributes: locations }));
  };

  const updatePreferredTimezoneAttributes = (newValues) => {
    const newTimezoneAttributes = buildDestroyableElementsArray(
      newValues.preferred_timezones,
      Object.values(newValues.preferred_time_zones_attributes),
      "time_zone"
    );
    setValues({
      ...newValues,
      preferred_time_zones_attributes: newTimezoneAttributes,
    });
  };

  return (
    <div className="settings-wrapper">
      <div className="flex-wrap">
        <QuestionHeader question={"Where are you located?"}>
          <FormWithSearch
            edit={isEdit}
            locationSearch={currentLocation}
            searchId="location"
            setLocation={(locations) =>
              updateCurrentLocation(locations[locations.length - 1])
            }
          />
        </QuestionHeader>
        <div style={{ marginRight: "10%" }} />
        <QuestionHeader question={"Which job locations would you apply to?"}>
          <FormWithSearch
            locationSearch={preferredLocation}
            searchId="preferred_locations_attributes"
            setLocation={updatePreferredLocations}
          />
        </QuestionHeader>
      </div>
      <div className="flex-wrap">
        <div className="question-block">
          <QuestionWithRadio
            question="Are you willing to relocate?"
            answers={["Yes", "No"]}
            name="relocate"
            radioOnChange={handleRadio}
            value={values.relocate}
          />
        </div>
        <div style={{ marginRight: "10%" }} />
        <div className="question-block">
          <h5 className="text-header text-header--margin">
            What is your preferred work environment?
          </h5>
          <RadioButtons
            options={[
              { text: "Remote", Icon: GlobeAmericasIcon },
              { text: "In Office", Icon: BuildingOffice2Icon },
              { text: "Hybrid", Icon: ShuffleIcon },
            ]}
            selectedValues={values.work_location}
            onChange={handleWorkLocationChange}
            multiple
          />
        </div>
      </div>
      <div style={{ marginTop: 48 }} />
      <QuestionHeader question={"What timezones are you willing to work in?"}>
        <FormWithSearch
          edit={isEdit}
          placeholder="Search"
          setSearch={(event) => {
            searchList(
              event,
              setTimezone,
              timezonesList,
              "preferred_time_zones_attributes",
              timezones
            );
          }}
          filterComponent={
            <FilterOption
              options={timezones}
              property="preferred_timezones"
              selectedOption={values}
              setSelectedOption={updatePreferredTimezoneAttributes}
            />
          }
        />
      </QuestionHeader>
      <div className="bottom-save-button">
        <Button
          icon="save"
          text="Apply Changes"
          className={"fill-button-profile"}
          onClick={() => {
            ProfileService.updateProfile({
              ...values,
              preferred_locations_attributes: markDestroyableElements(
                Object.values(profile.preferred_locations_attributes),
                Object.values(values.preferred_locations_attributes)
              ),
              preferred_time_zones_attributes: markDestroyableElements(
                Object.values(profile.preferred_time_zones_attributes),
                Object.values(values.preferred_time_zones_attributes)
              ),
            })
              .then((newProfile) => {
                successFlash(true);
                setProfile(newProfile);
                fetchProfile();
                setEdit(false);
              })
              .catch((err) => {
                errorFlash({
                  error: true,
                  message: ProfileService.errorMessages(err.status),
                });
                console.log("ERROR");
              });
          }}
        />
      </div>
    </div>
  );
};

export default LocationSettingsEdit;
