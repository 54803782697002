const setCookie = (name, value) => {
  const domain = "; domain=" + window.location.hostname.split('.').slice(-2).join('.');
  document.cookie = name + "=" + (value || "")  + "; path=/" + domain + "; SameSite=None; Secure; expires=Session";
}

const removeCookie = (name) => {
  // will return value or null
  const cookieValue = (document.cookie.match(
    RegExp("^(?:.*;)?\\s*" + name + "\\s*=\\s*([^;]+)(?:.*)?$")
  ) || [null])[1];
  if (null === cookieValue) {
    return;
  }
  const domain = "; domain=" + window.location.hostname.split('.').slice(-2).join('.');
  // Cookie properties have to be the same in order to set the expiration
  document.cookie = name + "=" + cookieValue  + "; path=/" + domain + "; SameSite=None; Secure; expires=Thu, 01 Jan 1970 00:00:01 GMT";
}

const setUserTokenCookie = (token) => setCookie('userToken', encodeURIComponent(token))
const removeUserTokenCookie = () => removeCookie('userToken')

export { setUserTokenCookie, removeUserTokenCookie }
export { setCookie, removeCookie }
