import React, { useState, useRef, useEffect } from 'react';
import './youtube_modal.scss';

function useOutsideAlerter(ref) {
  const [hide, setHide] = useState(false);
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setHide(true);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
  }, [ref]);
  return hide;
}

const YoutubeModal = ({ youtubeUrl, setYoutubeUrl }) => {
  const [loaded, setLoaded] = useState(false);
  const wrapperRef = useRef(null);
  const hide = useOutsideAlerter(wrapperRef);

  useEffect(() => {
    if(hide) {
      setLoaded(false)
      setTimeout(() => setYoutubeUrl(''), 300);
    }
  }, [setYoutubeUrl, hide]);

  return (
    <div className={`youtube-modal-overlay ${!loaded && 'hide'}`}>
      <div className={`youtube-modal-container ${!loaded && 'hide'}`} ref={wrapperRef}>
        <iframe
          className="youtube-modal modal"
          src={youtubeUrl}
          allowFullScreen
          title="Youtube Iframe"
          onLoad={() => setLoaded(true)}
        />
      </div>
    </div>
  );
};
export default YoutubeModal;
