import { FormWithInputs } from "../../../legacy/components/NewOnboarding";

const StepOne = ({ values, setValues, step, goToPage }) => {
  return (
    <FormWithInputs
      inputs={[
        {
          label: "First Name",
          name: "first_name",
          placeholder: "Enter a name",
        },
        { label: "Last Name", name: "last_name", placeholder: "Enter a name" },
      ]}
      values={values}
      setValues={setValues}
      step={step}
      goToPage={goToPage}
    />
  );
};

export default StepOne;
