import Title from "../../Title";
import Text from "../../Text";
import Button from "../../Button";

const Step0 = ({ titles, profile, setOnBoardingCompleted, goToPage, completeOnboarding }) => (
  <>
    <Title>{titles[0]}</Title>
    <Text>
      We’d like to help customize your experience in the platform by asking you
      a few questions.
      <br />
      <br />
      You can always fill this in later through Settings.
    </Text>
    <div className="OnboardingModal__Buttons">
      <Button
        outline
        onClick={() => {
          completeOnboarding(profile)
          setOnBoardingCompleted(true);
        }}
      >
        Maybe Later
      </Button>
      <Button onClick={() => goToPage(1)}>Fill in now</Button>
    </div>
  </>
);
export default Step0;
