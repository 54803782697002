import { BaseService } from "./BaseService"

const SettingsService =  {
	getDashboard: async() => {
		try {
			const dashboard = await BaseService.get(`dashboards`)
			return dashboard
		} catch(e) {
			console.error(e)
			return {}
		}
	}
}

export default SettingsService
