const urlParamsToLocationsArray = (params) => {
	if(!params?.length) return []

	const locations = params.split('~')
	let locationsObjectsArray = []
	for (let i = 0; i < locations?.length; i++) {
		let locationPropertiesArray = locations[i].split('^')
		locationsObjectsArray.push({
			address_attributes: {
				city: locationPropertiesArray[0],
				latitude: locationPropertiesArray[1],
				longitude: locationPropertiesArray[2],
				formatted_address: locationPropertiesArray[3],
			},
		})
	}
	return locationsObjectsArray
}

const locationStateToUrlParams = (locations) => {
	let locationsParamString = ''
	for (let i = 0; i < locations.length; i++) {
		if (i > 0) {
			locationsParamString = locationsParamString + '~'
		}
		locationsParamString =
			locationsParamString +
			locations[i].address_attributes.city +
			'^' +
			locations[i].address_attributes.latitude +
			'^' +
			locations[i].address_attributes.longitude +
			'^' +
			locations[i].address_attributes.formatted_address
	}
	return locationsParamString
}

const getCurrentLocationState = profile => {
	if(!profile?.location) return { location: [] }
	return {
		location: [{
			address_attributes: {
				formatted_address: profile.location,
				latitude: profile.latitude,
				longitude: profile.longitude
			}
		}]
	}
}

export  {
	urlParamsToLocationsArray,
	locationStateToUrlParams,
	getCurrentLocationState
}
