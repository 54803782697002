import { AUTH_TOKEN_KEY } from "../app/constants/auth";
const API_URL = process.env.REACT_APP_API_URL;
const API_URL_V1 = process.env.REACT_APP_API_URL_V1;

const getJwt = () => {
  const storageJwt = localStorage.getItem(AUTH_TOKEN_KEY);
  const jwt = storageJwt && storageJwt.slice(1, -1);
  return jwt;
};

export const BaseServiceV1 = {
  async get(url) {
    const request = await fetch(`${API_URL_V1}/${url}`, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
    if (request.status >= 400) throw request;
    return request.json();
  },
};

const getHeaders = () => {
  const headers = { 'Content-Type': 'application/json' };
  const jwt = getJwt();
  if (jwt) headers.Authorization = `Bearer ${jwt}`;
  return headers;
};

export const BaseService = {
  async get(url) {
    const headers = getHeaders();
    const request = await fetch(`${API_URL}/${url}`, { headers });
    if (request.status >= 400) throw request;
    return request.json();
  },
  async post(url, data = {}, isJson = true) {
    const headers = getHeaders();
    const request = await fetch(`${API_URL}/${url}`, {
      headers,
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (request.status >= 400) throw request;
    if (!isJson) return;
    return request.json();
  },
  async put(url, data = {}) {
    const headers = getHeaders();
    const request = await fetch(`${API_URL}/${url}`, {
      headers,
      method: 'PUT',
      body: JSON.stringify(data),
    });
    if (request.status >= 400) throw request;
    return request.json();
  },
  async delete(url) {
    const headers = getHeaders();
    const request = await fetch(`${API_URL}/${url}`, {
      headers,
      method: 'DELETE',
    });
    if (request.status >= 400) throw request;
    return request.json();
  },
};
