import Skeleton from '../../components/Skeleton';
import './page_banner.scss';
const PageBanner = ({ text, source, imageUrl, skeleton }) => {
  return (
    <div
      className="page-banner"
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right'
      }}
    >
      {skeleton && (
        <Skeleton className="banner-skeleton" h={192} />
      )}
      <div className="right-side-outer-container">
        <div className="right-side-inner-container">
          <h1 className="quotation">“</h1>
        </div>

        <div className="right-side-inner-container">
          <h1 className="text">
            {text}
            <span className="source">— {source}</span>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default PageBanner;
