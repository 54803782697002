import Skeleton from "../../components/Skeleton";

const Tab = ({ selected, selectedTab, tabList, skeleton, disable }) => {
  return (
    <ul style={{ display: "flex" }}>
      {tabList.map((tab, index) => {
        if (skeleton) {
          return (
            <Skeleton key={`tab-skeleton-${index}`} h={30} w={90} rounded={100} mr={20} />
          );
        } else {
          return (
            <li style={{ marginRight: 16 }} key={index}>
              <a
                href="/#"
                className={`tab-text ${
                  selectedTab === tab.toLowerCase() ? "active-tab" : ""
                }`}
                target="_self"
                onClick={(e) => {
                  e.preventDefault();
                  if (disable) return;
                  selected(e, tab.toLowerCase());
                }}
              >
                <div
                  className={
                    disable && selectedTab !== tab.toLowerCase()
                      ? "disable-text"
                      : ""
                  }
                >
                  {tab}
                </div>
              </a>
            </li>
          );
        }

      })}
    </ul>
  );
};

export default Tab;
