import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TechSettings from "../legacy/components/TechSettings";
import LocationSettings from "../legacy/components/LocationSettings";
import CareerSettings from "../legacy/components/CareerSettings";
import Button from "../legacy/components/Button";
import Tab from "../legacy/components/Tab";
import "../legacy/stylesheets/settings_section.scss";
import Layout from "../legacy/components/Layout";
import "../legacy/stylesheets/profile_section.scss";
import { useAuth } from "../auth/useAuth";
import SettingsService from "../services/SettingsService";
import ProfileService from "../services/ProfileService";
import { usePageData } from "../hooks/index";
import { settingsReceived } from "../app/slices/pagesSlice";
import { useToast } from "../components/Toast";
import Skeleton from "../components/Skeleton";

const Settings = () => {
  const [selectedTab, setSelectedTab] = useState("tech");
  const [edit, setEdit] = useState(false);

  const { logout, isAuthenticated, isLoading } = useAuth();

  const toast = useToast();

  const screenWidth = window.innerWidth;

  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state.pages);
  const { settingsData, loading: loadingSettings } = settings;

  usePageData({
    service: SettingsService.getSettings,
    callback: (data) => dispatch(settingsReceived(data)),
    loading: loadingSettings,
  });

  const [profile, setProfile] = useState({
    email: "",
    url: "",
    bio: "",
    parent: false,
    linkedin_url: "",
    github_username: "",
    twitter_username: "",
    first_name: "",
    last_name: "",
    time_zone: "",
    job_title: "",
    work: "",
    starter_year_in_tech: "",
    career_opportunities: "",
    years_in_tech: "",
    job_search: [],
    location: "",
    longitude: "",
    latitude: "",
    work_location: "",
    remote: false,
    relocate: false,
    preferred_location_city: "",
    preferred_location_state: "",
    preferred_location_country: "",
    resume_url: "",
    resume_filename: "",
    current_onboarding_page: "",
    onboarding_completed: false,
    pronoun_list: [],
    current_stack_list: [],
    aspiring_stack_list: [],
    engineering_interest_list: [],
    expertise_list: [],
    why_join_list: [],
    expertise: "",
    information_consented_at: "",
    avatar_url: "",
    locations_attributes: {},
    preferred_locations_attributes: {},
    network_members_attributes: {},
    networks: [],
    preferred_time_zones_attributes: {},
  });

  const fetchProfile = () => {
    ProfileService.getProfile().then(
      (profile) => profile && setProfile(profile)
    );
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const selectTab = (evt, tab) => {
    evt.preventDefault();
    setSelectedTab(tab);
  };

  const loading = loadingSettings || isLoading;

  const tabContent = (tabSelected) => {
    switch (tabSelected) {
      case "career":
        return (
          <CareerSettings
            isEdit={edit}
            screenWidth={screenWidth}
            setEdit={setEdit}
            profile={profile}
            setProfile={setProfile}
            editData={settingsData}
            successFlash={() => {
              toast.addToast({ message: "Your changes have been applied." });
            }}
            errorFlash={({ message }) =>
              toast.addToast({ message, variant: "error" })
            }
            fetchProfile={fetchProfile}
            skeleton={loading}
          />
        );
      case "location":
        return (
          <LocationSettings
            isEdit={edit}
            setEdit={setEdit}
            profile={profile}
            setProfile={setProfile}
            editData={settingsData}
            successFlash={() => {
              toast.addToast({ message: "Your changes have been applied." });
            }}
            errorFlash={({ message }) =>
              toast.addToast({ message, variant: "error" })
            }
            fetchProfile={fetchProfile}
            skeleton={loading}
          />
        );
      case "tech":
      default:
        return (
          <TechSettings
            isEdit={edit}
            setEdit={setEdit}
            profile={profile}
            setProfile={setProfile}
            editData={settingsData}
            successFlash={() => {
              toast.addToast({ message: "Your changes have been applied." });
            }}
            errorFlash={({ message }) =>
              toast.addToast({ message, variant: "error" })
            }
            fetchProfile={fetchProfile}
            skeleton={loading}
          />
        );
    }
  };

  if (!isLoading && !isAuthenticated) return <Navigate to="/" replace />;

  return (
    <Layout loading={false}>
      <div id="profile-section">
        {screenWidth < 860 ? (
          <div className="title-button-container">
            <div className="settings-header">
              {loading ? (
                <Skeleton h={40} w={300} rounded={8} />
              ) : (
                <h1 className="profile-title">Settings</h1>
              )}
              {edit ? (
                <div onClick={() => setEdit(false)}>
                  <ion-icon
                    class="settings-pencil-icon"
                    name="close"
                  ></ion-icon>
                </div>
              ) : (
                <div onClick={() => setEdit(true)}>
                  <ion-icon
                    class="settings-pencil-icon"
                    name="pencil"
                  ></ion-icon>
                </div>
              )}
            </div>
            <div onClick={logout}>
              <ion-icon class="settings-logout-icon" name="log-out"></ion-icon>
            </div>
          </div>
        ) : (
          <div className="title-button-container">
            {loading ? (
              <Skeleton h={40} w={180} rounded={8} />
            ) : (
              <h1 className="profile-title">Settings</h1>
            )}
            <div style={{ display: "flex" }}>
              {edit ? (
                <Button
                  icon="close"
                  text="Cancel"
                  width={135}
                  showSkeleton={loading}
                  onClick={() => setEdit(false)}
                  skeleton={loading}
                  rounded
                />
              ) : (
                <Button
                  icon="pencil"
                  text="Edit"
                  showSkeleton={loading}
                  width={105}
                  onClick={() => setEdit(true)}
                  skeleton={loading}
                  rounded
                />
              )}
              <div style={{ marginRight: 20 }} />
              <Button
                href="/logout"
                icon="log-out"
                text="Log Out"
                className={"fill-button-danger"}
                width={144}
                onClick={logout}
                skeleton={loading}
                rounded
              />
            </div>
          </div>
        )}
        {loading ? (
          <Skeleton h={20} w={"60%"} rounded={8} />
        ) : (
          <p className="setting-info">
            This information will be used to customize your experience and
            search results throughout the platform.
          </p>
        )}
        <div style={{ marginBottom: 34 }} />
        <div>
          <Tab
            selectedTab={selectedTab}
            selected={selectTab}
            tabList={["Tech", "Location", "Career"]}
            disable={edit}
            skeleton={loading}
          />
        </div>
        <div className="settings-content">{tabContent(selectedTab)}</div>
      </div>
    </Layout>
  );
};

export default Settings;
