import { createSlice } from "@reduxjs/toolkit"
import jobsService from "../../services/JobsService"

const jobsSlice = createSlice({
	name: 'jobs',
	initialState: {
		loading: true,
		jobs: [],
	},
	reducers: {
		jobsLoading(state) {
			return { ...state, loading: true }
		},
		jobsReceived(state, action) {
			if (state.loading) {
				return {
					loading: false,
					jobs: action.payload
				}
			}
		},
	},
})

const fetchJobs = () => async (dispatch) => {
	dispatch(jobsLoading())
	const response = await jobsService.getJobs()
	const action = jobsReceived(response.jobs)
	dispatch(action)
}
const favoriteJobsSelector = state =>
	({
		...state.jobs,
		jobs: state.jobs.jobs.filter(x => Boolean(x.favorite))
	})

export const { reducer: jobsReducer } = jobsSlice
export const { jobsLoading, jobsReceived } = jobsSlice.actions
export { fetchJobs, favoriteJobsSelector }
