/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useRef } from 'react';
import './location_search.scss';
import { ReactComponent as LocationIcon } from "../../components/icons/location.svg";

const LocationSearchRef = React.forwardRef(({ search, setLocations }, ref) => {
  const location = search.location;

  const addLocations = useCallback((locationObject) => {
    const alreadyAddedLocation = Boolean(location.find(x => 
      x.address_attributes.latitude === locationObject.geometry.location.lat()
      && x.address_attributes.longitude === locationObject.geometry.location.lng()
    ))

    if (!alreadyAddedLocation) {
      const city =
        locationObject.address_components.find((x) =>
          x.types.includes('locality')
        ) || {};
      const state =
        locationObject.address_components.find((x) =>
          x.types.includes('administrative_area_level_1')
        ) || {};
      const country = locationObject.address_components.find((x) =>
        x.types.includes('country')
      );
      location.push({
        address_attributes: {
          latitude: locationObject.geometry.location.lat(),
          longitude: locationObject.geometry.location.lng(),
          city: city.long_name,
          state: state.long_name,
          country: country.long_name,
          country_code: country.short_name,
          formatted_address: locationObject.formatted_address,
        },
      });
      setLocations(location);
    }
    ref.current.value = '';
  }, [search.location]);

  function initMap() {
    if (window?.google?.maps) {
      const autoComplete = new window.google.maps.places.Autocomplete(
        ref.current,
        {
          types: ['(cities)']
        }
      );
      autoComplete.addListener('place_changed', () => {
        const place = autoComplete.getPlace();
        if (place.geometry) {
          addLocations(place);
        }
      });
    }
  }

  useEffect(() => {
    initMap();
  }, []);

  const removeLocation = (locationObject) => {
    let locations = search.location;
    const location = locationObject.address_attributes;
    for (let i = 0; i < locations.length; i++) {
      if (
        location.latitude === locations[i].address_attributes.latitude &&
        location.longitude === locations[i].address_attributes.longitude
      ) {
        locations.splice(i, 1);
      }
    }
    setLocations(locations);
  };

  return (
    <div className="location-search input-container">
      <div className="input-container-inner">
        <input
          id="location"
          className="location"
          type="text"
          placeholder="Where"
          ref={ref}
        />
        <LocationIcon />
      </div>
      <div className="selected-locations">
        {search.location &&
          search.location.map((location, index) => {
            return (
              <div
                className="selected-location"
                key={index}
                onClick={() => removeLocation(location)}
              >
                {location.address_attributes.formatted_address}
                  <LocationIcon />
              </div>
            );
          })}
      </div>
    </div>
  );
});

const LocationSearch = ({ search, setLocations }) => {
  const location = search.location;
  const ref = useRef(null)

  const addLocations = useCallback((locationObject) => {
    const alreadyAddedLocation = Boolean(location.find(x => 
      x.address_attributes.latitude === locationObject.geometry.location.lat()
      && x.address_attributes.longitude === locationObject.geometry.location.lng()
    ))

    if (!alreadyAddedLocation) {
      const city =
        locationObject.address_components.find((x) =>
          x.types.includes('locality')
        ) || {};
      const state =
        locationObject.address_components.find((x) =>
          x.types.includes('administrative_area_level_1')
        ) || {};
      const country = locationObject.address_components.find((x) =>
        x.types.includes('country')
      );
      location.push({
        address_attributes: {
          latitude: locationObject.geometry.location.lat(),
          longitude: locationObject.geometry.location.lng(),
          city: city.long_name,
          state: state.long_name,
          country: country.long_name,
          country_code: country.short_name,
          formatted_address: locationObject.formatted_address,
        },
      });
      setLocations(location);
    }
    ref.current.value = '';
  }, [search.location]);

  function initMap() {
    if (window?.google?.maps) {
      const autoComplete = new window.google.maps.places.Autocomplete(
        ref.current,
        {
          types: ['(cities)']
        }
      );
      autoComplete.addListener('place_changed', () => {
        const place = autoComplete.getPlace();
        if (place.geometry) {
          addLocations(place);
        }
      });
    }
  }

  useEffect(() => {
    initMap();
  }, []);

  const removeLocation = (locationObject) => {
    let locations = search.location;
    const location = locationObject.address_attributes;
    for (let i = 0; i < locations.length; i++) {
      if (
        location.latitude === locations[i].address_attributes.latitude &&
        location.longitude === locations[i].address_attributes.longitude
      ) {
        locations.splice(i, 1);
      }
    }
    setLocations(locations);
  };

  return (
    <div className="location-search input-container">
      <label className="input-label" htmlFor="location">
        Select Location
      </label>
      <div className="input-container-inner">
        <input
          id="location"
          className="location"
          type="text"
          placeholder="Where"
          ref={ref}
        />
        <LocationIcon />
      </div>
      <div className="selected-locations">
        {search.location &&
          search.location.map((location, index) => {
            return (
              <div
                className="selected-location"
                key={index}
                onClick={() => removeLocation(location)}
              >
                {location.address_attributes.formatted_address}
                <LocationIcon />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export { LocationSearch, LocationSearchRef };
