/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import $ from "jquery";
import JobsSearch from "../legacy/components/JobsSearch";
import Layout from "../legacy/components/Layout";
import NoResultsFound from "../legacy/components/NoResultsFound";
import PageBanner from "../legacy/components/PageBanner";
import JobCard from "../components/cards/JobCard";
import JobDetails from "../legacy/components/JobDetails";
import JobsService from "../services/JobsService";
import ApplyToJobModal from "../components/modals/ApplyToJobModal";
import Alert from "../components/Alert";
import useAlert from "../hooks/useAlert";
import TimezonesService from "../services/TimezonesService";
import Format from "../legacy/components/utils/Format";
import useInfiniteScrollPagination from "../hooks/useInfiniteScrollPagination";
import { defaultCards } from "../utils/defaults";
import "../legacy/stylesheets/jobs.scss";
import "../legacy/stylesheets/jobs_section.scss";
import ScrollToTopButton from "../components/buttons/ScrollToTopButton";

const ALLOWED_URL_PARAMS = [
  "what",
  "location",
  "level",
  "on-site-remote",
  "job-type",
  "stack",
  "page",
];

const Jobs = () => {
  const navigate = useNavigate();
  const windowWidth = window.innerWidth;

  const { jobs: storeJobs, loading: loadingJobs } = useSelector(
    (state) => state.jobs
  );

  const [numOfFiltersSet, setNumOfFiltersSet] = useState(0);
  const [selectedJob, setSelectedJob] = useState({});
  const [jobsList, setJobsList] = useState(defaultCards(storeJobs));
  const [timeZoneList, setTimeZoneList] = useState({});
  const [isMobile, setIsMobile] = useState(windowWidth <= 930);
  const [modalOpen, setModalOpen] = useState(false);
  const [search, setSearch] = useState({ term: "" });
  const [filters, setFilters] = useState({
    jobType: [],
    level: [],
    location: [],
    onsiteRemote: [],
    stack: [],
  });
  const [searchResultsHeight, setSearchResultsHeight] = useState(isMobile ? 'auto' : null);

  const [searchParams] = useSearchParams();

  const searchTermRef = useRef();
  const searchLocationRef = useRef();
  const objectRef = useRef();
  const jobDetailsRef = useRef();

  const { page: currentPage, setNextPage, resetPage: resetPageNumber } = useInfiniteScrollPagination(
    objectRef,
    () => {
      fetchJobs({
        event: null,
        page: currentPage,
        query: search.term,
        filterObject: filters,
        keepOld: true
      });
    }
  );

  const format = new Format();

  const jobsContainer = useRef();

  // For setting the url parameters
  const updateUrlParams = (paramName, paramValues) => {
    const urlParams = new URLSearchParams(window.location.search);
    let newUrlParamsString = "";

    // Rearrange the Parameter Loop
    for (let i of urlParams.keys()) {
        if (i === paramName) continue; // Exclude the parameter being set/updated
        if (i === "what" && urlParams.get(i) === "") continue; // Exclude "what" if its value is empty
        if (ALLOWED_URL_PARAMS.indexOf(i) > -1) {
            newUrlParamsString += `${i}=${urlParams.get(i)}&`;
        }
    }

    // Add/Update New Parameter
    if (paramValues.length > 0 && !(paramName === "what" && paramValues[0] === "")) {
        if (paramName === "location") {
            let locationsParamString = "";
            for (let i = 0; i < paramValues.length; i++) {
                if (i > 0) {
                    locationsParamString += "~";
                }
                locationsParamString +=
                    `${paramValues[i].address_attributes.city}^` +
                    `${paramValues[i].address_attributes.latitude}^` +
                    `${paramValues[i].address_attributes.longitude}^` +
                    `${paramValues[i].address_attributes.formatted_address}`;
            }
            newUrlParamsString += `location=${locationsParamString}`;
        } else {
            newUrlParamsString += `${paramName}=${paramValues.join(",")}`;
        }
    }

    // Trim the '&' and Add '?'
    if (newUrlParamsString.endsWith('&')) {
        newUrlParamsString = newUrlParamsString.slice(0, -1);
    }
    if (newUrlParamsString) {
        newUrlParamsString = "?" + newUrlParamsString;
    }

    // Navigate
    newUrlParamsString === ""
        ? navigate(window.location.pathname, { replace: true })
        : navigate(newUrlParamsString, { replace: true });
  };

  const setFilter = (filterName, filterValue) => {
    resetPage();
    let camelCaseFilterName =
      filterName === "on-site-remote"
        ? "onsiteRemote"
        : format.toCamelCase(filterName);

    setFilters((prevState) => ({
      ...prevState,
      [camelCaseFilterName]: filterValue,
    }));
    updateUrlParams(filterName, filterValue);
  };

  const setLocations = (locations) => {
    setFilters((prevState) => ({
          ...prevState,
          location: locations,
        }));
    updateUrlParams("location", locations);
  }

  // For updating the filters based on the url parameters
  const parseUrlParamsAndSetFilters = () => {
    const urlParams = new URLSearchParams(window.location.search);
    for (let i of urlParams.keys()) {
      if (ALLOWED_URL_PARAMS.indexOf(i) > -1) {
        if (i === "location") {
          let locations = urlParams.get(i).split("~");
          let locationsObjectsArray = [];
          for (let i = 0; i < locations.length; i++) {
            let locationPropertiesArray = locations[i].split("^");
            locationsObjectsArray.push({
              address_attributes: {
                city: locationPropertiesArray[0],
                latitude: locationPropertiesArray[1],
                longitude: locationPropertiesArray[2],
                formatted_address: locationPropertiesArray[3],
              },
            });
          }
          setLocations(locationsObjectsArray);
        } else {
          setFilter(i, urlParams.get(i).split(","));
        }
      }
    }
  };

  const resetPage = () => {
    $(".search-results").scrollTop(0);
    resetPageNumber();
  };

  const resetJobs = () => {
    setJobsList(storeJobs);
    setSelectedJob(isMobile ? {} : storeJobs[0]);
  };

  const resetFilters = () => {
    setFilters({
      level: [],
      onsiteRemote: [],
      jobType: [],
      stack: [],
    });
    setSearch({
      term: "",
      location: [],
    });
    setLocations([]);
    searchTermRef.current.value = "";
    searchLocationRef.current.value = "";
    setNumOfFiltersSet(0);
    resetPage();
    resetJobs();

    window.history.replaceState("", "", window.location.pathname);
  };

  const setSearchTerm = (searchTerm) => {
    resetPage();

    setSearch((prevState) => ({
      ...prevState,
      term: searchTerm,
    }));

    updateUrlParams("what", [searchTerm]);
  };

  const fetchJobs = ({ event, page, query, filterObject, keepOld }) => {
    event?.preventDefault?.();

    const filterArray = [];

    if (filterObject?.location?.length) {
      const coordinates = filterObject.location
        .map(
          (item) =>
            `${item.address_attributes.latitude},${item.address_attributes.longitude}`
        )
        .join("|");
      filterArray.push(`&preferred_coordinates=${coordinates}`);
    }

    if (filterObject?.stack?.length) {
      filterArray.push(`&stack_list=${filterObject.stack.join(",")}`);
    }
    if (filterObject?.jobType?.length) {
      filterArray.push(`&time_status=${filterObject.jobType.join(",")}`);
    }
    if (filterObject?.level?.length) {
      filterArray.push(`&experience_list=${filterObject.level.join(",")}`);
    }
    if (filterObject?.onsiteRemote?.length) {
      filterArray.push(`&remote=${filterObject.onsiteRemote.join(",")}`);
    }

    let payload = filterArray?.length
      ? `${filterArray.join("")}&page=${page}`
      : `page=${page}`;

    if (query) {
      payload = `query=${query}&${payload}`;
    }

    const fetchFromService = (isSearch) => {
      const serviceMethod = isSearch
        ? JobsService.searchJobs
        : JobsService.getJobs;

      serviceMethod(payload).then(({ jobs, ...data }) => {
        setJobsList((prevJobs) => {
          if (keepOld) {
            return [...prevJobs, ...jobs];
          } else {
            return [...jobs];
          }
        });
        setNextPage(data?.meta?.next_page);
        if (!keepOld) {
          setSelectedJob(isMobile ? {} : jobs[0]);
        }
      });
    };

    if (query || filterArray.length > 0) {
      // If there is a search query or filters we use the search endpoint
      fetchFromService(true);
    } else {
      // else we use the default jobs endpoint
      fetchFromService(false);
    }
  };

  const applyToJob = (e) => {
    e.preventDefault();
    setModalOpen(true);
  };

  const { showAlert, alert } = useAlert();

  const fetchTimeZones = () => {
    TimezonesService.getTimezones().then((timezones) => {
      setTimeZoneList(timezones.list);
    });
  };

  const setSelectedByUrl = useCallback((list) => {
    const jobId = parseInt(searchParams.get("id"));
    const selected = list.find((job) => job?.id === jobId);
    if (!selected && jobId) {
      JobsService.getJobById(jobId).then((job) => {
        setJobsList((prevJobs) => [job, ...prevJobs]);
        setSelectedJob(job);
      })
    } else {
      setSelectedJob(isMobile ? (selected || {}) : (selected || list[0]));
    }

  }, [jobsList, searchParams]);

  const filtersCount = Object.values(filters).reduce(
    (acc, x) => acc + x.length,
    0
  );

  useEffect(() => {
    fetchTimeZones();
    resetPage();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      // Set the mobile status
      setIsMobile(window.innerWidth <= 930);
  
      // Adjust the search results height if not on mobile and if jobDetailsRef is available
      if (jobDetailsRef.current && window.innerWidth > 930) {
        const height = jobDetailsRef.current.clientHeight;
        setSearchResultsHeight(`${height}px`);
      }
    };
  
    // Call the function once to set the initial values
    handleResize();
  
    // Attach the event listener
    window.addEventListener("resize", handleResize);
  
    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [selectedJob, jobDetailsRef.current]);
  

  useEffect(() => {
    const whatQueryParam = searchParams.get('what');
    if (whatQueryParam) {
      setSearch({ term: whatQueryParam });
    }
  }, [])

  // Set store jobs if filters and search term are empty
  useEffect(() => {
    if (filtersCount ||  search.term || !storeJobs.length) return;
    setJobsList(storeJobs);
    setSelectedByUrl(storeJobs);
  }, [filters, search.term, storeJobs]);

  useEffect(() => {
    // Check if there are filters or search term, if not parse URL parameters
    if (!(filtersCount || search.term)) {
      parseUrlParamsAndSetFilters();
    }

    // Execute fetch if filters or search term are present
    if (filtersCount || search.term) {
      fetchJobs({
        event: null,
        page: 1,
        query: search.term,
        filterObject: filters,
        keepOld: false
      });
    }
  }, [filters, search.term]);

  if (isMobile && selectedJob.id) {
    return (
      <Layout loading={loadingJobs}>
        <div className="job-details-mobile">
          <JobDetails
            job={selectedJob}
            applyToJob={applyToJob}
            timeZoneList={timeZoneList}
            setSelectedJob={setSelectedJob}
            skeleton={loadingJobs}
          />
        </div>
      </Layout>
    )
  }

  return (
    <Layout loading={false}>
      <ApplyToJobModal
        close={() => setModalOpen(false)}
        open={modalOpen}
        selectedJob={selectedJob}
        showAlert={showAlert}
      />
      <Alert show={showAlert} {...alert}>
        {alert.type === "error"
          ? "An error occured. Please refresh your browser and try again."
          : "Application sent successfully."}
      </Alert>
      <div id="jobs-section">
          <PageBanner
            text="Women belong in all places where decisions are being made. It shouldn't be that women are the exception. "
            source="Ruth Bader Ginsburg"
            imageUrl="/assets/header-yellow.svg"
            skeleton={loadingJobs}
          />
          <JobsSearch
            fetchJobs={fetchJobs}
            filters={filters}
            numOfFiltersSet={numOfFiltersSet}
            ref={[searchTermRef, searchLocationRef]}
            resetFilters={resetFilters}
            search={search}
            setFilter={setFilter}
            setLocations={setLocations}
            setSearchTerm={setSearchTerm}
            skeleton={loadingJobs}
            windowWidth={windowWidth}
          />
          {jobsList?.length > 0 ? (
            <div className="jobs-content">
              <div 
                className="search-results" 
                ref={jobsContainer}
                style={isMobile ? {} : { height: searchResultsHeight }} 
              >
                {jobsList?.map((job, idx) => {
                  return (
                    <div
                      className="job-container"
                      key={`job-${job?.id}-${idx}`}
                      onClick={(event) => {
                        setSelectedJob(job);
                        event.preventDefault();
                        job?.id && navigate(`/jobs?id=${job?.id}`);
                      }}
                      style={{
                        display: !loadingJobs && (!job?.id || job?.status === "expired")? "none" : "block",
                      }}
                    >
                      <JobCard
                        job={job}
                        selected={
                          selectedJob?.id && selectedJob.id === job?.id
                        }
                        timeZoneList={timeZoneList}
                      />
                    </div>
                  );
                })}
                <ScrollToTopButton hide={false} />
                <div id="visor" ref={objectRef}></div>
              </div>
              {windowWidth > 930 && selectedJob && (
                <JobDetails
                  job={selectedJob}
                  timeZoneList={timeZoneList}
                  apply={applyToJob}
                  skeleton={loadingJobs}
                  ref={jobDetailsRef}
                />
              )}
            </div>
          ) : (
            <NoResultsFound resetFilters={resetFilters} />
          )}
      </div>
    </Layout>
  );
};

export default Jobs;
