const QuestionHeader = ({ children, question }) => {
  return (
    <div className="question-block">
      <h5 className="text-header">{question}</h5>
      <div style={{ marginBottom: 20 }} />
      {children}
    </div>
  );
};

export default QuestionHeader;