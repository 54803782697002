import { createSlice } from "@reduxjs/toolkit"
import resourcesService from '../../services/ResourcesService'

const resourcesSlice = createSlice({
	name: 'resources',
	initialState: {
		loading: true,
		resources: [],
	},
	reducers: {
		resourcesLoading(state) {
			return { ...state, loading: true }
		},
		resourcesReceived(state, action) {
			if (state.loading) {
				return {
					loading: false,
					resources: action.payload
				}
			}
		},
	},
})

const fetchResources = () => async (dispatch) => {
	dispatch(resourcesLoading())
	const response = await resourcesService.getResources()
	const action = resourcesReceived(response.resources)
	dispatch(action)
}

const favoriteResourcesSelector = state =>
	({
		...state.resources,
		resources: state.resources.resources.filter(x => Boolean(x.favorite))
	})

export const { reducer: resourcesReducer } = resourcesSlice
export const { resourcesLoading, resourcesReceived  } = resourcesSlice.actions
export { fetchResources, favoriteResourcesSelector }
