import { BaseService } from "./BaseService";

const ResourcesService =  {
	getResources: async(payload) => {
		let url = 'resources';
		if (payload) url = `resources?${payload}`;
		try {
      const data = await BaseService.get(url);
      return { resources: data.resources, ...data };
    } catch (e) {
      console.error(e);
      return { resources: [] };
    }
	},
	searchResources: async(payload) => {
		try {
			const data = await BaseService.get(`resources/search?${payload}`)
			return { resources: data.resources, ...data };
		} catch(e) {
			console.error(e)
			return { resources: [] };
		}
	}

}

export default ResourcesService;
