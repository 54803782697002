import { BaseService } from "./BaseService"

const SuggestionsService =  {
	getSuggestions: async(value) => {
		try {
			const data = await BaseService.get(`suggestions?search_type=${value}`)
			return data
		} catch(e) {
			console.error(e)
		}
	},
}

export default SuggestionsService
