const FavoritesButton = (props) => {
  const { text, link, cardStyles } = props;
  return (
    <a
      style={{ width: "100%", display: "flex", justifyContent: "center" }}
      target={link?.includes("http") ? "_blank" : "_self"}
      href={link}
      rel="noreferrer"
    >
      <button className={cardStyles ? "card-button" : "dash-button"}>
        {text}
      </button>
    </a>
  );
};

export default FavoritesButton;
