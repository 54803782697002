// Construct an array of objects with custom property and default values.
// Elements with an empty id ("") are considered new.
export const buildDestroyableElementsArray = (newValues, oldValues, prop) => {
  return newValues.map(
    (x) =>
      oldValues.find((y) => y[prop] === x) ?? {
        id: "",
        [prop]: x,
      }
  );
};

// Mark elements with an id for destruction if they're not present in the toSave array.
// This should be executed once the user submits the form.
export const markDestroyableElements = (saved, toSave) => {
  const all = [...toSave, ...saved];
  return all.reduce((acc, x) => {
    const idx = acc.findIndex((l) => x.id && l.id === x.id);
    const duplicated = idx !== -1;
    if (duplicated) acc[idx]["_destroy"] = false;
    else acc.push({ ...x, _destroy: Boolean(x.id) });
    return acc;
  }, []);
};
