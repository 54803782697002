import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../components/Alert";
import Layout from "../legacy/components/Layout";
import useAlert from "../hooks/useAlert";
import usePagination from "../hooks/usePagination";
import useWindowSize from "../hooks/useWindowSize";
import { fetchApplaudHers } from "../app/slices/applaudhersSlice";
import "../styles/applaudher.scss";

const BACKGROUND_COLORS = [
  "#FB5714",
  "#3045BF",
  "#FB5714",
  "#BB27A8",
  "#BB27A8",
  "#007A7C",
];

const BACKGROUND_IMAGES = [
  "blue-orange.webp",
  "green-blue.webp",
  "orange-teal.webp",
  "purple-orange.webp",
  "teal-purple.webp",
  "yellow-teal.webp"
];

const ITEMS_PER_PAGE = 9;

const Banner = () => {
  const navigate = useNavigate();

  return (
    <div className="applaudher-banner-container">
      <div className="hero-container">
        <div className="img-container">
          <img src="/assets/applaudher/applaudher-hero.webp" alt="applaudher-hero" />
        </div>
      </div>
      <div className="banner-text-container">
        <h1><i>#ApplaudHer</i></h1>
        <p>
          Women Who Code highlights the achievements of our members by celebrating them with Applaud Her.
        </p>
        <button onClick={() => navigate('/applaudher/new')}>
          SUBMIT #APPLAUDHER
        </button>
      </div>
    </div>
  )
};

const LoadingSpinner = () => (
  <div className="loading-container">
    <img src="/images/Loader.gif" alt="loading-spinner" />
  </div>
);

const ApplaudHerCard = ({ applaud, index }) => {
  const [hovering, setHovering] = useState(false);
  const backgroundImage = `/assets/applaudher/${BACKGROUND_IMAGES[index % BACKGROUND_IMAGES.length]}`;
  const backgroundColor = BACKGROUND_COLORS[index % BACKGROUND_COLORS.length];

  return (
    <div
      className="applaudher-card"
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="text-background" style={{ background: backgroundColor }}>
        {hovering ? (
          <div>
            <h2><i>{applaud.name}</i></h2>
            <p className="hover-description">{`${applaud.name} of ${applaud.network_title} ${applaud.description}`}</p>
          </div>
        ) : (
          <div>
            <h1><i>#applaudher</i></h1>
            <p>{`${applaud.name} of ${applaud.network_title} ${applaud.description}`}</p>
          </div>
        )}
      </div>
    </div>
  )
};

const ApplaudHer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const windowSize = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();
  const { applaudHers, loading, meta } = useSelector((state) => state.applaudHers);
  const { showAlert, alert } = useAlert();
  const [alertMessage, setAlertMessage] = useState('');
  const [siblingCount, setSiblingCount] = useState(1);

  useEffect(() => {
    if (windowSize.width >= 1200) {
      setSiblingCount(4);
    } else if (windowSize.width > 1024) {
      setSiblingCount(3);
    } else if (windowSize.width > 860) {
      setSiblingCount(2);
    } else {
      setSiblingCount(1);
    }
  }, [windowSize.width]);

  const pageFromURL = parseInt(searchParams.get('page')) || 1;
  const totalPages = meta.total_result_count ? Math.ceil(meta.total_result_count / ITEMS_PER_PAGE) : 0;
  const paginationRange = usePagination({
    totalCount: totalPages,
    siblingCount: siblingCount,
    currentPage: pageFromURL,
  });

  useEffect(() => {
    dispatch(fetchApplaudHers(`page=${pageFromURL}`));
  }, [dispatch, pageFromURL]);

  useEffect(() => {
    if (location.state?.submitted) {
      showAlert('success', 3500);
      setAlertMessage("Your Applaud has been submitted for review.");
      location.state.submitted = false;
    }
  }, [location, showAlert]);


  const paginate = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    
    setSearchParams({ page: pageNumber.toString() });
    dispatch(fetchApplaudHers(`page=${pageNumber}`));
  };

  const paginationItems = paginationRange.map((item, index) => {
    if (item === '...') {
      return <span key={`ellipsis-${index}`} className="ellipsis">...</span>;
    } else {
      return (
        <button
          key={item}
          onClick={() => paginate(item)}
          disabled={loading || item === pageFromURL}
          className={item === pageFromURL ? 'active' : ''}
        >
          {item}
        </button>
      );
    }
  });

  return (
    <Layout>
      <Alert {...alert}>
        {alertMessage}
      </Alert>
      <Banner />
      <div className="applaudher-content-container">
        {loading ? <LoadingSpinner /> : (
          <div className="applaudhers">
            {applaudHers.map((applaud, index) => (
              <ApplaudHerCard
                applaud={applaud}
                key={applaud.id}
                index={index}
              />
            ))}
          </div>
        )}
        {totalPages > 1 && (
          <div className="pagination">
            <button onClick={() => paginate(pageFromURL - 1)} disabled={pageFromURL === 1 || loading}>
              {"<"}
            </button>
            {paginationItems}
            <button onClick={() => paginate(pageFromURL + 1)} disabled={pageFromURL === totalPages || loading}>
              {">"}
            </button>
          </div>
        )}
      </div>
    </Layout>
  )
};

export default ApplaudHer;