import { useState } from "react";
import { useSelector } from "react-redux";
import Format from "../../legacy/components/utils/Format";
import Favorite from "../../legacy/components/Favorite";
import Skeleton from "../Skeleton";
import "./JobCard.scss";
import { Link } from "react-router-dom";
import { Tooltip } from "../../components/Tooltip";

const JobCard = ({
  job,
  selected,
  width,
  timeZoneList,
}) => {
  const [showBenefitsTooltip, setShowBenefitsTooltip] = useState(false);
  const {
    logo_url,
    company_name,
    id,
    salary,
    currency,
    title,
    salary_period,
    benefits,
    experience_list,
    stack_list,
    addresses,
    time_status,
    remote,
    remote_timezone,
  } = job ?? {};

  const favorites = useSelector((state) => state.favorites.jobs) || [];
  const isFavorite = favorites.some((favoriteJob) => favoriteJob?.id === id);

  const format = new Format();
  const toggleShowBenefitsTooltip = () => {
    setShowBenefitsTooltip(!showBenefitsTooltip);
  };

  const formatStackList = (stack_list) => {
    const stackListCapitalize = format.capitalizeFirstLetter(stack_list, " | ");
    return format.truncate(stackListCapitalize, 36);
  };

  return (
    <Link
      to={job?.id ? `/jobs?id=${job?.id}` : ""}
      className={"job-card " + (selected ? "selected" : "")}
      id={`job-${job?.id}`}
      style={width && {
        display: 'inline-block',
        width: width,
        maxHeight: 335,
      }}
    >
      <div
        className={
          "job " + (selected ? "selected " : "")
        }
      >
        <div className="top-row">
          {job ? (
            <img
              className="image"
              alt="Company Icon"
              src={logo_url ? logo_url : "/assets/jobs/default-icon.svg"}
            />
          ) : (
            <Skeleton className="image" radius={100} />
          )}
          <div className="middle-section">
            {job ? (
              <>
                <div className="company">{company_name}</div>
                {addresses?.length > 0 && (
                  <div className="location" style={{ whiteSpace: "pre-wrap" }}>
                    {addresses[0].city && `${addresses[0].city}, `}
                    {addresses[0].state && `${addresses[0].state}, `}
                    {addresses[0].country}
                  </div>
                )}
              </>
            ) : (
              <>
                <Skeleton className="company" w={62} h={15} mb={7} rounded />
                <Skeleton className="location" w={47} h={15} rounded />
              </>
            )}
          </div>
          <Favorite type="jobs" item={{ ...job }} favorite={isFavorite} />
        </div>
        {id ? (
          <>
            <div className="title">{title}</div>
              <div className="salary">
                {!!salary && (
                  `${salary} ${currency} ${format.removeUnderscrore(salary_period)}`
                )}
              </div>
            <div className="job-type">
              {format.capitalizeFirstLetter(time_status, " ")}
              {time_status && remote && ", "}
              {remote && !remote_timezone && "Remote"}
              {remote &&
                remote_timezone &&
                remote_timezone !== "" &&
                ` Remote in ${format.abbrTimezone(
                  remote_timezone,
                  timeZoneList
                )}`}
              {remote && format.displayTimezone(remote_timezone)}
            </div>
            <div className="level">
              {experience_list && (
                <div>
                  Level: {format.capitalizeFirstLetter(experience_list, ", ")}
                </div>
              )}
            </div>
            <div className="level">
              {stack_list?.length > 0 && (
                <div>{formatStackList(stack_list)}</div>
              )}
            </div>
          </>
        ) : (
          <>
            <Skeleton className="title" w={225} h={20} mb={28} rounded />
            <Skeleton className="salary" w={225} h={20} mb={12} rounded />
            <Skeleton className="job-type" w={60} h={13} mb={9} rounded />
            <Skeleton className="level" w={84} h={13} mb={7} rounded />
            <Skeleton className="level" w={178} h={13} mb={21} rounded />
          </>
        )}
        <div className="container-benefits">
          {benefits?.length > 0 && (
            <ul className="benefits">
              {benefits.slice(0, 6).map((benefit, index) => {
                const title = benefit.name
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ");

                return (
                  <Tooltip key={`job-card-tooltip-${index}`} content={title}>
                    <li className="benefit" key={index}>
                      <div
                        className="benefit-icon"
                        dangerouslySetInnerHTML={{ __html: benefit.icon_xml }}
                      />
                    </li>
                  </Tooltip>
                );
              })}
              {benefits.length > 6 && (
                <li className="benefit-extras">(+{benefits.length - 6})</li>
              )}
              {showBenefitsTooltip && selected && (
                <div className="benefits-tooltip">
                  You can view the benefits for this job by selecting it and
                  scrolling to the bottom of the job details section.
                  <button type="button" onClick={toggleShowBenefitsTooltip}>
                    GOT IT!
                  </button>
                </div>
              )}
            </ul>
          )}
          {!job && (
            <ul className="benefits center">
              {[1, 2, 3, 4, 5, 6, 7].map((x) => (
                <Skeleton
                  className="benefit"
                  w={20}
                  h={20}
                  radius={8}
                  key={x}
                />
              ))}
            </ul>
          )}
        </div>
      </div>
    </Link>
  );
};

export default JobCard;
