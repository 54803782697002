import React from 'react';
import { useSelector } from 'react-redux';
import Favorite from './Favorite';
import './resource.scss';
import Skeleton from '../../components/Skeleton';

const Resource = ({ resource, setYoutubeUrl }) => {
  const {
    title,
    url,
    image_url,
    format_list,
  } = resource ??  {};

  const favorites = useSelector(state => state.favorites.resources) || [];
  const isFavorite = favorites.some(favoriteResource => favoriteResource?.id === resource?.id);

  return (
    <div className="resource">
      <div className="top">
        {format_list?.[0] === 'Video' && (
          <div className="youtube-iframe-container">
            <iframe
              src={url}
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Youtube Iframe"
            />
            <div
              className="youtube-iframe-overlay"
              onClick={() => setYoutubeUrl(url)}
            />
          </div>
        )}
        {format_list?.[0] !== 'Video' && (
          <a href={url} target="_blank" rel="noreferrer">
            {resource
              ? <img
                  alt="Article"
                  src={image_url || '/images/defaults/resource-thumbnail.jpg'}
                />
              : <Skeleton w="100%" h={200} radius="8px 8px 0 0" />
            }
          </a>
        )}
      </div>
      <div className="bottom">
        {resource
          ? <Favorite type="resources" item={{...resource}} favorite={isFavorite} />
          : <Skeleton className="favorite" w={40} h={40} radius={8} />
        }
        {resource
          ? <div className="name">{title}</div>
          : <Skeleton className="name" rounded w={244} h={40} />
        }
      </div>
    </div>
  );
};

export default Resource;
