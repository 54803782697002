import { FormWithCheckboxes } from "../../../legacy/components/NewOnboarding";

const Step2 = ({ jobList, handleCheckBoxes, isChecked }) => jobList?.length ?(
  <div className="checkgroup-container">
    <div className="checkgroup">
      {jobList.slice(0, 4).map((jobSearch) => {
        return (
          <FormWithCheckboxes
            key={jobSearch}
            label={jobSearch}
            name="job_search"
            onChange={(checked, value) => {
              handleCheckBoxes(checked, value, "job_search");
            }}
            checked={isChecked("job_search", jobSearch)}
          />
        );
      })}
    </div>
    <div className="checkgroup">
      {jobList.slice(4).map((jobSearch) => {
        return (
          <FormWithCheckboxes
            key={jobSearch}
            label={jobSearch}
            name="job_search"
            onChange={(checked, value) => {
              handleCheckBoxes(checked, value, "job_search");
            }}
            checked={isChecked("job_search", jobSearch)}
          />
        );
      })}
    </div>
  </div>
) : null;

export default Step2;
