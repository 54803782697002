import React, { useState } from "react";
import useDropdown from "../../hooks/useDropdown";

function FormWithDropdown({ options, setOption, values, property }) {
  const [dropdownText, setDropdownText] = useState("Please choose an option");
  const { isOpen: openList, toggleDropdown, ref } = useDropdown();

  React.useEffect(() => {
    if (values[property] !== "" && values[property] !== null) {
      setDropdownText(values[property]);
    }
  }, [property, values]);

  const renderOptions = (options) => {
    return options.map((option) => (
      <p
        className="list-item"
        onClick={() => {
          setDropdownText(option);
          setOption({ ...values, [property]: option });
        }}
        key={option}
      >
        {option}
      </p>
    ));
  };
  return (
    <div className="dropdown-container" onClick={toggleDropdown} ref={ref}>
      <div className="dropdown">
        <p className="dropdown-text">{dropdownText}</p>
      </div>
      {openList && <div className="drop-list">{renderOptions(options)}</div>}
    </div>
  );
}

export default FormWithDropdown;
