import RadioButtons from "../../buttons/RadioButtons";
import { ReactComponent as GlobeAmericasIcon } from "../../icons/globe-americas.svg";
import { ReactComponent as BuildingOffice2Icon } from "../../icons/building-office-2.svg";
import { ReactComponent as ShuffleIcon } from "../../icons/shuffle.svg";

const Step15 = ({ values, setValues }) => {
  const options = [
    {
      text: "Remote",
      Icon: GlobeAmericasIcon,
    },
    {
      text: "In Office",
      Icon: BuildingOffice2Icon,
    },
    {
      text: "Hybrid",
      Icon: ShuffleIcon,
    }
  ];

  const selectedValues = values.work_location;
  const handleChange = (newValues) => {
    setValues((state) => ({ ...state, work_location: newValues }));
  };

  return (
    <RadioButtons
      options={options}
      selectedValues={selectedValues}
      onChange={handleChange}
      multiple
    />
  );
};

export default Step15;
