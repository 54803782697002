import useOnboarding from "../../../hooks/useOnboarding";
import Button from "../../Button";
import Title, { H4 } from "../../Title";
import "./OnboardingModal.scss";
import ProgressBar from "./ProgressBar";
import titles from "./titles";

import { steps } from "./steps";
import { useEffect } from "react";

const OnboardingModal = ({ isOpen, communities, ...props }) => {
  const onboarding = useOnboarding({ communities });
  const {
    step,
    completeOnboarding,
    setOnBoardingCompleted,
    onBoardingCompleted,
    goToPage,
  } = onboarding;
  const isLastStep = step === titles.length - 1;

  // Enable/disable scrolling when onboarding is open
  useEffect(() => {
    document.body.style.overflow = (isOpen && !onBoardingCompleted) ? 'hidden' : 'unset';
    return () => { document.body.style.overflow = 'unset'; }
  }, [isOpen, onBoardingCompleted])

  if (!isOpen || onBoardingCompleted) return null;

  const Step = steps[step];

  return (
    <div className="OnboardingOverlay">
      <div className="OnboardingOverlay__inner">
        <div className="OnboardingOverlay__content">
          <div className="OnboardingModal">
            {step === 0 && (
              <div className="OnboardingModal__ImageContainer"></div>
            )}
            {step === 0 ? (
              <div>
                <section className="OnboardingModal__Content OnboardingModal__Content--no-overflow">
                  <Step {...onboarding} titles={titles} />
                </section>
              </div>
            ) : (
              <>
                <section className="OnboardingModal__Content">
                  {isLastStep ? (
                    <Title>{titles[step]}</Title>
                  ) : (
                    <>
                    <p className="current-step">
                      Step {step} of {titles.length - 1}
                    </p>
                    <H4>{titles[step]}</H4>
                    </>
                  )}
                  <div
                    className={`${
                      [4, 12].includes(step)
                        ? "OnboardingModal__Content--overflow-unset"
                        : [6, 8, 9, 17].includes(step)
                          ? "OnboardingModal__Content--no-overflow"
                          : "OnboardingModal__Content--overflow-y"
                    }`}
                  >
                    <Step {...onboarding} {...props} />
                  </div>

                  {isLastStep ? (
                    <Button
                      onClick={() => {
                        setOnBoardingCompleted(true);
                        completeOnboarding(onboarding.values);
                      }}
                    >
                      GOT IT
                    </Button>
                  ) : (
                    <>
                      <div className="OnboardingModal__Buttons">
                        <Button outline onClick={() => goToPage(step - 1)}>
                          Back
                        </Button>
                        <Button onClick={() => goToPage(step + 1)}>Next</Button>
                      </div>
                      <ProgressBar
                        step={step}
                        goToPage={goToPage}
                        steps={titles.length - 1}
                      />
                    </>
                  )}
                </section>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingModal;
