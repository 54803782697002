import React from 'react';
import { useEffect } from "react"
import { useAuth } from '../auth/useAuth';
import LoggedOutTopBar from '../legacy/components/LoggedOutTopBar';
import '../legacy/stylesheets/donate.scss';

const Donate = () => {
  const { loggedIn } = useAuth();
  const donateUrl = process.env.REACT_APP_WORDPRESS_URL + "/donate";

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://downloads.mightycause.com/widgets/v1/embed.js';
    document.body.appendChild(script);

    window.onload = function () {
      window.MCForms.createEmbedForm({
        elementID: 'MC-donation-form',
        url: 'https://www.mightycause.com/forms/Womenwhocode?embed=embeddable_form',
      });
    };
  }, [])

  if (!loggedIn) {
    window.location.replace(donateUrl);
    return null;
  }

  return (
    <div className="new-experience-container">
      <LoggedOutTopBar removeLogin={false} user={null}/>
      <div id="donations-iframe">
        <div id="MC-donation-form"></div>
      </div>
    </div>
  );
}
export default Donate;
