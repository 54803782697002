import React, { useEffect, useState } from "react";
import ProfileService from "../../services/ProfileService";
import Button from "./Button";
import FilterOption from "./FilterOption";
import FormWithButtons from "./FormWithButtons";
import FormWithCheckboxes from "./FormWithCheckboxes";
import FormWithDropdown from "./FormWithDropdown";
import FormWithSearch from "./FormWithSearch";
import QuestionHeader from "./QuestionHeader";
import QuestionWithRadio from "./QuestionWithRadio";
import {
  getArrayDifference,
  updateObjectAttributes,
  yearDropDown,
  searchList,
} from "./utils/FormUtils";
import NetworksService from "../../services/NetworksService";

const CareerSettingsEdit = ({
  isEdit,
  setEdit,
  data,
  successFlash,
  errorFlash,
  screenWidth,
  profile,
  setProfile,
  fetchProfile,
}) => {
  const { job_list, why_join, networks_hash } = data;
  const communitiesList = networks_hash && Object.keys(networks_hash);
  const [communities, setCommunities] = useState(communitiesList);

  const [values, setValues] = useState(profile);

  useEffect(() => {
    getCommunities();
  }, []);

  const getCommunities = () => {
    NetworksService.getNetworks().then((networks) => {
      const communities = networks.map((network) => network.title);
      setCommunities(communities);
    });
  };

  const handleCheckBoxes = (checked, checkboxValue, property) => {
    const value = values[property] || [];
    const newValues = checked
      ? [...value, checkboxValue]
      : value.filter((item) => item !== checkboxValue);
    setValues({ ...values, [property]: newValues });
  };

  const handleRadio = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeConsent = (e) => {
    switch (e.target.value) {
      case "Yes":
        setValues({ ...values, [e.target.name]: true });
        return;
      case "No":
        setValues({ ...values, [e.target.name]: false });
        return;
      default:
        return;
    }
  };

  const consentValue = (value) => (value ? "Yes" : "No");

  // const getTagsFromRequest = (value, setSearch) => {
  //   TagsService.getStacks(value).then((data) => setSearch(data.list));
  // };

  const updateNetworks = (values) => {
    let newValues = { ...values };
    let newNetworkIds = values.network_titles.map( (network) => networks_hash[network]);
    let currentNetworkIds = Object.values(values.network_members_attributes)
      .filter(({ _destroy }) => !_destroy)
      .map( (n) => n.network_id);

    // get difference between both network_ids list
    let attrDifference = getArrayDifference(newNetworkIds, currentNetworkIds);

    // if currentNetworkIds contains difference, remove else add
    newValues.network_members_attributes = updateObjectAttributes(
      newValues.network_members_attributes,
      currentNetworkIds,
      attrDifference.at(-1),
      "network_id"
    );

    setValues(newValues);
  };

  return (
    <div className="settings-wrapper">
      <div className="flex-wrap">
        <QuestionHeader
          question={"How would you best describe your job search?"}
        >
          <div className="flex-wrap">
            <div style={{ display: "flex" }}>
              <div>
                {job_list?.slice(0, 4).map((jobSearch) => {
                  return (
                    <FormWithCheckboxes
                      key={jobSearch}
                      label={jobSearch}
                      name="job_search"
                      checked={
                        values.job_search
                          ? values.job_search.includes(jobSearch)
                          : false
                      }
                      onChange={(checked, value) => {
                        handleCheckBoxes(checked, value, "job_search");
                      }}
                    />
                  );
                })}
              </div>
              <div style={{ marginRight: 40 }} />
              <div>
                {job_list?.slice(4).map((jobSearch) => {
                  return (
                    <FormWithCheckboxes
                      key={jobSearch}
                      label={jobSearch}
                      name="job_search"
                      checked={
                        values.job_search
                          ? values.job_search.includes(jobSearch)
                          : false
                      }
                      onChange={(checked, value) => {
                        handleCheckBoxes(checked, value, "job_search");
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </QuestionHeader>
        <div style={{ marginRight: "10%" }} />
        <div className="question-block">
          <QuestionWithRadio
            question="Are you open to new career opportunities?"
            answers={["Yes", "No"]}
            name="career_opportunities"
            radioOnChange={handleRadio}
            value={values.career_opportunities || ""}
            noTopMargin={true}
            noWidth={""}
          />
          <p className="form-info-text">
            By clicking Yes, you consent to having your information shared with
            partner companies that are hiring. If you have a question, feel free
            to message us at{" "}
            <span style={{ color: "#E86431" }}>contact@womenwhocode.com</span>
          </p>
        </div>
      </div>
      <div className="flex-wrap" style={{ marginTop: 48 }}>
        <QuestionHeader question={"When did you get your first tech job?"}>
          <FormWithDropdown
            options={yearDropDown()}
            setOption={setValues}
            values={values}
            property="starter_year_in_tech"
          />
        </QuestionHeader>
        <div style={{ marginRight: "10%" }} />
        <QuestionHeader question={"Your Resume"}>
          <FormWithButtons
            noHeader={true}
            width={screenWidth < 860 ? "100%" : "fit-content"}
            buttons={["click to upload"]}
            values={values}
            setValues={setValues}
            property="resume_filename"
            fileBucket={data.filestack_bucket}
            fileKey={data.filestack_key}
            uploadFile={ProfileService.uploadFile}
          />
        </QuestionHeader>
      </div>
      <div className="flex-wrap" style={{ marginTop: 48 }}>
        <QuestionHeader
          question={"Which WWCode Communities would you like to join?"}
        >
          <FormWithSearch
            edit={isEdit}
            placeholder="Search"
            setSearch={(event) => {
              searchList(
                event,
                setCommunities,
                communitiesList,
                "network_titles",
                communities
              );
            }}
            filterComponent={
              <FilterOption
                options={communities}
                property="network_titles"
                selectedOption={values}
                setSelectedOption={updateNetworks}
              />
            }
          />
        </QuestionHeader>
        <div style={{ marginRight: "10%" }} />
        <div className="question-block">
          <QuestionWithRadio
            question="Would you like to receive the Career Nav edition of Code Review in your inbox?"
            answers={["Yes", "No"]}
            name="information_consented_at"
            radioOnChange={onChangeConsent}
            value={consentValue(values.information_consented_at)}
            noTopMargin={true}
            noWidth={true}
          />
          <p className="form-info-text">
            Career Nav contains great career tips, including changing roles,
            finding opportunities, salary negotiation, career mapping,
            demystifying the leadership ladder, scholarships, etc.
          </p>
        </div>
      </div>
      <div className="flex-wrap" style={{ marginTop: 48 }}>
        <QuestionHeader
          question={"What do you hope to gain from the community?"}
        >
          <div className="flex-wrap">
            <div>
              {why_join?.map((join) => (
                <FormWithCheckboxes
                  key={join}
                  label={join}
                  name="why_join_list"
                  checked={
                    values.why_join_list
                      ? values.why_join_list.includes(join)
                      : false
                  }
                  onChange={(checked, value) => {
                    handleCheckBoxes(checked, value, "why_join_list");
                  }}
                />
              ))}
            </div>
          </div>
        </QuestionHeader>
      </div>
      <div className="bottom-save-button">
        <Button
          icon="save"
          text="Apply Changes"
          className={"fill-button-profile"}
          onClick={() => {
            ProfileService.updateProfile(values)
              .then(() => {
                successFlash(true);
                setProfile(values);
                fetchProfile();
                setEdit(false);
              })
              .catch((err) => {
                errorFlash({
                  error: true,
                  message: ProfileService.errorMessages(err.status),
                });
                console.log("ERROR");
              });
          }}
        />
      </div>
    </div>
  );
};

export default CareerSettingsEdit;
