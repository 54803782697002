import Skeleton from "../../components/Skeleton";

const QuestionWithText = ({
  question,
  answer,
  noTopMargin,
  skeleton,
  noWidth,
}) => {
  return (
    <div style={{ marginTop: noTopMargin ? null : 48 }}>
      {skeleton ? (
        <Skeleton h={32} w={350} rounded={100} />
      ) : (
        <h5 className="text-header">{question}</h5>
      )}
      <div style={{ marginBottom: 32 }} />
      {skeleton ? (
        <Skeleton h={28} w={120} rounded={100} />
      ) : (
          <p className="text-answer">{answer || "N/A"}</p>
      )}
    </div>
  );
};

export default QuestionWithText;
