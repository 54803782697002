import OnboardingSection from "../legacy/pages/OnboardingSection"
import LoggedOutTopBar from "../legacy/components/LoggedOutTopBar"
import '../legacy/stylesheets/onboarding.scss'
import '../legacy/stylesheets/profile_section.scss'
import '../legacy/stylesheets/_forms.scss'
import '../legacy/stylesheets/base_styles.scss'
import '../legacy/stylesheets/_typography.scss'
import { useAuth } from '../auth/useAuth'
import Navbar from "../legacy/components/Navbar"
import { Navigate } from "react-router-dom"

const LandingPage = () => {
  const { login, loggedIn } = useAuth();
  const wordpressUrl = process.env.REACT_APP_WORDPRESS_URL;

  if (loggedIn) return <Navigate to="/dashboard" replace={true} />
  if (!loggedIn) {
    window.location.replace(wordpressUrl);
    return null;
  }

  return (
		<div className="new-experience-container">
			<div className="navbar">
				<LoggedOutTopBar removeLogin={true} user={null} />
			</div>

			<div className="navbar-container">
				<Navbar loggedIn={false} user={''} />
      </div>

			<section id="onboarding-page" className="new-experience">
				<OnboardingSection auth0Login={login} />
			</section>
		</div>
	)
}

export default LandingPage
