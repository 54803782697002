import React, { useEffect } from 'react';
import LoadingOverlay from '../../components/LoadingOverlay';
import Navbar from './Navbar';
import LoggedOutTopBar from './LoggedOutTopBar';
import { useAuth } from '../../auth/useAuth';
import { useSelector } from 'react-redux';

const Layout = ({ children, modal, loading }) => {
  const { user, loggedIn } = useAuth();
  const [showLoading, setShowLoading] = React.useState(true);

  useEffect(() => {
    if (window.location.pathname.includes('events') || window.location.pathname.includes('resources') || window.location.pathname.includes('jobs')) {
      setShowLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      setShowLoading(false);
    }
  }, [loading]);

  const { activeAlert } = useSelector(state => state.alerts)
  return (
    <div className="new-experience-container">
      <LoggedOutTopBar removeLogin={false} user={user ?? loggedIn} />
      {(showLoading) && <LoadingOverlay />}
      <section
        id="new-dashboard"
        className={`new-experience dashboard-background ${loggedIn ? 'logged-in' : ''} ${modal ? 'no-overflow' : ''}`}
      >
        <div className="navbar-container">
          <Navbar user={user} loggedIn={loggedIn} />
        </div>
        <div className="content-container">
          {children}
          {activeAlert && (
            <div className="status-changed">{activeAlert.message}</div>
          )}
        </div>
        {modal}
      </section>
    </div>
  );
};

export default Layout;
