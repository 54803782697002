import { FilterOption, FormWithSearch } from "../../../legacy/components/NewOnboarding";

const Step8 = ({ getTagsFromRequest, setCodingStacks, codingStacks, values, setValues}) => (
  <FormWithSearch
    type="search"
    setSearch={(event) => {
      const { value } = event.target;
      setTimeout(() => {
        getTagsFromRequest(value, setCodingStacks);
      }, 1000);
    }}
    filterComponent={
      <FilterOption
        filterClassName="filter-option-modal"
        options={codingStacks.slice(0, 20)}
        property="current_stack_list"
        selectedOption={values}
        setSelectedOption={setValues}
      />
    }
  />
);

export default Step8;
