import React from 'react';
import Button from './Button';
import FormWithCheckboxes from './FormWithCheckboxes';
import Radio from './Radio';
import Input from './NewOnboarding/Input';
import ProfileService from '../../services/ProfileService';
import Layout from './Layout';
import { useAuth } from '../../auth/useAuth';
let prevValues;

const ProfileEdit = ({
  setEdit,
  screenWidth,
  data,
  values,
  setValues,
  successFlash,
}) => {
  const { updateProfile, logout } = useAuth()
  const emailValidator =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  const [openModal, setOpenModal] = React.useState(false);
  const [firstNameError, setFirstNameError] = React.useState({
    error: false,
    message: '',
  });
  const [lastNameError, setLastNameError] = React.useState({
    error: false,
    message: '',
  });
  const [emailError, setEmailError] = React.useState({
    error: false,
    message: '',
  });
  const [showErrorFlash, setShowErrorFlash] = React.useState({
    error: false,
    message: '',
  });
  const [selectedRadio, setSelectedRadio] = React.useState(
    values.parent ? 'Yes' : 'No'
  );
  const mobileBreakWidth = 890;

  const onChange = (e) => {
    validateInputsOnchange(e);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleRadio = (e) => {
    setSelectedRadio(e.target.value);
    setValues({
      ...values,
      [e.target.name]: e.target.value === 'Yes' ? true : false,
    });
  };

  const handleCheckBoxes = (checked, checkboxValue, property) => {
    if (!checked) {
      setValues({
        ...values,
        [property]: values[property].filter((item) => item !== checkboxValue),
      });
    } else {
      setValues({
        ...values,
        [property]: values[property].concat(checkboxValue),
      });
    }
  };

  const validateInputsOnchange = (event) => {
    const { name, value } = event.target;
    if (name === 'first_name' && value.length > 1) {
      setFirstNameError({
        error: false,
        message: '',
      });
    }
    if (name === 'last_name' && value.length > 1) {
      setLastNameError({
        error: false,
        message: '',
      });
    }
    if (name === 'email' && !value.match(emailValidator)) {
      setEmailError({
        error: false,
        message: '',
      });
    }
  };

  const validateInputsOnSubmit = (input) => {
    let invalidInput = false;
    if (input.first_name === '') {
      setFirstNameError({
        error: true,
        message: 'First name must have a minimum of 2 characters.',
      });
      invalidInput = true;
    }
    if (input.last_name === '') {
      setLastNameError({
        error: true,
        message: 'Last name must have a minimum of 2 characters.',
      });
      invalidInput = true;
    }
    if (input.email && !input.email.match(emailValidator)) {
      setEmailError({
        error: true,
        message: 'Please enter a valid email address.',
      });
      invalidInput = true;
    }
    return invalidInput;
  };

  const applyProfileChanges = (values) => {
    if (validateInputsOnSubmit(values)) return;
    const updateEmail = values.email !== data.profile.email

    ProfileService.updateProfile(values, updateEmail).then(
      () => {
        setEdit(false);
        successFlash(true);
        updateProfile()
        if(updateEmail)
          logout({ state: { type: 'success', message: "Please check your email to confirm your account." }})
      },
      (err) => {
        console.log('Error');
        setShowErrorFlash({ error: true, message: ProfileService.errorMessages(err.status) });
      }
    );
  };

  React.useEffect(() => {
    prevValues = { ...values };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (showErrorFlash.error) {
      setTimeout(() => {
        setShowErrorFlash({ error: false, message: '' });
      }, 3500);
    }
  }, [screenWidth, showErrorFlash]);

  return (
    <Layout loading={false}>
      <div id="profile-section">
        {showErrorFlash.error && (
          <div className="status-changed error-status">
            <p style={{ margin: 0 }}>{showErrorFlash.message} </p>
          </div>
        )}
        {openModal && (
          <div className="onboarding-overlay">
            <div className="modal-container">
              <div className="close-button" onClick={() => setOpenModal(false)}>
                <img src="/images/x.png" alt="Close" />
              </div>
              <div className="edit-profile-img">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div className="profile-img-container">
                    {values.filepicker_url ? (
                      <img
                        src={values.filepicker_url}
                        className="profile-edit-img"
                        alt="Edit"
                      />
                    ) : values.first_name && values.last_name ? (
                      <span className="img-letter">
                        {values.first_name.substring(0, 1)}
                        {values.last_name.substring(0, 1)}
                      </span>
                    ) : (
                      <p className="img-letter">
                        {values.email.substring(0, 1)}
                      </p>
                    )}
                  </div>
                  <h4 className="profile-edit-modal-title">Profile Photo</h4>
                  <div className='profile-edit-modal-buttons'>
                    <Button
                      icon="trash"
                      text="Remove"
                      width={220}
                      onClick={() => {
                        setValues({
                          ...values,
                          filepicker_url: null,
                        });
                      }}
                    />
                    <div style={{ marginRight: 48 }} />
                    <Button
                      icon="pencil"
                      text="Change"
                      width={220}
                      className={'fill-button-profile'}
                      onClick={() => {
                        ProfileService.uploadFile(data.filestack_bucket, data.filestack_key, [
                          'image/jpg',
                          'image/jpeg',
                          ],
                          (result) => setValues({
                            ...values,
                            filepicker_url: result.url,
                          })
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="title-button-container">
          <h1 className="profile-edit-title">My Profile</h1>
          {screenWidth > mobileBreakWidth && (
            <Button
              icon="close"
              text="Cancel"
              onClick={() => {
                setValues(prevValues);
                setEdit(false);
              }}
            />
          )}
        </div>
        <div
          className="profile-card"
          style={{ boxShadow: screenWidth > mobileBreakWidth ? '' : 'none' }}
        >
          {screenWidth > mobileBreakWidth && (
            <div className="line-container-edit" style={{ opacity: 0.2 }}>
              <img src="/images/wave-line.png" alt="Wave line" />
            </div>
          )}
          <div className="img-logo-container">
            <div className="profile-edit-img-logo">
              <div className="profile-edit-img-container">
                {values.filepicker_url ? (
                  <img
                    src={values.filepicker_url}
                    className="profile-edit-img"
                    alt="Profile edit"
                  />
                ) : values.first_name && values.last_name ? (
                  <span className="edit-img-letter">
                    {values.first_name.substring(0, 1)}
                    {values.last_name.substring(0, 1)}
                  </span>
                ) : (
                  <p className="edit-img-letter">
                    {values.email.substring(0, 1)}
                  </p>
                )}
              </div>
              <div style={{ width: 32 }} />
              <Button
                icon="camera"
                text="view profile photo"
                onClick={() => setOpenModal(true)}
              />
            </div>
            {screenWidth > mobileBreakWidth && (
              <div className="logo-container" style={{ opacity: 0.2 }}>
                <img src="/images/wwcode_logo.png" className="logo" alt="WWC Logo" />
              </div>
            )}
          </div>
          {screenWidth < mobileBreakWidth && (
            <h5 className="profile-edit-sub-title">About Me</h5>
          )}
          <div className="info-edit-inputs">
            {['First Name', 'Last Name'].map((input, index) => {
              const inputValue = ['first_name', 'last_name'];
              const error = [firstNameError, lastNameError];
              return (
                <div
                  style={{
                    flexGrow: 1,
                    marginRight:
                      index === 0 && screenWidth > mobileBreakWidth ? 15 : null,
                    marginTop: screenWidth < mobileBreakWidth ? 28 : null,
                  }}
                  key={input}
                >
                  <Input
                    name={inputValue[index]}
                    label={input}
                    value={values[inputValue[index]]}
                    onChange={(e) => onChange(e)}
                    error={error[index].error}
                    message={error[index].message}
                  />
                </div>
              );
            })}
          </div>
          {screenWidth < mobileBreakWidth && (
            <div style={{ width: '100%', marginTop: 28 }}>
              <Input
                name="email"
                label="Email"
                value={values.email}
                onChange={(e) => onChange(e)}
                error={emailError.error}
                message={emailError.message}
              />
            </div>
          )}
          <div className="info-edit-inputs">
            {['Job Title', 'Company'].map((input, index) => {
              const inputValue = ['job_title', 'work'];
              return (
                <div
                  style={{
                    flexGrow: 1,
                    marginRight:
                      index === 0 && screenWidth > mobileBreakWidth ? 15 : null,
                    marginTop: screenWidth < mobileBreakWidth ? 28 : null,
                  }}
                  key={input}
                >
                  <Input
                    name={inputValue[index]}
                    label={input}
                    value={values[inputValue[index]]}
                    onChange={(e) => onChange(e)}
                    //error={error}
                  />
                </div>
              );
            })}
          </div>
          <div className="flex-wrap">
            <div>
              <p className="profile-p-header">Pronouns</p>
              <div className="flex-wrap">
                {data.pronouns?.map((checkbox, index) => {
                  return (
                    <div
                      style={{
                        marginRight:
                          index === 3
                            ? null
                            : screenWidth < mobileBreakWidth
                            ? 48
                            : 16,
                      }}
                      key={checkbox}
                    >
                      <FormWithCheckboxes
                        label={checkbox}
                        name="pronoun_list"
                        checked={values.pronoun_list.includes(checkbox)}
                        onChange={(checked, value) => {
                          handleCheckBoxes(checked, value, 'pronoun_list');
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              style={{
                marginRight: screenWidth < mobileBreakWidth ? null : 80,
              }}
            />
            <div>
              <p className="profile-p-header">Do you have kids?</p>
              <div style={{ display: 'flex' }}>
                {['Yes', 'No'].map((answer, index) => {
                  return (
                    <div
                      style={{ marginRight: index === 1 ? null : 48 }}
                      key={answer}
                    >
                      <Radio
                        label={answer}
                        value={answer}
                        name="parent"
                        onChange={handleRadio}
                        checked={selectedRadio === answer}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {screenWidth > mobileBreakWidth && (
            <div style={{ width: '42%', marginTop: 12 }}>
              <Input
                label="Email"
                name="email"
                value={values.email}
                onChange={(v, n) => onChange(v, n)}
                error={emailError.error}
                message={emailError.message}
              />
            </div>
          )}
          {screenWidth < mobileBreakWidth && (
            <h5 className="profile-edit-sub-title">Social Media</h5>
          )}
          <div
            className="flex-wrap"
            style={{
              marginRight: screenWidth < mobileBreakWidth ? null : 12,
            }}
          >
            {['Website', 'Github', 'Twitter', 'LinkedIn', 'Instagram'].map(
              (input, index) => {
                const inputValue = [
                  'url',
                  'github_username',
                  'twitter_username',
                  'linkedin_url',
                  'instagram_username',
                ];
                return (
                  <div
                    style={{
                      flexGrow: 1,
                      width: screenWidth < mobileBreakWidth ? '100%' : null,
                      marginRight:
                        index === 4
                          ? null
                          : screenWidth < mobileBreakWidth
                          ? null
                          : 24,
                      marginTop: screenWidth < mobileBreakWidth ? 12 : null,
                    }}
                    key={input}
                  >
                    <Input
                      name={inputValue[index]}
                      label={input}
                      value={values[inputValue[index]]}
                      onChange={(e) => onChange(e)}
                      //error={error}
                    />
                  </div>
                );
              }
            )}
          </div>
          <div style={{ marginTop: screenWidth < mobileBreakWidth ? 28 : 55 }}>
            <Button
              icon="save"
              text="Apply Changes"
              className={'fill-button-profile'}
              onClick={() => {
                applyProfileChanges(values);
              }}
            />
            {screenWidth < mobileBreakWidth && (
              <div style={{ marginTop: 12 }}>
                <Button
                  icon="close"
                  text="Cancel"
                  onClick={() => {
                    setValues(prevValues);
                    setEdit(false);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProfileEdit;
