import { createSlice } from "@reduxjs/toolkit"

const resourcesSlice = createSlice({
	name: 'pages',
	initialState: {
		dashboard: {
			loading: true,
			dashboardData: {}
		},
		settings: {
			loading: true,
			settingsData: {}
		},
		profile: {
			loading: true,
			profileData: {}
		}
	},
	reducers: {
		dashboardReceived(state, action) {
			const newState = { ...state }
			newState.dashboard = {
				loading: false,
				dashboardData: action.payload
			}
			return newState
		},
		settingsReceived(state, action) {
			const newState = { ...state }
			newState.settings = {
				loading: false,
				settingsData: action.payload
			}
			return newState
		},
		profileReceived(state, action) {
			const newState = { ...state }
			newState.profile = {
				loading: false,
				profileData: action.payload
			}
			return newState
		}
	},
})

export const { reducer: pagesReducer } = resourcesSlice
export const { dashboardReceived, settingsReceived, profileReceived } = resourcesSlice.actions
