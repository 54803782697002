import { useEffect, useState, useRef } from "react";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import debounce from "just-debounce-it";

const DEBOUNCE_TIME = 1000;

const useInfiniteScrollPagination = (objectRef, onPageChange) => {
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(page + 1);
  const handledPageRef = useRef(1);
  const { isNearScreen } = useIntersectionObserver({
    distance: "200px",
    objectRef,
  });

  const resetPage = () => {
    setPage(1);
    setNextPage(2);
    handledPageRef.current = 1;
  };

  useEffect(() => {
    const debounceHandleNextPage = () => {
      debounce(setPage((prevPage) => prevPage + 1), DEBOUNCE_TIME);
    };

    if (isNearScreen) {
      debounceHandleNextPage();
    }
  }, [isNearScreen]);

  useEffect(() => {
    if (page === nextPage && page !== handledPageRef.current) {
      onPageChange();
      handledPageRef.current = page;
    }
  }, [nextPage, onPageChange, page]);

  return { page, setNextPage, resetPage };
};

export default useInfiniteScrollPagination;
