import { FormWithCheckboxes } from "../../../legacy/components/NewOnboarding";

const Step10 = ({ expertiseList, handleCheckBoxes, isChecked}) => (
  <div
    className="checkgroup-container"
  >
    <div className="checkgroup">
      {expertiseList.slice(0, 2).map((expertise) => {
        return (
          <FormWithCheckboxes
            key={expertise}
            label={expertise}
            name="expertise_list"
            onChange={(checked, value) => {
              handleCheckBoxes(checked, value, "expertise_list");
            }}
            checked={isChecked("expertise_list", expertise)}
          />
        );
      })}
    </div>
    <div className="checkgroup">
      {expertiseList.slice(2).map((expertise) => {
        return (
          <FormWithCheckboxes
            key={expertise}
            label={expertise}
            name="expertise_list"
            onChange={(checked, value) => {
              handleCheckBoxes(checked, value, "expertise_list");
            }}
            checked={isChecked("expertise_list", expertise)}
          />
        );
      })}
    </div>
  </div>
);

export default Step10;
