import { FormWithCheckboxes } from "../../../legacy/components/NewOnboarding";

const Step7 = ({ joinList, handleCheckBoxes, isChecked }) => (
  <div
    className="checkgroup-container"
  >
    <div className="checkgroup">
      {joinList.slice(0, 4).map((join) => (
        <FormWithCheckboxes
          key={join}
          label={join}
          name="why_join_list"
          onChange={(checked, value) => {
            handleCheckBoxes(checked, value, "why_join_list");
          }}
          checked={isChecked("why_join_list", join)}
        />
      ))}
    </div>
    <div className="checkgroup">
      {joinList.slice(4, 8).map((join) => (
        <FormWithCheckboxes
          key={join}
          label={join}
          name="why_join_list"
          onChange={(checked, value) => {
            handleCheckBoxes(checked, value, "why_join_list");
          }}
          checked={isChecked("why_join_list", join)}
        />
      ))}
    </div>
  </div>
);

export default Step7;
