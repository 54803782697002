import { BaseService } from "./BaseService";

const BookmarkingsService =  {
	getFavorites: async() => {
		try {
			const data = await BaseService.get('favorites');
			return {
				favorites: {
					...data,
				}
			};
		} catch(e) {
			console.error(e);
			return {
				favorites: {
					events: [],
					jobs: [],
					resources: [],
				}
			};
		}
	},
	addBookmarking: async(type, id) => {
		try {
			await BaseService.post(`${type}/${id}/bookmarkings`);
		} catch(e) {
			console.error(e);
		}
	},
	deleteBookmarking: async(type, id) => {
		try {
			await BaseService.delete(`${type}/${id}/bookmarkings`);
		} catch(e) {
			console.error(e);
		}
	},
}

export default BookmarkingsService;
