import * as yup from 'yup';

const schema = yup.object().shape({
  firstName: yup.string()
		.required("Please enter a first name."),
  lastName: yup.string()
    .required("Please enter a last name."),
  network: yup.number()
    .notOneOf([0], "Please select a network.")
    .required("Network selection is required."),
  description: yup.string()
		.required("Please enter a description."),
	captcha: yup.string().nullable()
		.required("Please verify you are a human.")
});

export default schema;