import { useEffect, useState } from "react";
import {
  getArrayDifference,
  updateObjectAttributes,
  yearDropDown,
} from "../legacy/components/utils/FormUtils";
import ProfileService from "../services/ProfileService";
import TagsService from "../services/TagsService";
import { useAuth } from "../auth/useAuth";
import NetworksService from "../services/NetworksService";
import { getCurrentLocationState } from "../utils/locations";
import { markDestroyableElements } from "../utils/functions/destroyableElements";

const useOnboarding = ({ communities }) => {
  const { profile, setProfile } = useAuth();

  const onboarding = profile?.onboarding_completed;
  const profilePage = 0;
  const [step, setStep] = useState(profilePage);

  const [technologyInterest, setTechnologyInterest] = useState([]);
  const [codingStacks, setCodingStacks] = useState([]);
  const [aspireStack, setAspireStack] = useState([]);
  const profilePreferredLocation = Object.values(
    profile?.preferred_locations_attributes ?? {}
  );
  const [preferredLocation, setPreferredLocation] = useState({
    location: profilePreferredLocation,
  });
  const [currentLocation, setCurrentLocation] = useState(
    getCurrentLocationState(profile)
  );
  const [onBoardingCompleted, setOnBoardingCompleted] = useState(onboarding);
  const [showErrorFlash, setShowErrorFlash] = useState({
    error: false,
    message: "",
  });
  const [recommendedCommunities, setRecommendedCommunities] = useState([]);
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    career_opportunities: "",
    starter_year_in_tech: "",
    resume_filename: "",
    information_consented_at: "",
    expertise: "",
    expertise_list: [],
    years_in_tech: "",
    relocate: "",
    remote: "",
    location: "",
    job_search: [],
    why_join_list: [],
    engineering_interest_list: [],
    current_stack_list: [],
    aspiring_stack_list: [],
    network_members_attributes: {},
    preferred_locations: {},
    preferred_locations_attributes: {},
  });

  useEffect(() => {
    if (!profile) return;
    setValues({ ...profile });
  }, [profile]);

  const fetchProfile = () => {
    ProfileService.getProfile().then(
      (profile) => profile && setValues({ ...profile })
    );
  };

  const updatePreferredLocation = (locations) => {
    setPreferredLocation({ location: locations });
    setValues((x) => ({ ...x, preferred_locations_attributes: locations }));
  };

  const updateCurrentLocation = (locationObj) => {
    setCurrentLocation({ location: locationObj ? [locationObj] : [] });
    setValues((x) => ({
      ...x,
      location: locationObj?.address_attributes?.formatted_address ?? null,
      latitude: locationObj?.address_attributes?.latitude ?? null,
      longitude: locationObj?.address_attributes?.longitude ?? null,
    }));
  };

  const updateNetworks = (values) => {
    let newValues = { ...values };
    // get newNetworkIds
    let newNetworkIds = values.network_titles.map(
      (network) => communities[network]
    );

    // get currentNetworkIds
    let networkMembersAttributesList = Object.values(
      values.network_members_attributes
    ).filter(({ _destroy }) => !_destroy);
    let currentNetworkIds = networkMembersAttributesList.map(
      (n) => n.network_id
    );

    // get difference btw both network_ids list
    let attrDifference = getArrayDifference(newNetworkIds, currentNetworkIds);

    // if currentNetworkIds contains difference, remove else add
    newValues.network_members_attributes = updateObjectAttributes(
      newValues.network_members_attributes,
      currentNetworkIds,
      attrDifference.at(-1),
      "network_id"
    );

    setValues(newValues);
  };

  const handleCheckBoxes = (checked, checkboxValue, property) => {
    const newValues = checked
      ? [...values[property], checkboxValue]
      : values[property].filter((item) => item !== checkboxValue);
    setValues({ ...values, [property]: newValues });
  };

  const goToPage = (page, skipped = false, newValues) => {
    setStep(page);
    if (skipped) {
      setValues({ ...profile });
      setCurrentLocation(getCurrentLocationState(profile));
      setPreferredLocation({ location: profilePreferredLocation });
      return;
    }
    ProfileService.updateProfile({
      ...profile,
      ...(newValues ?? values),
      preferred_locations_attributes: markDestroyableElements(
        Object.values(profile.preferred_locations_attributes),
        Object.values(values.preferred_locations_attributes)
      ),
      current_onboarding_page: page,
      id: profile.id,
    }).then(
      (newProfile) => {
        setProfile(newProfile);
      },
      (err) => {
        setShowErrorFlash({
          error: true,
          message: ProfileService.errorMessages(err.status),
        });
        console.log("ERROR");
      }
    );
  };
  const completeOnboarding = (data) => {
    ProfileService.updateProfile({ ...data, onboarding_completed: true }).then(
      (newProfile) => {
        setProfile(newProfile);
        console.log("SUCCESS");
      },
      () => console.log("ERROR")
    );
  };

  useEffect(() => {
    if (showErrorFlash.error) {
      setTimeout(() => {
        setShowErrorFlash({ error: false, message: "" });
      }, 3500);
    }
  }, [showErrorFlash]);

  const getTagsFromRequest = (value, setSearch) => {
    TagsService.getStacks(value).then((data) => setSearch(data.list));
  };

  const fetchRecommendedNetworks = () => {
    NetworksService.getNetworkRecommendations().then((data) =>
      setRecommendedCommunities(data.networks.map((x) => x.network_title))
    );
  };

  useEffect(() => {
    values.location &&
      setCurrentLocation({
        location: [
          {
            address_attributes: {
              latitude: values.latitude,
              longitude: values.longitude,
              formatted_address: values.location,
            },
          },
        ],
      });
  }, [values.latitude, values.location, values.longitude]);

  useEffect(() => {
    fetchProfile();
    getTagsFromRequest("", (data) => {
      setTechnologyInterest(data);
      setCodingStacks(data);
      setAspireStack(data);
    });
  }, []);

  useEffect(() => {
    if (step === 16) fetchRecommendedNetworks();
  }, [step]);

  const isChecked = (listName, value) => values?.[listName]?.includes?.(value);

  const setInformationConsentedAt = (values) => {
    const informationConsentedAt = values.information_consented_at
      ? "Yes"
      : "No";
    setValues((prevValues) => ({
      ...prevValues,
      information_consented_at: informationConsentedAt,
    }));
  };

  return {
    step,
    setStep,
    values,
    setValues,
    onBoardingCompleted,
    setOnBoardingCompleted,
    showErrorFlash,
    setShowErrorFlash,
    technologyInterest,
    setTechnologyInterest,
    codingStacks,
    setCodingStacks,
    aspireStack,
    setAspireStack,
    preferredLocation,
    setPreferredLocation,
    currentLocation,
    setCurrentLocation,
    recommendedCommunities,
    setRecommendedCommunities,
    isChecked,
    setInformationConsentedAt,
    updateNetworks,
    updateCurrentLocation,
    updatePreferredLocation,
    handleCheckBoxes,
    goToPage,
    completeOnboarding,
    fetchProfile,
    getTagsFromRequest,
    yearDropDown,
    profile,
  };
};

export default useOnboarding;
