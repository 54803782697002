import './Title.scss'

const Title = ({ children, className="" }) => {
	return (
		<h3 className={`Title ${className}`}>{children}</h3>
	)
}

const H4 = ({ children, className="" }) => {
	return (
		<h4 className={`h4 ${className}`}>{children}</h4>
	)
}

export default Title
export { H4 }
