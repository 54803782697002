import { useState, useEffect } from "react";
import ProfileService from '../../services/ProfileService';
import QuestionHeader from './QuestionHeader';
import FormWithSearch from './FormWithSearch';
import FilterOption from './FilterOption';
import QuestionWithRadio from './QuestionWithRadio';
import FormWithCheckboxes from './FormWithCheckboxes';
import Button from './Button';
import TagsService from '../../services/TagsService'
const TechSettingsEdit = ({
  setEdit,
  data,
  successFlash,
  errorFlash,
  profile,
  setProfile,
  fetchProfile
}) => {
  const [codingStacks, setCodingStacks] = useState([]);
  const [aspireStack, setAspireStack] = useState([]);
  const [values, setValues] = useState(profile);

  const handleRadio = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const getTagsFromRequest = (value, setSearch) => {
    TagsService.getStacks(value)
      .then((data) => setSearch(data.list));
  };

  const handleCheckBoxes = (checked, checkboxValue, property) => {
    const value = values[property] || [];
    const newValues = checked
      ? [...value, checkboxValue]
      : value.filter((item) => item !== checkboxValue);
    setValues({ ...values, [property]: newValues });
  };

  useEffect(() => {
    getTagsFromRequest('', (data) => {
      setCodingStacks(data);
      setAspireStack(data);
    });
  }, []);

  return (
    <div className="settings-wrapper">
      <div className="flex-wrap">
        <QuestionHeader question={'Which coding stacks do you use?'}>
          <FormWithSearch
            edit={true}
            setSearch={(event) => {
              const { value } = event.target;
              setTimeout(() => {
                getTagsFromRequest(value, setCodingStacks);
              }, 1000);
            }}
            filterComponent={
              <FilterOption
                options={codingStacks}
                property="current_stack_list"
                selectedOption={values}
                setSelectedOption={setValues}
              />
            }
          />
        </QuestionHeader>
        <div style={{ marginRight: '10%' }} />
        <QuestionHeader question={'Which coding stacks do you want to learn?'}>
          <FormWithSearch
            edit={true}
            setSearch={(event) => {
              const { value } = event.target;
              setTimeout(() => {
                getTagsFromRequest(value, setAspireStack);
              }, 1000);
            }}
            filterComponent={
              <FilterOption
                options={aspireStack}
                property="aspiring_stack_list"
                selectedOption={values}
                setSelectedOption={setValues}
              />
            }
          />
        </QuestionHeader>
      </div>
      <div className="flex-wrap" style={{ marginTop: 48 }}>
        <QuestionHeader question={"How would you describe your level of expertise?"}>
          <div className="flex-wrap">
            <div>
              {
                data.expertise_list.slice(0, 2).map(expertise => (
                  <FormWithCheckboxes
                    key={expertise}
                    label={expertise}
                    name="expertise_list"
                    checked={values.expertise_list ? values.expertise_list.includes(expertise) : false}
                    onChange={(checked, value) => {
                      handleCheckBoxes(checked, value, "expertise_list")
                    }}
                  />
                ))
              }
            </div>
            <div style={{marginRight: 40}}/>
            <div>
              {
                data.expertise_list.slice(2).map(expertise => (
                  <FormWithCheckboxes
                    key={expertise}
                    label={expertise}
                    name="expertise_list"
                    checked={values.expertise_list ? values.expertise_list.includes(expertise) : false}
                    onChange={(checked, value) => {
                      handleCheckBoxes(checked, value, "expertise_list")
                    }}
                  />
                ))
              }
            </div>
          </div>
        </QuestionHeader>
        <div style={{ marginRight: '10%' }} />
        <div className="question-block">
          <QuestionWithRadio
            question="How many years of experience in tech do you have?"
            answers={data?.years_in_tech}
            name="years_in_tech"
            radioOnChange={handleRadio}
            value={values.years_in_tech || ''}
            noWidth={true}
            noTopMargin={true}
          />
        </div>
      </div>
      <div className="bottom-save-button">
        <Button
          icon="save"
          text="Apply Changes"
          className={'fill-button-profile'}
          onClick={() => {
            ProfileService.updateProfile(values)
            .then(() => {
              successFlash(true)
              setProfile(values)
              fetchProfile()
              setEdit(false)
            }).catch((err) => {
              errorFlash({error: true, message: ProfileService.errorMessages(err.status) })
              console.log("ERROR")
            })
          }}
        />
      </div>
    </div>
  );
};
export default TechSettingsEdit;
